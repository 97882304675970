import React from 'react';
import { getClassNames } from '../../helpers/classHelpers';
import styles from './Chip.module.scss';

export type ChipProps = {
    label?: string;
    icon?: React.ReactElement;
    isActive?: boolean;
    className?: string;
    onClick?: () => void;
};

const Chip: React.FC<ChipProps> = ({ label, icon, isActive, className, onClick }) => {
    const onClickHandler = (e: React.MouseEvent<HTMLDivElement>) => {
        if (!onClick) return;

        e.preventDefault();
        e.stopPropagation();
        onClick();
    };

    return (
        <div onClick={onClickHandler} className={getClassNames(['df-chip assist', styles.chip, !!onClick && styles['clickable'], isActive && 'selected choice multi', className])} role='button' tabIndex={0}>
            {icon}
            {label && <span>{label}</span>}
        </div>
    );
};

export default Chip;