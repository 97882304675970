import usePersistentInputState from '../../views/chat-view/usePersistentInputState';
import usePersistentAutoSubmitState from '../../views/chat-view/usePersistentLoadingForState';
import MockChatService from '../../services/MockChatService';
import ChatProvider from './ChatContext';
import { PropsWithChildren, useMemo } from 'react';
import { useAuth } from '../auth/AuthContext';

const MockChatServiceProvider: React.FC<PropsWithChildren> = ({ children }) => {
    const persistentInputs = usePersistentInputState();
    const persistentAutoSubmit = usePersistentAutoSubmitState();
    const persistent = { ...persistentInputs, ...persistentAutoSubmit };

    const { getAccessToken } = useAuth();
    const mockService = useMemo(() => new MockChatService(getAccessToken), [getAccessToken]);

    return <ChatProvider {...mockService} persistent={persistent}>
        {children}
    </ChatProvider>;
};

export default MockChatServiceProvider;