import React, { type PropsWithChildren, useEffect, useMemo, useState } from 'react';
import AuthProvider, { type IAuthService, TokenState } from './AuthContext';
import useStorage from '../../hooks/useStorage';

export type AuthState = {
    isLoggedIn: boolean;
};

const defaultState: AuthState = {
    isLoggedIn: false,
};

const MockAuthContextProvider: React.FC<PropsWithChildren<{ state?: Partial<AuthState>; }>> = ({ children, state: initialState = defaultState }) => {
    const { get, set } = useStorage('mock');

    const [state, setState] = useState(initialState);

    useEffect(() => {
        const isLoggedIn = get('isLoggedIn');
        if (isLoggedIn) setState(s => ({ ...s, isLoggedIn: true }));
    }, [get]);

    const service = useMemo<IAuthService>(() => {
        const s: IAuthService = {
            login: () => {
                setState(s => ({ ...s, isLoggedIn: true }));
                set('isLoggedIn', true);
            },
            logout: () => {
                setState(s => ({ ...s, isLoggedIn: false }));
                set('isLoggedIn', false);
            },
            getAccessToken() {
                setState(s => ({ ...s, tokenState: TokenState.Valid }));
                return Promise.resolve('token');
            },
            tokenState: TokenState.Valid,
            isLoggingIn: false,
            isLoggingOut: false,
            isStartingUp: false,
            user: state.isLoggedIn ? { username: 'johndoe', name: 'John Doe', avatar: 'https://placehold.co/120x120/e20010/white?text=JD' } : undefined,
        };
        return s;
    }, [set, state.isLoggedIn]);

    return <AuthProvider service={service}>
        {children}
    </AuthProvider>;
};

export default MockAuthContextProvider;