const envConfigKeyPrefix = "REACT_APP";
const envConfig = process.env as any as Readonly<Record<string, string>>;
const envName = envConfig.NODE_ENV;

export const isDevelopmentEnv = () => envName === 'development';

const getEnvConfigKey = (key: string, prefix: string): string => {
    key = key.toUpperCase().replace(new RegExp("-", 'g'), "_");
    return [prefix, key].filter(x => !!x).join("_");
};


const getEnvConfig = (key: string, fallback?: string, prefix = envConfigKeyPrefix) => {

    let configKey = getEnvConfigKey(key, prefix);

    let val = envConfig[configKey] ?? fallback;

    if (!val) console.warn(`Config entry '${configKey}' was not found`);
    return val;
};

export default class EnvConfigHelper {

    static envConfig: Readonly<Record<string, string>> = envConfig;
    static envName = envName;
    static get = getEnvConfig;
}