import React, { type PropsWithChildren, useMemo } from 'react';
import { useAuth } from '../contexts/auth/AuthContext';

const LoggedOutTemplate: React.FC<PropsWithChildren> = ({ children }) => {
    const { user } = useAuth();
    const isLoggedIn = useMemo(() => !!user, [user]);

    if (isLoggedIn) return null;
    else return <>{children}</>;
};

export default LoggedOutTemplate;