import { useState, useEffect, useCallback, useMemo } from 'react';
import useStorage from './useStorage';
import Modal from '../ui/modal/Modal';
import useLabels from './useLabels';
import useDebounce from './useDebounce';
import ChatDisclaimer, { type ChatDisclaimerProps } from '../elements/chat-disclaimer/ChatDisclaimer';
import ChatDisclaimerModalFooter from '../elements/chat-disclaimer-modal-footer/ChatDisclaimerModalFooter';
import MarkdownWrapper from '../ui/markdown-wrapper/MarkdownWrapper';

const storeScope = 'chat-window';
const disclaimerStoreKey = 'is-disclaimer-read';

export default function useDisclaimer() {
    const labels = useLabels();
    const l = useMemo(() => ({ hl: labels.disclaimerHeadline, cta: labels.disclaimerButton }), [labels.disclaimerButton, labels.disclaimerHeadline]);

    const [showDisclaimer, setShowDisclaimer] = useState<boolean>(false);
    const [isDisclaimerRead, setIsDisclaimerRead] = useState<boolean>(false);

    const { get, set } = useStorage(storeScope, 'session');

    useEffect(() => {
        const isRead = get(disclaimerStoreKey);
        setShowDisclaimer(!isRead);
    }, [get]);

    const hideDisclaimer = useCallback(() => {
        set(disclaimerStoreKey, true);
        setShowDisclaimer(false);
    }, [set]);

    // Debounce to allow modal to animate out
    const debouncedShowDisclaimer = useDebounce(showDisclaimer, 300, showDisclaimer);
    const renderDisclaimer = useCallback((props: Omit<ChatDisclaimerProps, 'hideButton' | 'onCtaClick' | 'onDisclaimerIsRead'> = {}) => {
        if (!debouncedShowDisclaimer) return null;
        const disclaimer = showDisclaimer && <ChatDisclaimer {...props} onDisclaimerIsRead={setIsDisclaimerRead} />;

        return <Modal
            size='large'
            headline={l.hl}
            hideCloseIcon
            isOpen={showDisclaimer}
            body={disclaimer}
            footer={<ChatDisclaimerModalFooter extra={<MarkdownWrapper markdown={labels.disclaimerConfirmationMarkdown} />} button={{ label: l.cta, onClick: hideDisclaimer, isDisabled: !isDisclaimerRead }} />}
        />;
    }, [debouncedShowDisclaimer, hideDisclaimer, isDisclaimerRead, l.cta, l.hl, labels.disclaimerConfirmationMarkdown, showDisclaimer]);

    return {
        showDisclaimer,
        isDisclaimerRead,
        renderDisclaimer,
    };
}