import React from 'react';
import ReactDOM from 'react-dom/client';
import App from './App';

// Mosaic (and styles)
import '@danfoss/mosaic-css/dist/mosaic.css';
import './index.scss';

// Icons
import 'material-icons/iconfont/material-icons.css';

// Contexts
import ContextBootstrap from './contexts/bootstrap/ContextBootstrap';
import ContextBuffer from './contexts/bootstrap/ContextBuffer';

import { setNonce } from "@danfoss/mosaic-core/loader";
const { mosaic } = require('./constants/nonce');
setNonce(mosaic);

const root = ReactDOM.createRoot(
    document.getElementById('root') as HTMLElement
);

root.render(
    <React.StrictMode>
        <ContextBootstrap>
            <ContextBuffer>
                <App />
            </ContextBuffer>
        </ContextBootstrap>
    </React.StrictMode>
);