import useLabels from '../../../../../../hooks/useLabels';
import { useCallback, useEffect, useState } from 'react';
import { generateUniqueId } from '@danfoss/mosaic-core';
import { RightSidebarProps } from '../../../../../../contexts/layout/LayoutContext';

const useDcsEmbed = () => {
    const [isLoaded, setIsLoaded] = useState(false);

    const txvEmbed = useCallback(({ culture, labels, metadata, addRightSidebarContent, showRightSidebar }: ToolEmbedProas) => {
        if (!isLoaded) return;
        const headerLabel = labels.embeddedToolHeadline;
        addRightSidebarContent({
            header: <b>{headerLabel}</b>,
            children: DCS_EMBED_CONTAINER_ELEMENT(),
            metadata
        });

        toolConfigLoader(culture, 'txv-selection');
        showRightSidebar();
    }, [isLoaded]);

    const exvEmbed = useCallback(({ culture, labels, metadata, addRightSidebarContent, showRightSidebar }: ToolEmbedProas) => {
        if (!isLoaded) return;

        const headerLabel = labels.embeddedToolHeadline;
        addRightSidebarContent({
            header: <b>{headerLabel}</b>,
            children: DCS_EMBED_CONTAINER_ELEMENT(),
            metadata
        });

        toolConfigLoader(culture, 'exv-selection');
        showRightSidebar();
    }, [isLoaded]);

    useEffect(() => {
        if (isLoaded) return;

        const script = document.createElement('script');
        script.src = DCS_EMBED_URL;
        script.async = false;
        document.body.appendChild(script);

        script.onload = () => {
            setIsLoaded(true);
        };
        script.onerror = () => {
            console.error('DCS Ember script loading failed');
        };
    }, [isLoaded]);

    return { txvEmbed, exvEmbed };
};

export default useDcsEmbed;


//

type ToolEmbedProas = {
    culture: string;
    labels: ReturnType<typeof useLabels>;
    metadata: Record<string, string>;
    addRightSidebarContent: (content: RightSidebarProps) => void;
    showRightSidebar: () => void;
};

const DCS_EMBED_URL = "https://dcsddcdsfeproddocs.z16.web.core.windows.net/scripts/dcs-embedded-tool.js";
const DCS_EMBED_CONTAINER_ID = "danfoss-tool-container";
const DCS_EMBED_CONTAINER_ELEMENT = () => <div id={DCS_EMBED_CONTAINER_ID} key={generateUniqueId()} />;
const toolConfigLoader = (culture: string, tool: string) => {
    try {
        setTimeout(() => {
            new (window as any).DcsEmbeddedTool(`#${DCS_EMBED_CONTAINER_ID}`, {
                tool,
                culture,
                wholesalerId: "danfoss",
                toolSpecificData: {
                    // Hide 'add to basket' CTA
                    hideCta: true
                }
            });
        }, 100);
    }
    catch (e) {
        console.error('Embed tool config:', { e });
    }
};

