import { useCallback, useLayoutEffect, useRef } from 'react';
import { MediaSize } from '../constants/consts';
import TaskPromise from '../services/TaskPromise';
import { useMediaSize } from './useMediaSize';

type ReturnType = {
    /**Performs scroll to bottom.*/
    scrollToBottom: () => void;
    /**Performs scroll to bottom after a delay. Default 100 */
    scrollToBottomWithDelay: (delay?: number) => Promise<void>;
    /**Performs scroll to bottom only if the current scroll position is not greater than the buffer */
    scrollToBottomWithBuffer: () => void;
    /**Performs scroll to bottom after a delay only if the current scroll position is not greater than the buffer. Default 100 */
    scrollToBottomWithBufferAndDelay: (delay?: number) => Promise<void>;
    scrollBuffer: number;
};
export default function useScrollToBottom(element: React.RefObject<HTMLDivElement>, buffer: 'auto' | number = 'auto'): ReturnType {
    const scrollBuffer = useMediaSize(ms => buffer !== 'auto' ? buffer : ms <= MediaSize.xs ? 120 : 80);

    const lastScrollPositionRef = useRef(0)
    const anchored = useRef(false)
    useLayoutEffect(() => {
        const el = element.current;
        if (!el) return;

        const handler = () => {
            const lastScrollPosition = lastScrollPositionRef.current;
            lastScrollPositionRef.current = el.scrollTop


            const scrollBottomPosition = el.scrollTop + el.offsetHeight;
            const scrollHeight = el.scrollHeight - scrollBuffer;
            const scrolledToBottom = scrollBottomPosition >= scrollHeight;
            const scrollingUp = lastScrollPosition > el.scrollTop;

            anchored.current = !scrollingUp && scrolledToBottom
        }

        el.addEventListener('scroll', handler)
        handler()
        return () => {
            el.removeEventListener('scroll', handler)
        }
    }, [element, scrollBuffer])
    const scrollToBottom = useCallback(() => {
        const el = element.current;
        if (!el) return;

        requestAnimationFrame(() => el.scrollTop = el.scrollHeight);
    }, [element]);


    const scrollToBottomWithDelay = useCallback(async (delay = 100) => {
        await TaskPromise.delay(delay);
        scrollToBottom();
    }, [scrollToBottom]);


    const scrollToBottomWithBuffer = useCallback(() => {
        if (anchored.current) scrollToBottom();
    }, [scrollToBottom]);

    const scrollToBottomWithBufferAndDelay = useCallback(async (delay = 100) => {
        await TaskPromise.delay(delay);
        scrollToBottomWithBuffer()
    }, [scrollToBottomWithBuffer]);

    return {
        scrollToBottom,
        scrollToBottomWithDelay,
        scrollToBottomWithBuffer,
        scrollToBottomWithBufferAndDelay,
        scrollBuffer
    };
}