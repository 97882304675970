import { StyleClassEnum } from '@danfoss/mosaic-core';
import { useEffect } from 'react';

export default function useScrollLock(lockScroll?: boolean) {

    useEffect(() => {
        document.body.classList.toggle(StyleClassEnum.LockScroll, lockScroll);

        return () => {
            document.body.classList.remove(StyleClassEnum.LockScroll);
        };
    }, [lockScroll]);
}