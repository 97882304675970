import React, { memo, useCallback, useMemo, useState } from 'react';
import TemperatureSlider from '../temperature-slider/TemperatureSlider';
import useLabels from '../../hooks/useLabels';
import Divider from '../../ui/divider/Divider';
import InputLabel from '../../ui/input-label/InputLabel';
import Accordion from '../../ui/accordion/Accordion';
import Tag from '../../ui/tag/Tag';
import Button, { ButtonThemes } from '../../ui/button/Button';
import { getClassNames } from '../../helpers/classHelpers';
import { DfSelect } from '@danfoss/mosaic-react';
import { MediaSize, defaultTemperature } from '../../constants/consts';
import { useMediaSize } from '../../hooks/useMediaSize';
import { AccordionVariantEnum } from '@danfoss/mosaic-core';
import { ChatInputModel, ChatInputPropsBase } from './models';
import { TestIds } from '../../mocks/ids';
import style from './ChatInput.module.scss';

export type ChatInputOptionsProps = ChatInputPropsBase & Pick<ChatInputModel, "llm" | "persona" | "temperature">;

export const ChatInputOptions: React.NamedExoticComponent<ChatInputOptionsProps> = memo(({ llm, temperature, persona, onModelMerge, llmOptions, personaOptions, isInputDisabled }) => {
    const labels = useLabels();
    const l = useMemo(() => {
        return {
            accordionHl: labels.advancedSettings,
            beta: labels.beta,
            model: labels.model,
            modelIconTitle: labels.modelIconTitle,
            modalHl: labels.modelDescriptions,
            choosePersona: labels.choosePersona,
            choosePersonaDescriptions: labels.choosePersonaDescriptions,
            choosePersonaIconTitle: labels.choosePersonaIconTitle
        };
    }, [labels]);


    const isMobile = useMediaSize(ms => ms <= MediaSize.sm);
    const advancedPersonaSelected = !persona;

    const slider = <TemperatureSlider value={(temperature ?? defaultTemperature) * 10} onChange={v => onModelMerge({ temperature: parseFloat((v * 0.1).toFixed(3)) })} isDisabled={isInputDisabled} />;
    const renderPersonaOptions = !personaOptions?.length ? null : <div className={style['settings-personas']}>
        <InputLabel
            label={l.choosePersona}
            tooltipPlacement={'top-start'}
            modal={{ headline: l.choosePersona, body: l.choosePersonaDescriptions, }}
            isDisabled={isInputDisabled}
            iconTitle={l.choosePersonaIconTitle}
            className={style['select-label']} />
        <div className={style['settings-personas-options']}>
            {personaOptions.map((o, i) => {
                const isSelected = o.key === persona;
                return <div className={style['persona-container']} key={i}>
                    <Button label={o.title} theme={isSelected ? ButtonThemes.primary : ButtonThemes.secondary} isDisabled={isInputDisabled} onClick={() => onModelMerge({ persona: o.key })} />
                    {o.beta && <Tag label={l.beta} className={style.beta + ` ${(isInputDisabled ? style.disabled : "")}`} />}
                </div>;
            })}
        </div>
        <label className={getClassNames([style['persona-note'], 'df-helper-text'])}>{personaOptions.find(x => x.key === persona)?.description}</label>
    </div>;

    const llmModalBody = <LlmModalBody llmOptions={llmOptions} />;
    const llmUIOptions = useMemo(() => llmOptions.map(x => ({ ...x, 'data-testid': TestIds.dfSelectOption })), [llmOptions]);
    const renderAdvancedPersonaOptions = advancedPersonaSelected && <>
        {isMobile && <Accordion
            isDisabled={isInputDisabled}
            className={style.accordion}
            isInitiallyOpen={true}
            headline={l.accordionHl}
            content={<>
                <Divider />
                {slider}
            </>} />}
        <div>
            <InputLabel
                testId={TestIds.advancedOptionsModal}
                iconTestId={TestIds.advancedOptionsModalIcon}
                label={l.model}
                tooltipPlacement={'top-start'}
                modal={{ headline: l.modalHl, body: llmModalBody, }}
                iconTitle={l.modelIconTitle}
                isDisabled={isInputDisabled}
                className={style['select-label']}
            />
            <DfSelect
                data-testid={TestIds.dfSelect}
                showSelectedOptions
                disabled={isInputDisabled}

                value={llmUIOptions.find(x => x.value === llm)}
                onSelectChange={(e) => onModelMerge({ llm: (e.detail.value as any).value as string })}
                options={llmUIOptions} />
        </div>

        {!isMobile && slider}
    </>;

    return <div className={style['settings-container']}>
        {renderPersonaOptions}
        {renderAdvancedPersonaOptions}
    </div>;
});

const LlmModalBody: React.NamedExoticComponent<Pick<ChatInputPropsBase, "llmOptions">> = memo(({ llmOptions }) => {
    const [openAccordions, setOpenAccordions] = useState<number[]>([]);
    const onToggleAccordionHandler = useCallback((index: number) => {
        setOpenAccordions(prev => {
            const isOpen = prev.includes(index);
            return isOpen ? prev.filter(i => i !== index) : [...prev, index];
        });
    }, []);

    return <>
        {
            llmOptions.map((llm, i) => <Accordion
                key={i}
                headline={llm.label}
                content={<p className={style['accordion-desc']}>{llm.description}</p>}

                externalState={{
                    isOpen: openAccordions.includes(i),
                    onToggle: (isOpen: boolean) => onToggleAccordionHandler(i)
                }}
                variant={AccordionVariantEnum.WithDivider}
            />)
        }
    </>;
});