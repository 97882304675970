import React from 'react';
import { ReactComponent as LoaderIcon } from '@danfoss/mosaic-icons/dist/svg/loader-circle.svg';
import { getClassNames } from '../../helpers/classHelpers';
import { TestIds } from '../../mocks/ids';
import style from './LoadingIcon.module.scss';

type Props = {
    /** In px. Default to 24px */
    size?: number;
    className?: string;
};

const LoadingIcon: React.FC<Props> = ({ size = 24, className }) => {
    return (
        // This icon is not in Material Icons set, thus importing it from mosaic-icons 
        <LoaderIcon data-testid={TestIds.loadingIcon} className={getClassNames([className, style.icon])} style={{ width: `${size}px` }} />
    );
};

export default LoadingIcon;