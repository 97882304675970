import { ConversationEnquiry, ErrorMessage, ResponseContext, SystemMessage } from "../models/ChatTypes";
import { parseAndHandle } from "./jsonHelper";
import { isString } from "./typeHelpers";

export enum StructuredContent {
    SystemMessage = 'SystemMessage',
    ResponseContext = 'ResponseContext',
    EnquiryAnalysis = 'EnquiryAnalysis',
    ErrorInformation = 'PromptErrorInformation',
    TrustedHtmlContent = 'TrustedHtmlContent'
}

/**For StructuredContent.PromptErrorInformation, the following codes are used */
export enum ChatMessageErrorCode {
    ContentFilterViolation = 'content_filter_violation',
    TokenLimitExceeded = 'token_limit_exceeded',
    Cancelled = 'cancelled',
    Unknown = 'unknown_error'
}


export type StructuredContentModel = {
    [StructuredContent.SystemMessage]: SystemMessage,
    [StructuredContent.EnquiryAnalysis]: ConversationEnquiry,
    [StructuredContent.ResponseContext]: ResponseContext,
    [StructuredContent.TrustedHtmlContent]: string,
    [StructuredContent.ErrorInformation]: ErrorMessage;
};
type StructuredContentParseResult = {
    tag: StructuredContent;
    text: string;
    raw: string;
};

export type StructuredContentHandler<T extends StructuredContent> = (model: StructuredContentModel[T]) => void;
export type StructuredContentErrorHandler = (error: SyntaxError, parseResult: StructuredContentParseResult) => void;

const getFlatXmlTagRegexFlag = "gms";
const getFlatXmlTagRegexExp = (tag: string) => `<${tag}>(.+?)</${tag}>`;
const getFlatXmlTagRegex = (tag: string, flags: string = getFlatXmlTagRegexFlag) => new RegExp(getFlatXmlTagRegexExp(tag), flags);

// Prompt Errors
const promptErrorTag = StructuredContent.ErrorInformation;
export const promptErrorTagRegex = getFlatXmlTagRegex(promptErrorTag, 'ms');
export const wrapAsMarkdownError = (message: string, code?: string) => `<${promptErrorTag}>${JSON.stringify({ message: message, code })}</${promptErrorTag}>`;

// Trusted HTML 
export const trustedHtmlTag = StructuredContent.TrustedHtmlContent;
export const trustedHtmlTagTagRegex = getFlatXmlTagRegex(trustedHtmlTag, 'ms');

export const structuredContentKeys = Object.values(StructuredContent);
export const structuredContentTags = structuredContentKeys.map(key => ({ open: `<${key}>`, close: `</${key}>` }));
export const structuredContentSplitExp = new RegExp(`(${structuredContentTags.map(({ open, close }) => `${open}.+?${close}`).join("|")})`, getFlatXmlTagRegexFlag);
export const structuredContentExtractSignalExp = new RegExp(`^(<(?<tag>${structuredContentKeys.join('|')})>(?<text>.+?)</.*>)$`);
/**Splits a string by complete structured content (opened and closed) 
 * For example:
 * content: TEXT<SystemMessage>{ "a": "b" }</SystemMessage><SystemMessage>{ "a": 
 * result: [ "TEXT", '<SystemMessage>{ "a": "b" }</SystemMessage>', '<SystemMessage>{ "a": ' ]
*/
export const splitByStructuredContent = (content: string) => {
    if (!content) return [];
    return content.split(structuredContentSplitExp);
};


export const toStructuredContentOrString = (content: string): string | StructuredContentParseResult => {
    if (!content || content[0] !== '<') return content;
    const groups = content.match(structuredContentExtractSignalExp)?.groups;
    if (!groups) return content;

    const { tag, text } = groups;
    if (!tag || !text) return content;

    return { tag: tag as StructuredContent, text, raw: content };
};

/**Used to check stream chunk for unclosed structured tags. This indicates that this chunk should not be processed until the next chunk arrives */
export const isIncompleteStructuredContent = (content: string) => {
    // If the content does not start with an xml tags opening, then there this can't be a structured content.
    if (!content || content[0] !== '<') {
        return false;
    }
    for (let i = 0; i < structuredContentTags.length; i++) {
        const { open, close } = structuredContentTags[i];
        if (content.startsWith(open) && !content.endsWith(close)) {
            return true;
        }
    }
    return false;
};
export const processorStructuredContent = <T extends StructuredContent>(
    parseResult: StructuredContentParseResult,
    handler: StructuredContentHandler<T>,
    errorHandler?: StructuredContentErrorHandler
) => {
    parseAndHandle<StructuredContentModel[T]>(parseResult.text, handler, e => errorHandler?.(e, parseResult));
};

export type StructuredContentHandlers = { [T in StructuredContent]?: StructuredContentHandler<T> };
export interface IMessageStreamHandler {
    onContent: (content: string) => void;

    structuredContentHandlers: StructuredContentHandlers;
    structuredContentPraseErrorHandlers?: StructuredContentErrorHandler;
}

/**When streaming content, we receive it in chunks.
 * The content contains text and structured content that arrive within a set of xml tags (@see StructuredContent)
 * The chunk can contain both regular and structured content. 
 * So we start by splitting on structured content. 
 * 
 * Ex: "TEXT<SystemMessage>{ "a": "b" }</SystemMessage><SystemMessage>{ "a": " 
 *  => [ 
 *  (1): "TEXT", 
 *  (2): '<SystemMessage>{ "a": "b" }</SystemMessage>', 
 *  (3): '<SystemMessage>{ "a": ' ]
 * When a chunk arrives, there are three scenarios (as seen in the example above): 
 * 1- The chunk is just text to be added as regular content. 
 * 2- The chunk is wrapped within one of the structured content xml tags 
 * 3- The chunk is a part of a structured content.
 *  - In this case, we carry the chunk until the next chunk arrives so we can parse the structured content correctly.  
 */
export class MessageStreamProcessor {
    private carriedContent?: string;
    constructor(public streamHandler: IMessageStreamHandler) {

    }
    processChunk = (chunk: string) => {
        if (this.carriedContent) {
            chunk = this.carriedContent + chunk;
            this.carriedContent = undefined;
        }

        if (!chunk) return;

        const parts = splitByStructuredContent(chunk).map(toStructuredContentOrString);
        
        parts.forEach(this.processChunkPart);
    };

    private processChunkPart = (part: ReturnType<typeof toStructuredContentOrString>) => {
        if (isString(part)) {
            if (isIncompleteStructuredContent(part)) {
                return this.carriedContent = part;
            }
            else
                return this.streamHandler.onContent(part);
        }
        const tag = part.tag;
        const structuredContentHandler = this.streamHandler.structuredContentHandlers[tag] as StructuredContentHandler<typeof tag>;
        if (!structuredContentHandler)
            return this.streamHandler.onContent(part.raw);

        processorStructuredContent(part, structuredContentHandler, this.streamHandler.structuredContentPraseErrorHandlers);
    };
}