import { useCallback, useEffect, useRef, useState } from "react";
import { MediaOrientation, MediaSize } from '../constants/consts';

export const useMediaQuery = <T,>(callback: (inRange: boolean) => T, min?: MediaSize, max?: MediaSize) => {
    const inRangeRef = useRef<boolean>();
    const stateRef = useRef<T>();

    const handler = useCallback((currentSize: MediaSize) => {
        const inRange = (min !== undefined && currentSize >= min) || (max !== undefined && currentSize <= max);
        if (inRangeRef.current === inRange) return stateRef.current;

        const newState = callback(inRange);
        inRangeRef.current = inRange;
        stateRef.current = newState;
        return newState;
    }, [min, max, callback]);

    const state = useMediaSize(handler);

    return state;
};

export const useMediaSize = <T,>(callback: (mediaSize: MediaSize) => T) => {
    const [state, setState] = useState(callback(getMediaSize()));

    useEffect(() => {
        const sizeChangeHandler = (e: any) => setState(callback(getMediaSize(e.target.innerWidth)));
        window.addEventListener('resize', sizeChangeHandler);
        setState(callback(getMediaSize()));
        return () => window.removeEventListener('resize', sizeChangeHandler);
    }, [callback]);

    return state;
};

export const useMediaOrdination = <T,>(callback: (mediaOrientation: MediaOrientation) => T) => {
    const [state, setState] = useState(callback(getMediaOrientation()));

    useEffect(() => {
        const sizeChangeHandler = (e: any) => setState(callback(getMediaOrientation(e.target.innerWidth, e.target.innerHeight)));
        window.addEventListener('resize', sizeChangeHandler);
        setState(callback(getMediaOrientation()));
        return () => window.removeEventListener('resize', sizeChangeHandler);
    }, [callback]);

    return state;
};


const getMediaSize = (width: number = window.innerWidth) => {
    let size = MediaSize.lg;
    Object.entries(MediaSize).forEach(([k, v]) => {
        const $v = parseInt(v as string);
        if (width <= $v) {
            size = MediaSize[k as any] as any as MediaSize;
        }
    });
    return size;
};


const getMediaOrientation = (width: number = window.innerWidth, height: number = window.innerHeight) => {
    return width > height ? MediaOrientation.landscape : MediaOrientation.portrait
};
