import React, { useCallback, useMemo, useRef, useState } from 'react';
import Modal from '../../ui/modal/Modal';
import Textarea from '../../ui/textarea/Textarea';
import useLabels from '../../hooks/useLabels';
import useTextareaMutations from '../../hooks/useTextareaMutations';
import ButtonAsync from '../../ui/button/ButtonAsync';
import Button, { ButtonThemes } from '../../ui/button/Button';
import { Icon } from '../../ui/icon/Icon';
import { ObjectTypes, usePathRouting } from '../../ui/markdown-wrapper/hooks/useActions/useActions';
import { Vote } from '../../models/types';
import { copyToClipboard } from '../../helpers/stringHelpers';
import { ChatMessageStreamingResponse } from '../chat-window/models';
import { IconStyles } from '../../ui/icon/Icon.types';
import { makeHashPath } from '../../helpers/hashRouting';
import { Icons } from '../../ui/icon/icons/material';
import style from './MessageActions.module.scss';


type Props = {
    message: ChatMessageStreamingResponse;

    isLiked: boolean;
    onLikeClick: () => Promise<void>;

    isDisliked: boolean;
    onDislikeClick: () => Promise<void>;

    onLoadFeedback: () => Promise<void>;
    loadedComment?: string; // for checking if comment was changed
    comment: string;

    onCommentChange: (value: string) => void;
    onCommentSave: (value: string) => Promise<void>;
};

const ICON_SIZE = 16;

const MessageActions: React.FC<Props> = ({ isLiked, onLikeClick, isDisliked, onDislikeClick, onLoadFeedback, loadedComment, comment, message, onCommentChange, onCommentSave }) => {
    const labels = useLabels();
    const l = useMemo(() => {
        return {
            modalHl: labels.commentHeadline,
            modalDescription: labels.commentDescription,
            textareaLabel: labels.comment,
            s: labels.submit,
            c: labels.cancel,
            likeTitle: labels.likeTitle,
            dislikeTitle: labels.dislikeTitle,
            copyTitle: labels.copyTitle,
            messageInfoTitle: labels.messageInfoTitle,
        };
    }, [labels]);

    const textareaRef = useRef<HTMLTextAreaElement>(null);
    useTextareaMutations(textareaRef);

    const [showModal, setShowModal] = useState(false);
    const [isVoteLoading, setIsVoteLoading] = useState<false | Vote>(false);
    const onCloseModalHandler = useCallback(() => {
        setShowModal(false);
        onCommentChange('');
    }, [onCommentChange]);

    const onVoteClickHandler = useCallback((callback: () => Promise<void>, loadingState: Vote) => async () => {
        if (isVoteLoading) return;

        const isResetting = (loadingState === 1 && isLiked) || (loadingState === -1 && isDisliked);

        setIsVoteLoading(loadingState);
        try {
            await callback();
            if (!isResetting) {
                await onLoadFeedback();
                setShowModal(true);
            }
        } catch (e) {
            console.error(e);
            setShowModal(false);
        } finally {
            setIsVoteLoading(false);
        }
    }, [isDisliked, isLiked, isVoteLoading, onLoadFeedback]);

    const onCopyClickHandler = useCallback(async () => await copyToClipboard(message.content), [message.content]);

    const onCommentChangeHandler = useCallback((value: string) => onCommentChange(value), [onCommentChange]);

    const onCommentSaveHandler = useCallback(async () => {
        try {
            await onCommentSave(comment);
            setShowModal(false);
        } catch (err) {
            console.error('Failed to save comment: ', err);
        }
    }, [comment, onCommentSave]);

    const { invokePathNavigateToSidePanel } = usePathRouting();

    return (<>
        <div className={style['message-actions']}>
            <Icon.Base
                size={ICON_SIZE}
                onClick={onVoteClickHandler(onLikeClick, 1)}
                isDisabled={isVoteLoading === 1}
                isLoading={isVoteLoading === 1}
                title={l.likeTitle}
                iconName={Icons.thumbUp}
                iconStyle={IconStyles[isLiked ? 'filled' : 'outlined']}
            />
            <Icon.Base
                size={ICON_SIZE}
                onClick={onVoteClickHandler(onDislikeClick, -1)}
                isDisabled={isVoteLoading === -1}
                isLoading={isVoteLoading === -1}
                title={l.dislikeTitle}
                iconName={Icons.thumbDown}
                iconStyle={IconStyles[isDisliked ? 'filled' : 'outlined']}
            />
            <Icon.Async
                size={ICON_SIZE}
                title={l.copyTitle}
                iconName={Icons.copy}
                onAsyncClick={onCopyClickHandler}
                flashFeedbackIcon

            />
            {message?.trace_id && <>
                |
                <Icon.Base
                    size={ICON_SIZE}
                    title={l.messageInfoTitle}
                    iconName={Icons.info}
                    onClick={() => invokePathNavigateToSidePanel('/' + makeHashPath(ObjectTypes.trace, message.trace_id!))}
                />
            </>
            }
        </div>

        <Modal
            closeOnEscape
            isOpen={showModal}
            headline={l.modalHl}
            body={<>
                <p className={style.description}>{l.modalDescription}</p>
                <Textarea
                    ref={textareaRef}
                    resize='none'
                    label={l.textareaLabel}
                    value={comment}
                    onChange={onCommentChangeHandler}
                />
            </>}
            footer={<>
                <Button label={l.c} onClick={onCloseModalHandler} theme={ButtonThemes.textPrimary} />
                <ButtonAsync label={l.s} onClick={onCommentSaveHandler} isDisabled={!comment.trim() || comment === loadedComment} />
            </>}
            onClose={onCloseModalHandler}
        />
    </>);
};

export default MessageActions;