import { type ReactNode, useMemo } from 'react';
import { useAppInfo, AuthenticationState } from '../contexts/app-info/AppInfoContext';
import { useAuth } from '../contexts/auth/AuthContext';
import Button from '../ui/button/Button';
import useLabels from './useLabels';

export default function useAuthenticationStateErrorContent(): {
    headline: string;
    description?: string;
    children?: ReactNode;
    button: ReactNode;
} {
    const labels = useLabels();
    const { logout } = useAuth();
    const { authenticationState } = useAppInfo();

    const renderButton = useMemo(() => {
        return <Button label={labels.logout} onClick={logout} />;
    }, [labels.logout, logout]);

    const toParagraphs = (text: string) => text.split('\n').map((d, i) => <p key={i}>{d}</p>);

    switch (authenticationState) {
        case AuthenticationState.UnableToAuthenticate: return {
            headline: labels.welcome,
            description: labels.notAuthorizedDescription,
            button: renderButton,
        };
        case AuthenticationState.MissingRole: return {
            headline: labels.welcome,
            description: labels.notAuthorizedYetAlertHeadline,
            children: toParagraphs(labels.notAuthorizedYetAlertDescription),
            button: renderButton,
        };
        case AuthenticationState.AccessBlocked: return {
            headline: labels.welcome,
            description: labels.accessBlockedAlertHeadline,
            button: renderButton,
        };
        case AuthenticationState.UnknownError:
        default: return {
            headline: labels.welcome,
            description: labels.unknownErrorDescription,
            button: renderButton,
        };
    }
}