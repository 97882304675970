import React from 'react';
import style from './SidebarChildren.module.scss';
import { getClassNames } from '../../helpers/classHelpers';

type Props = {
    className?: string;
    children: React.ReactNode;
};

const SidebarChildren: React.FC<Props> = ({ className, children }) => {
    return (
        <div className={getClassNames([style.container, className])}>
            {children}
        </div>
    );
};

export default SidebarChildren;