import React from 'react';
import useLabels from '../../hooks/useLabels';
import { Icon } from '../../ui/icon/Icon';
import { DfMenuItem } from '@danfoss/mosaic-react';
import { Icons } from '../../ui/icon/icons/material';
import style from './SidebarHeader.module.scss';

type Props = {
    children: React.ReactNode;
    onClose?: () => void;
    iconName?: Icons;
};

const SidebarHeader: React.FC<Props> = ({ iconName, children, onClose }) => {
    const labels = useLabels();
    return (
        <>
            {(children) && <DfMenuItem className={style.container}>
                <div className={style.wrapper}>
                    {onClose && <Icon.Base title={labels.closeSidebarIconTitle} iconName={iconName ?? Icons.clear} onClick={onClose} />}
                    {children}
                </div>
            </DfMenuItem>}
        </>
    );
};

export default SidebarHeader;