import { PropsWithChildren, createContext, useCallback, useContext, useEffect, useMemo, useRef, useState } from 'react';
import { useMediaSize } from '../../hooks/useMediaSize';
import { MediaSize } from '../../constants/consts';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { extractHashProps, getHashPath, getHashRoutePaths } from '../../helpers/hashRouting';

export type RightSidebarProps = {
    header?: React.ReactNode;
    children?: React.ReactNode;
    metadata: Record<string, string> | undefined | {
        objectId: string;
        objectType: string;
    };
};

export type LayoutContextProps = {
    isLeftSidebarVisible: boolean,
    showLeftSidebar: () => void;
    hideLeftSidebar: () => void;

    isRightSidebarVisible: boolean;
    showRightSidebar: () => void;
    hideRightSidebar: () => void;

    rightSidebarContentStack: RightSidebarProps[];
    rightSidebarContentStackLength: number;
    rightSidebarContent?: RightSidebarProps;

    /** Adds to the right side content stack */
    addRightSidebarContent: (content: RightSidebarProps, currentHashPaths?: string[]) => void;
    /** Removes the currently open right side content from the stack */
    removeRightSidebarContent: () => void;
    /** Clears the whole right side content stack  */
    clearRightSidebarContent: () => void;
};

const defaultLayout = {
    isLeftSidebarVisible: false,
    showLeftSidebar: () => { },
    hideLeftSidebar: () => { },

    isRightSidebarVisible: false,
    showRightSidebar: () => { },
    hideRightSidebar: () => { },

    rightSidebarContentStack: [],
    rightSidebarContentStackLength: 0,

    rightSidebar: undefined,
    addRightSidebarContent: () => { },
    removeRightSidebarContent: () => { },
    clearRightSidebarContent: () => { },
};

const LayoutContext = createContext(defaultLayout as LayoutContextProps);

export const LayoutProvider = (props: PropsWithChildren<{}>) => {
    const navigate = useNavigate();
    const location = useLocation();
    const [rightSidebarContentStack, setRightSidebarContentStack] = useState<RightSidebarProps[]>([]);
    const [isRightSidebarVisible, setIsRightSidebarVisible] = useState(false);
    const [isLeftSidebarVisible, setIsLeftSidebarVisible] = useState(false);

    const showLeftSidebar = useCallback(() => setIsLeftSidebarVisible(true), []);
    const hideLeftSidebar = useCallback(() => setIsLeftSidebarVisible(false), []);

    const addRightSidebarContent = useCallback((content: RightSidebarProps) => {
        const { objectId, objectType } = content?.metadata ?? {};
        setRightSidebarContentStack(prev => {
            if (prev.find(({ metadata }) => (objectId === metadata?.objectId && objectType === metadata?.objectType))) {
                return prev;
            }
            return [...prev, content];;
        });
    }, []);

    const showRightSidebar = useCallback(() => setIsRightSidebarVisible(true), []);

    const clearRightSidebarContent = useCallback(() => setRightSidebarContentStack([]), []);

    const hideRightSidebar = useCallback(() => {
        setIsRightSidebarVisible(false);
        clearRightSidebarContent();
        navigate({ hash: '' }, { replace: true });
    }, [clearRightSidebarContent, navigate]);

    const removeRightSidebarContent = useCallback(() => {
        setRightSidebarContentStack(c => {
            const contents = c?.filter(x => x !== c[c.length - 1]);
            if (!contents?.length) hideRightSidebar();
            return contents;
        });
        const { uriWithoutLastPath } = getHashPath(location.hash);
        navigate({ hash: uriWithoutLastPath }, { replace: true });
    }, [hideRightSidebar, navigate, location.hash]);

    const isTabletSize = useMediaSize(ms => ms >= MediaSize.md);
    const autoPresetLeftSidebar = useMemo(() => !isRightSidebarVisible && isTabletSize, [isRightSidebarVisible, isTabletSize]);

    const value = useMemo(() => ({
        isLeftSidebarVisible: isLeftSidebarVisible || autoPresetLeftSidebar,
        showLeftSidebar,
        hideLeftSidebar,

        isRightSidebarVisible,
        showRightSidebar,
        hideRightSidebar,

        rightSidebarContentStack,
        rightSidebarContentStackLength: rightSidebarContentStack.length,
        rightSidebarContent: rightSidebarContentStack[rightSidebarContentStack.length - 1],
        addRightSidebarContent,
        removeRightSidebarContent,
        clearRightSidebarContent,
    }), [rightSidebarContentStack, isRightSidebarVisible, autoPresetLeftSidebar, isLeftSidebarVisible, removeRightSidebarContent, clearRightSidebarContent, addRightSidebarContent, showLeftSidebar, hideLeftSidebar, showRightSidebar, hideRightSidebar]);

    // Close right sidebar upon location change
    const pathnameRef = useRef(location.pathname);
    useEffect(() => {
        if (pathnameRef.current && location.pathname === pathnameRef.current) return;
        pathnameRef.current = location.pathname;
        hideRightSidebar();
    }, [location, hideRightSidebar]);

    return <LayoutContext.Provider value={value}>
        {props.children}
    </LayoutContext.Provider>;
};

export const useLayout = () => useContext(LayoutContext);

// Listen to hash changes and invoke the action side panel
export const useInvokeSidePanel = (callback: (id?: string, type?: string, chatId?: string) => void) => {
    const { hash } = useLocation();
    const { chatId } = useParams();
    
    useEffect(() => {
        (async () => {
            const hashes = getHashRoutePaths(hash);

            for (const hash of hashes) {
                const { objectType, objectId } = extractHashProps(hash);
                await new Promise(resolve => setTimeout(() => {
                    if (!objectType) return;
                    resolve(callback(objectId, objectType, chatId));
                }, 10)); // Prevent wrong order of execution/race condition
            }
        })();
    }, [chatId, hash, callback]);
};