import React, { CSSProperties, forwardRef } from 'react';
import InputLabel from '../input-label/InputLabel';
import { getClassNames } from '../../helpers/classHelpers';
import { BaseUIType } from '../../models/UITypes';
import style from './Textarea.module.scss';

export type TextareaProps = BaseUIType & {
    label?: string;
    value: string;
    onChange: (value: string) => void;
    onKeyUp?: (e: React.KeyboardEvent<HTMLTextAreaElement>) => void;
    onKeyDown?: (e: React.KeyboardEvent<HTMLTextAreaElement>) => void;
    placeholder?: string;

    resize?: CSSProperties['resize'];
    withIcon?: boolean;
    isRequired?: boolean;
    isInvalid?: boolean;
    isDisabled?: boolean;

    className?: string;
    /**Default 1000 */
    maxLength?: number;
};

const Textarea = forwardRef<HTMLTextAreaElement, TextareaProps>(({ label, value, onChange, onKeyDown, placeholder, resize = 'none', withIcon, isRequired, isInvalid, isDisabled, maxLength, className, testId, }, ref) => {
    return (<>
        {label && <InputLabel label={label} isRequired={isRequired} />}
        <textarea
            maxLength={maxLength ?? 1000}
            data-testid={testId}
            disabled={isDisabled}
            placeholder={placeholder}
            className={getClassNames(['df-input', style.textarea, className, withIcon && style['with-icon']])}
            ref={ref}
            value={value}
            aria-invalid={isInvalid}
            onKeyDown={onKeyDown}
            onChange={(e) => onChange(e.target.value)}
            style={{ resize }}
        />
    </>);
});

export default Textarea;