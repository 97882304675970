import React, { type PropsWithChildren, useMemo } from 'react';
import AppInfoProvider from './AppInfoContext';
import MockAppInfoService from '../../services/MockAppInfoService';
import { useAuth } from '../auth/AuthContext';

const MockAppInfoServiceProvider: React.FC<PropsWithChildren> = ({ children }) => {
    const { getAccessToken } = useAuth();

    const service = useMemo(() => new MockAppInfoService(getAccessToken), [getAccessToken]);

    return <AppInfoProvider service={service}>
        {children}
    </AppInfoProvider>;
};

export default MockAppInfoServiceProvider;