import { useEffect } from "react";
import styles from './useFormStyling.module.scss';

const TARGET_CLASS_NAME = 'trusted-html'; // TODO: Change this to the actual class name
const listenerMap = new WeakMap();

const constructTargetClass = (className?: string) => [`.${TARGET_CLASS_NAME}`, className].filter(Boolean).join(' ');
const getTargetElements = () => document.getElementsByClassName(TARGET_CLASS_NAME);

const getButtonElements = (index: number) => {
    const target = getTargetElements()?.[index];
    return target.querySelectorAll('button');
};
const getSelectElements = (index: number) => {
    const target = getTargetElements()?.[index];
    return target.querySelectorAll('select');
};
const getRadioElements = (index: number) => {
    const target = getTargetElements()?.[index];
    return target.querySelectorAll('input[type="radio"]');
};
const getCheckboxElements = (index: number) => {
    const target = getTargetElements()[index];
    return target.querySelectorAll('input[type="checkbox"]');
};
const getLabelElements = (index: number) => {
    const target = getTargetElements()[index];
    return Array.from(target.querySelectorAll('label'));
};
const getBreakElements = (index: number) => {
    const target = getTargetElements()[index];
    return Array.from(target.querySelectorAll('br'));
};

// Apply style to <button>'s
const initButton = (inputIndex: number) => {
    const trustedHtml = getTargetElements();

    const buttons = getButtonElements(inputIndex);

    buttons.forEach((button: ChildNode) => {
        if (listenerMap.has(button)) return;

        if (inputIndex !== (trustedHtml.length - 1)) {
            const el = button as HTMLButtonElement;
            el.disabled = true;
        }

        const btn = button as HTMLButtonElement;
        ['df-btn-primary', 'df-btn-small'].forEach(cl => btn?.classList?.add(cl));
        const h = () => alert('Button click');
        btn.addEventListener('click', h);
        listenerMap.set(button, h);
    });
};

// General radio/checkbox input processor
const processInputs = (inputIndex: number, elements: NodeListOf<Element>, fieldsetClassNames: string[], containerClassNames: string[]) => {
    const trustedHtml = getTargetElements();

    const labels = getLabelElements(inputIndex);
    const fieldset = document.createElement('fieldset');
    fieldset.classList.add(...fieldsetClassNames);

    for (let index = 0; index < elements.length; index++) {
        const element = elements[index];
        if (listenerMap.has(element) || (element as HTMLInputElement).disabled) return;

        const parentId = element.id;
        const label = labels.find((label) => (label as HTMLLabelElement).htmlFor === parentId);

        const container = document.createElement('div');
        container.classList.add(containerClassNames.join(' '));


        if (inputIndex !== (trustedHtml.length - 1)) {
            const el = element as HTMLInputElement;
            el.disabled = true;

            const isChecked = el.checked || true;
            el.checked = isChecked;
        }
        container.appendChild(element);
        label && container.appendChild(label!);
        fieldset.appendChild(container);

        const h = () => { alert('Input change detected!'); };
        element.addEventListener('change', h);
        listenerMap.set(element, h);
    }

    trustedHtml[inputIndex].insertBefore(fieldset, trustedHtml[inputIndex].firstChild);
};

// Apply style on <select>
const initSelectInputs = (inputIndex: number) => {
    const trustedHtml = getTargetElements();

    const selects = getSelectElements(inputIndex);
    if (!selects.length) return;

    const labels = getLabelElements(inputIndex);

    const div = document.createElement('div');
    div.classList.add(styles['select-wrapper']);

    for (let index = 0; index < selects.length; index++) {
        const selector = selects[index];
        if (listenerMap.has(selector) || selector.disabled) return;

        const selectorId = selector.id;
        const label = labels.find((label) => (label as HTMLLabelElement).htmlFor === selectorId);

        selects[index].classList.add('df-select');
        label && div.appendChild(label);
        div.appendChild(selects[index]);

        if (inputIndex !== (trustedHtml.length - 1)) {
            const el = selector as HTMLSelectElement;
            el.disabled = true;
        }

        const h = () => { alert('Input change detected!'); };
        selector.addEventListener('change', h);
        listenerMap.set(selector, h);
    }

    trustedHtml[inputIndex].insertBefore(div, trustedHtml[inputIndex].firstChild);
};

// Apply style on <input type='radio'>
const initRadioInputs = (index: number) => {
    const radios = getRadioElements(index);
    if (!radios.length) return;
    processInputs(index, radios, ['df-radio-group', 'inline'], ['df-radio']);
};

// Apply style on <input type='checkbox'>
const initCheckboxInputs = (index: number) => {
    const checkboxes = getCheckboxElements(index);
    if (!checkboxes.length) return;

    processInputs(index, checkboxes, ['df-checkbox-group', 'inline'], ['df-checkbox']);
};

// Remove unnecessary elements E.g: <br>/<fieldset> tags
const removeUnnecessaryElements = (index: number) => {
    const trustedHtml = getTargetElements();
    trustedHtml[index].classList.add(styles['form-styling']);
    const brs = getBreakElements(index);
    brs.forEach((br: ChildNode) => trustedHtml[index].removeChild(br));

    // Remove redundant <fieldset>s // TODO: Improve
    const target = trustedHtml[index];
    const fieldsetList = target.querySelectorAll(constructTargetClass('fieldset'));

    Object.values(fieldsetList).forEach((fieldset: ChildNode) => {
        const children = Object.values(fieldset?.childNodes);
        const empty = !children.length || children.every((v: any) => !v.children.length);
        if (empty) target.removeChild(fieldset);
    });
};

const processTrustedHTML = (callback: (element: Element, index: number) => void) => Object.values(getTargetElements() || []).forEach(callback);

const init = () => processTrustedHTML((_, index) => {
    initButton(index);
    initSelectInputs(index);
    initRadioInputs(index);
    initCheckboxInputs(index);
    removeUnnecessaryElements(index);
});

export default function useFormStyling({ isActive }: { isActive: boolean; }) {
    useEffect(() => {
        if (!isActive) return;
        init();
        return () => { };
    }, [isActive]);
};