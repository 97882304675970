import { useEffect, useRef } from "react";

/**Retains a ref that is always updated with the latest value. Useful when a non-reactive ref is need to a reactive state*/
export default function useRefLive<T>(value: T, onChange?: (state: T) => void) {
    const ref = useRef(value);
    useEffect(() => {
        ref.current = value;
        onChange?.(value)
    }, [value, onChange]);
    return ref;
} 