import React from 'react';
import { getClassNames } from '../../helpers/classHelpers';
import style from './Tag.module.scss';

type Props = {
    label: string;
    hideBorder?: boolean;
    className?: string;
};

const Tag: React.FC<Props> = ({ label, hideBorder, className }) => {
    return (
        <span className={getClassNames([className, style.tag, hideBorder && style['_no-border']])}>
            {label}
        </span>
    );
};

export default Tag;