import React, { HTMLInputTypeAttribute } from 'react';
import { getClassNames } from '../../helpers/classHelpers';

export type InputProps = {
    id?: string;
    value: string;
    onChange: (value: string) => void;
    placeholder?: string;
    type?: HTMLInputTypeAttribute;

    isDisabled?: boolean;
    className?: string;
};

const Input: React.FC<InputProps> = ({ id, value, onChange, placeholder, type = 'text', isDisabled, className, }) => {
    return (
        <input
            id={id}
            type={type}
            placeholder={placeholder}
            className={getClassNames(['df-input', className])}
            disabled={isDisabled}
            value={value}
            onChange={(e) => onChange(e.target.value)}
        />
    );
};

export default Input;