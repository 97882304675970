import React, { useState, useCallback, useMemo } from 'react';
import UserInstructionsModal from '../ui/user-instructions-modal/UserInstructionsModal';

function useUserInstructionsModal() {
    const [isModalOpen, setIsModalOpen] = useState(false);
    const openModal = useCallback(() => setIsModalOpen(true), []);
    const closeModal = useCallback(() => setIsModalOpen(false), []);

    const renderModal = useMemo(() => {
        return <UserInstructionsModal isModalOpen={isModalOpen} closeModal={closeModal} />;
    }, [closeModal, isModalOpen]);

    return {
        openModal,
        closeModal,
        renderModal,
    };
}

export default useUserInstructionsModal;