const upperCaseExp = /[A-Z]/g;
const dashTidyUpExp = /^-|-{2,}/g;

type classCollection = ((boolean | string | undefined | null) | { [className: string]: boolean | undefined; } | classCollection)[];


const toCamelCase = (val?: string) => !val ? val : val.replace(upperCaseExp, (letter) => '-' + letter.toLowerCase()).replace(dashTidyUpExp, "");

const getClassName = (className: string, classPrefix?: string, baseClass?: string) => {
    className = classPrefix && className !== classPrefix && className.indexOf(classPrefix) === -1 ? `${classPrefix}_${className}` : className;
    className = baseClass && className !== baseClass && className.indexOf(baseClass + "-") === -1 ? `${baseClass}-${className}` : className;
    return className;
};

export const getClassNames = (classList: classCollection, classPrefix?: string, baseClass?: string, addClassPrefixToList = true) => {
    classList = classList || [];
    if (addClassPrefixToList && classPrefix && classList.indexOf(classPrefix) === -1) {
        classList.splice(0, 0, classPrefix);
    }

    const strings = classList
        .filter(x => !!x)
        .map(i => {
            if (i instanceof Array) {
                return getClassNames(i as classCollection, classPrefix, baseClass, false);
            }
            if (i instanceof Object) {
                return getClassNames(Object.keys(i).filter(k => i[k]).map(toCamelCase), classPrefix, baseClass, false);
            }

            return i;
        })
        .filter(x => x && x !== true && !!(x.trim())) as string[];
    return strings.map(x => getClassName(x, classPrefix, baseClass)).join(' ');
};
