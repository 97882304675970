import { useCallback, useMemo, useRef, useState } from 'react';
import { useAuth } from '../../contexts/auth/AuthContext';
import style from './promo.module.scss';
import { Icon } from '../../ui/icon/Icon';
import { Icons } from '../../ui/icon/icons/material';
import { IconStyles, IconTheme } from '../../ui/icon/Icon.types';
import LoadingIcon from '../../ui/loading-icon/LoadingIcon';
import Button, { ButtonThemes } from '../../ui/button/Button';
import { useNavigate } from 'react-router-dom';
import { AppRoute } from '../../router/Routing';
import { useMediaOrdination } from '../../hooks/useMediaSize';
import { MediaOrientation } from '../../constants/consts';
import EnvConfigHelper from '../../helpers/envConfigHelper';

const baseUrl = `${EnvConfigHelper.get('videos-base-url')}/dcs-assistant/promo-v1`
const videoUrl = {
    [MediaOrientation.landscape]: `${baseUrl}/landscape.mp4`,
    [MediaOrientation.portrait]: `${baseUrl}/portrait.mp4`
}
const showIntroBeforeSec = {
    [MediaOrientation.landscape]: .5,
    [MediaOrientation.portrait]: .5
}
const showCtaOnSec = {
    [MediaOrientation.landscape]: 31,
    [MediaOrientation.portrait]: 30.5
}

const DcsPromo: React.FC = () => {

    const navigate = useNavigate()
    const { user } = useAuth()

    const ordination = useMediaOrdination(s => s)

    const videoRef = useRef<HTMLVideoElement>(null)
    const introRef = useRef<HTMLDivElement>(null)
    const overlayRef = useRef<HTMLDivElement>(null)

    const [isLoading, setIsLoading] = useState(false)
    const [isMuted, setIsMuted] = useState(false)
    const [isPlaying, setIsPlaying] = useState(false)


    const [canPlay, setCanPlay] = useState(false)
    const [showIntro, setShowIntro] = useState(true)
    const [showCta, setShowCta] = useState(false)

    const currentTimeRef = useRef(0)
    const onLoadStarted = useCallback(() => { setIsLoading(true); setCanPlay(false) }, [])
    const onCanPlay = useCallback(() => { setIsLoading(false); setCanPlay(true); }, [])
    const onPlaying = useCallback(() => setIsPlaying(true), [])
    const onPause = useCallback(() => setIsPlaying(false), [])

    const onVolumeChange = useCallback(() => setIsMuted(!!videoRef.current?.muted), [])
    const onTimeUpdate = useCallback(() => {
        const currentTime = videoRef.current?.currentTime ?? 0
        currentTimeRef.current = currentTime
        setShowIntro(currentTime <= showIntroBeforeSec[ordination])
        setShowCta(currentTime > showCtaOnSec[ordination])
    }, [ordination])


    const togglePlayState = useCallback(() => videoRef.current?.[isPlaying ? 'pause' : 'play'](), [isPlaying])
    const toggleMuteState = useCallback(() => videoRef.current && (videoRef.current.muted = !videoRef.current.muted), [])

    const playButton = useMemo(() => <Icon.Base
        title='Play / Pause'
        size={54}
        onClick={togglePlayState}
        className={`${style.icon} ${style.play}`}
        iconStyle={IconStyles.filled}
        theme={IconTheme.light}
        iconName={isPlaying ? Icons.pause : Icons.play}
    />, [isPlaying, togglePlayState])

    const muteButton = useMemo(() => <Icon.Base
        title='Mute / Unmute'
        size={54}
        onClick={toggleMuteState}
        className={style.icon}
        iconStyle={IconStyles.filled}
        theme={IconTheme.light}
        iconName={isMuted ? Icons.unmute : Icons.mute}
    />, [isMuted, toggleMuteState])


    return <div className={`${style.root} ${showIntro ? '' : style.hide_intro} ${isPlaying ? style.playing : ''} ${canPlay ? style.ready : ''} ${isLoading ? style.loading : ''}`}>
        <video ref={videoRef} src={videoUrl[ordination]} muted playsInline autoPlay onCanPlay={onCanPlay} controls={false} onPlaying={onPlaying} onPause={onPause} onVolumeChange={onVolumeChange} onTimeUpdate={onTimeUpdate} onLoadStart={onLoadStarted}/>
        <div ref={introRef} className={style.intro}>
            <h1>Hi <b>{user?.name?.split(' ')[0]}</b></h1>
            <LoadingIcon className={`${style.icon} ${style.spinner} ${style.white}`} />
            {playButton}
        </div>
        <div ref={overlayRef} className={style.overlay}>
            {showCta ?
                <Button theme={ButtonThemes.secondary} isLarge={true} className={style.cta} onClick={() => navigate(AppRoute.dcsPimPersona)}>Try AIDA out!</Button>
                :
                <>
                    {playButton}
                    {muteButton}
                </>
            }
        </div>
    </div>
}

export default DcsPromo