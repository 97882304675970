import React, { type PropsWithChildren } from 'react';
import AppContextBootstrap from './AppContextBootstrap';
import EnvConfigHelper from '../../helpers/envConfigHelper';
import MockContextBootstrap from './MockContextBootstrap';

const UI_TEST = EnvConfigHelper.get('ui-test') === 'true';

const ContextBootstrap: React.FC<PropsWithChildren> = ({ children }) => {
    if (UI_TEST) return <MockContextBootstrap children={children} />;
    return <AppContextBootstrap children={children} />;
};

export default ContextBootstrap;