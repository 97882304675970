import { Icon } from '../../icon/Icon';
import { Image } from '../Image';
import { ModalType } from '../Image.types';
import { useEffect, useMemo } from 'react';
import { IconTheme } from '../../icon/Icon.types';
import { createPortal } from 'react-dom';
import { portalId } from '../../../constants/consts';
import { Icons } from '../../icon/icons/material';
import styles from '../Image.module.scss';

// Note: This component is based on the useImageExpanding.tsx
export default function ImageModal({ className, images, targetImage, onClose }: ModalType) {
    const outlet: HTMLElement | null = (typeof window !== "undefined") ? document.getElementById(portalId) : null;

    useEffect(() => {
        if (!targetImage) return;
        const h = (e: KeyboardEvent) => (e.key === 'Escape' || e.key === 'Enter') && onClose();
        document.addEventListener('keydown', h);
        return () => document.removeEventListener('keydown', h);
    }, [targetImage, onClose]);

    const modal = useMemo(() => {
        return <div className={styles['image-modal']}>
            <Icon.Base className={styles['close']} theme={IconTheme.light} iconName={Icons.clear} onClick={onClose} title={''} />
            <Image.Custom className={className} images={images} enableZoom enableThumbs />
        </div>;
    }, [onClose, className, images]);

    const renderModal = useMemo(() => {
        if (!outlet || !targetImage) return null;
        return createPortal(modal, outlet);
    }, [outlet, modal, targetImage]);

    return renderModal;
};