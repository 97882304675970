
import { ChatMessageErrorCode } from "../helpers/messageStreamHelper";
import { ErrorLabels } from "../models/types";

const labels = {
    // Generic
    cancel: 'Cancel',
    close: 'Close',
    delete: 'Delete',
    rename: 'Rename',
    submit: 'Submit',
    goBack: 'Go back',
    retry: 'Retry',

    // Landing page
    welcome: 'Welcome to the Danfoss AI Chatbot',
    welcomeDescription: 'Welcome to Danfoss AI Chatbot, the intelligent AI chatbot experience. This innovative tool can assist you in generating ideas, brainstorming concepts, and crafting compelling content, all while streamlining your daily workflow.',
    login: 'Login',

    // Auth Error pages
    // AuthenticationState related
    notAuthorizedYetAlertHeadline: `We are sorry! Unfortunately, you are not authorized to use this application.`,
    notAuthorizedYetAlertDescription: `We apologize for the inconvenience!
    Please try logging out and then logging back in to see if it helps.
    Please also be aware that we have a pending approval with the German work councils.
    For further troubleshooting, please reach out to the "Data Analytics Community" on Microsoft Teams.`,
    // `We are currently testing the solution with a closed test audience. 
    // We do not currently accept new access requests.
    // You will be notified when this solution is open to a wider audience.`,

    notAuthorizedDescription: 'We are sorry! Unfortunately, you are not authorized to use this application.',

    accessBlockedAlertHeadline: 'We are sorry! Unfortunately, you are not authorized to use this application.',

    unknownErrorHeadline: 'An unknown error has occurred',
    unknownErrorDescription: 'We are sorry! Unfortunately, an unknown error occurred. Please try again later or contact us if the issue persists.',

    // TokenState related
    tokenExpiredHeadline: 'Session expired',
    tokenExpiredDescription: 'Please log in again to continue using the application.',

    invalidTokenHeadline: 'Invalid token',
    invalidTokenDescription: 'We are sorry! Unfortunately, the token is invalid. Please try again later or contact us if the issue persists.',

    loadingErrorDescription: 'Please try again later or contact us if the issue persists.',

    genericErrorHeadline: 'An error occurred',

    // Navigation
    changeLanguage: 'Change language',
    logout: 'Logout',


    // Chat
    newChatTitle: 'New chat',
    chatWelcome: 'How can I assist you?',
    inputPlaceholder: 'Type a message...',
    userInstructions: 'User Instructions',
    disclaimerHeadline: 'Chat Guidelines',

    termsApiError: 'Unable to display terms at the moment',
    disclaimerConfirmationMarkdown: 'By using our Danfoss AI chatbot, you agree to have read and understood this User Instruction and the [Danfoss data privacy policy](https://www.danfoss.com/en/terms/privacy/)',
    disclaimerButton: 'Got it',
    disclaimerNote: 'Note: Answers made by the Danfoss AI Chatbot are computer-generated and may be inaccurate, incomplete, or outdated, and does not reflect the opinion of Danfoss. Use with care.',
    productivityHeadline: 'Productivity gain',
    productivityDescription: 'Submit productivity gain (minutes)',
    productivityPlaceholder: 'Enter minutes',

    // Chat - Context sources
    sources: 'Sources',
    miscSources: 'Misc.',
    goToSource: 'Go to source',
    viewMore: 'View more',
    viewLess: 'View less',

    // Chat Enquiry
    enquiryHeadline: 'Enquiry analysis',
    enquiryQuestion: 'Question',
    enquiryCategory: 'Category',
    enquiryKeywords: 'Keywords',

    // Chat - Actions
    comment: 'Comment',
    commentHeadline: 'Submit message feedback',
    commentDescription: 'The feedback are anonymous and will be used to improve the chatbot.',
    likeTitle: 'Like response',
    dislikeTitle: 'Dislike response',
    copyTitle: 'Copy response',
    messageInfoTitle: 'Information about response',
    historyErrorHeadline: 'Error',
    historyErrorOccurredWhileLoading: 'An error occurred while loading the conversation',


    // Sidebar
    newChat: 'Start new chat',
    clearHistoryHeadline: 'Delete all chats?',
    clearHistoryDescription: 'By clearing your chat history, you will not be able to see previous conversations. This action cannot be reverted. Are you sure you want to continue?',
    clearHistory: 'Clear history',
    deleteChatHeadline: 'Delete chat?',
    deleteChatDescription: 'By deleting this chat, you will not be able to see previous messages within this chat. This action cannot be reverted. Are you sure you want to continue?',
    deleteIconTitle: 'Delete conversation',


    // Error handling: 
    // - Prompt submit errors
    submitFailed: "Unable to submit message",
    offlineError: "Check your internet connectivity.",
    // - Markdown render error
    markdownRenderError: "An error ocurred while parsing this content",

    // useActions 
    // data - side panel
    noDynamicData: 'No data is available',


    // Dates
    today: 'Today',
    yesterday: 'Yesterday',
    within7Days: 'Previous 7 days',
    within30Days: 'Previous 30 days',
    jan: 'January',
    feb: 'February',
    mar: 'March',
    apr: 'April',
    may: 'May',
    jun: 'June',
    jul: 'July',
    aug: 'August',
    sep: 'September',
    oct: 'October',
    nov: 'November',
    dec: 'December',


    // Advanced/Settings
    advancedSettings: 'Advanced settings',
    temperature: 'Temperature',
    model: 'Model',
    modelIconTitle: 'Information about available models',
    modelDescriptions: 'Model descriptions',
    temperatureMin: 'Precise',
    temperatureMid: 'Balanced',
    temperatureMax: 'Creative',

    // Personas
    beta: 'Beta',
    persona: 'Assistant',
    choosePersona: 'Choose an assistant',
    choosePersonaIconTitle: 'Information about available assistants',
    choosePersonaDescriptions: 'Choose the assistant with specific knowledge to assist you with your chat. The assistant will provide you with different responses based on your choice of scope for your chat.',
    invalidPersona: 'The selected assistant/model is no longer available',

    advancedTitle: 'Advanced Chat Assistant',
    advancedDescription: 'Customize LLM options to fit your use case (the available LLMs are generic/non-Danfoss specific)',

    // TBD
    tbd: (prefix = '') => `${prefix}${prefix ? ' feature' : 'Feature'} is in progress.`,


    // Table search
    tableSearchHeadline: 'Search within the table',
    tableSearchNotResultsFound: 'No results found for the search criteria',

    // Sidebar actions
    viewDetails: 'View details',
    productDetails: 'Product details',
    noResultsFound: (id: string) => `No results found for: ${id}`,
    embeddedToolHeadline: 'Embedded tool',
    chainViewerHeadline: 'Insights',
    pdfViewer: 'PDF viewer',
    pdfCannotBeDisplayed: 'PDF cannot be displayed',

    viewDepthHeadline: 'Maximum view depth reached',
    viewDepthDescription: 'You have reached the maximum view depth. Please try close some of the previous side panels.',

    // Insights
    input: 'Input',
    output: 'Output',
    artifacts: 'Artifacts',
    showMoreDetails: 'See more details',
    displayTechnicalDetails: 'Technical details',

    // ChatWindowHeader 
    reportGainIconTitle: 'Report the time gain',
    createNewChatIconTitle: 'Create a new conversation',
    editChatNameIconTitle: 'Edit conversation name',
    menuIconTitle: 'Side menu',
    closeSidebarIconTitle: 'Close side panel',
    openInNewTabTitle: 'Open in the new tab',
    navigateToInformationViewIconTitle: 'Navigate to the information view',

    // ChatInput
    submitTitle: 'Submit an inquiry',
    stopSubmitTitle: 'Stop inquiry generation',

    // ChatMessage
    enquiryAnalysisTitle: 'Enquiry analysis',

    // Information page
    informationPageCta: 'Information',
    viewReleaseNotes: 'View release notes',

    // Image 
    imageEnlargeCta: 'Enlarge image',
    imageEnlargeAlt: 'Enlarged view',

    // Anchor to headline
    anchorHeadlineTitle: 'Copy the link to the resource'
};

export const inputValidationLabels = {
    messageTooLong: 'Character limit reached',
};
export const chatMessageErrorLabels: ErrorLabels<ChatMessageErrorCode> = {
    [ChatMessageErrorCode.Unknown]: {
        h: "Something went wrong",
        d: "Please try again later or contact us if the issue persists."
    },
    [ChatMessageErrorCode.Cancelled]: {
        h: "Response generation cancelled",
        d: "The response generation was cancelled. Please try again if this was a mistake."
    },
    [ChatMessageErrorCode.ContentFilterViolation]: {
        h: "Content violation",
        d: "The message was flagged for content violation. If you believe this is a mistake, please use the feedback functionality to report it."
    },
    [ChatMessageErrorCode.TokenLimitExceeded]: {
        h: "Token limit is reached",
        d: "This model's maximum token limit is reached for the session. Please use 'Start new chat' to continue."
    }
};


export const countries = {
    VU: "Vanuatu",
    EC: "Ecuador",
    VN: "Vietnam",
    VI: "Amer.virgin island",
    DZ: "Algeria",
    VG: "Brit.Virgin Island",
    DM: "Dominica",
    VE: "Venezuela",
    DO: "Dominican Republic",
    VC: "St. Vincent",
    VA: "Vatican city",
    DE: "Germany",
    UZ: "Uzbekistan",
    UY: "Uruguay",
    DK: "Denmark",
    DJ: "Djibouti",
    US: "USA",
    UM: "US Minor Outlying Islands",
    EU: "European Union",
    UG: "Uganda",
    UA: "Ukraine",
    ET: "Ethiopia",
    ES: "Spain",
    ER: "Eritrea",
    EG: "Egypt",
    TZ: "Tanzania",
    EE: "Estonia",
    TT: "Trinidad and Tobago",
    TW: "Taiwan R.O.C.",
    TV: "Tuvalu",
    GD: "Grenada",
    GE: "Georgia",
    GF: "French Guinea",
    GA: "Gabon",
    GB: "Great Britain",
    XK: "Kosovo",
    N: "All countries",
    FR: "France",
    FO: "Faroe Islands",
    FK: "Falkland islands",
    FJ: "Fiji",
    FM: "Micronesia",
    FI: "Finland",
    WS: "Western Samoa",
    GY: "Guyana",
    GW: "Guinea-Bissau",
    GU: "Guam",
    GT: "Guatemala",
    GR: "Greece",
    GQ: "Equatorial Guinea",
    WF: "Wallis,futuna",
    GP: "Guadeloupe",
    GN: "Guinea",
    GM: "Gambia",
    GL: "Greenland",
    GI: "Gibraltar",
    GH: "Ghana",
    RE: "Reunion",
    RO: "Romania",
    AT: "Austria",
    AS: "Samoa, American",
    AR: "Argentina",
    AQ: "Antarctica",
    QA: "Qatar",
    AW: "Aruba",
    AU: "Australia",
    AZ: "Azerbaijan",
    BA: "Bosnia-Herzegovina",
    PT: "Portugal",
    AD: "Andorra",
    PW: "Palau",
    AG: "Antigua/Barbuda",
    PR: "Puerto Rico",
    AE: "United Arab Emirates",
    AF: "Afghanistan",
    AL: "Albania",
    STL: "stateless",
    AI: "Anguilla",
    AO: "Angola",
    PY: "Paraguay",
    AM: "Armenia",
    AN: "Dutch Antilles",
    BW: "Botswana",
    TG: "Togo",
    BY: "Belarus",
    TD: "Chad",
    BS: "Bahamas",
    BR: "Brazil",
    TJ: "Tadzhikistan",
    BT: "Bhutan",
    TH: "Thailand",
    TO: "Tonga",
    TN: "Tunesia",
    TM: "Turkmenistan",
    CA: "Canada",
    BZ: "Belize",
    TR: "Turkey",
    BF: "Burkina Faso",
    BG: "Bulgaria",
    SV: "El Salvador",
    BH: "Bahrain",
    BI: "Burundi",
    ST: "Sao Tome and Principe",
    BB: "Barbados",
    SY: "Syria",
    SZ: "Swaziland",
    BD: "Bangladesh",
    BE: "Belgium",
    BN: "Brunei Daruessalam",
    BO: "Bolivia",
    BJ: "Benin",
    TC: "Turksh Caicosin",
    BM: "Bermuda",
    SD: "Sudan",
    CZ: "Czech Republic",
    SC: "Seychelles",
    CY: "Cyprus",
    CX: "Christmas Island",
    SE: "Sweden",
    SH: "St. Helena",
    CV: "Cape Verdian",
    SG: "Singapore",
    CU: "Cuba",
    SI: "Slovenia",
    SL: "Sierra Leone",
    SK: "Slovakia",
    SN: "Senegal",
    SM: "San marino",
    SO: "Somalia",
    SR: "Suriname",
    CI: "Ivory coast",
    RS: "Serbia",
    CG: "Congo",
    RU: "Russian Federation",
    CH: "Switzerland",
    RW: "Rwanda",
    CF: "Central African Republic",
    CC: "Cocos Islands",
    CD: "Democratic Republic of Congo",
    CR: "Costa Rica",
    CO: "Colombia",
    CM: "Cameroon",
    CN: "China",
    SA: "Saudi Arabia",
    CK: "Cook Islands",
    SB: "Solomon Islands",
    CL: "Chile",
    LV: "Latvia",
    LU: "Luxembourg",
    LT: "Lithuania",
    LY: "Libya",
    LS: "Lesotho",
    LR: "Liberia",
    MG: "Madagascar",
    MH: "Marshall Islands",
    ME: "Montenegro",
    MK: "Macedonia",
    ML: "Mali",
    MC: "Monaco",
    MD: "Moldavia",
    MA: "Morocco",
    MV: "Maldives",
    MU: "Mauritius",
    MX: "Mexico",
    MW: "Malawi",
    MZ: "Mozambique",
    MY: "Malaysia",
    MN: "Mongolia",
    MM: "Myanmar",
    MP: "Northern Mariana Islands",
    MO: "Macau",
    MR: "Mauretania",
    MQ: "Martinique",
    MT: "Malta",
    MS: "Montserrat",
    NF: "Norfolk Island",
    NG: "Nigeria",
    NI: "Nicaragua",
    NL: "Netherlands",
    NA: "Namibia",
    NC: "New Caledonia",
    NE: "Niger",
    XDK: "Denmark Exports",
    NZ: "New Zealand",
    NU: "Niue Islands",
    NR: "Nauru",
    NP: "Nepal",
    NO: "Norway",
    OM: "Oman",
    PL: "Poland",
    PM: "St. Pierre and Miquelon",
    PH: "Philippines",
    PK: "Pakistan",
    PE: "Peru",
    PF: "French Polynesia",
    PG: "Papua New Guinea",
    PA: "Panama",
    HK: "Hong Kong",
    ZA: "South Africa",
    HN: "Honduras",
    HR: "Croatia",
    HT: "Haiti",
    HU: "Hungary",
    ZM: "Zambia",
    ID: "Indonesia",
    ZW: "Zimbabwe",
    ZR: "Zaire",
    IE: "Ireland",
    IL: "Israel",
    IM: "Isle of Man",
    IN: "India",
    IQ: "Iraq",
    IR: "Iran",
    YE: "Yemen",
    IS: "Iceland",
    IT: "Italy",
    YT: "Mayotte",
    JP: "Japan",
    JO: "Jordan",
    JM: "Jamaica",
    KI: "Kiribati",
    KH: "Cambodia",
    KG: "Kirghistan",
    KE: "Kenya",
    KP: "North Korea",
    KR: "Korea",
    KM: "Comorin",
    KN: "St. Kitts and Nevis",
    KW: "Kuwait",
    KY: "Cayman Islands",
    KZ: "Kazakhstan",
    LA: "Laos",
    LC: "St. Lucia",
    LB: "Lebanon",
    LI: "Liechtenstein",
    LK: "Sri Lanka"
}
export const languages = {
    hr: "Croatian",
    zh: "Chinese",
    ro: "Romanian",
    vi: "Vietnamese",
    tr: "Turkish",
    no: "Norwegian",
    hu: "Hungarian",
    lv: "Latvian",
    hi: "Hindi",
    lt: "Lithuanian",
    ga: "Irish",
    th: "Thai",
    de: "German",
    id: "Indonesian",
    fi: "Finnish",
    ta: "Tamil",
    bg: "Bulgarian",
    fr: "French",
    sv: "Swedish",
    mk: "Macedonian",
    sl: "Slovenian",
    sk: "Slovak",
    uk: "Ukrainian",
    is: "Icelandic",
    da: "Danish",
    it: "Italian",
    sr: "Serbian",
    ko: "Korean",
    fa: "Persian",
    ar: "Arabic",
    he: "Hebrew",
    kk: "Kazakh",
    cs: "Czech",
    el: "Greek",
    pt: "Portuguese",
    pl: "Polish",
    en: "English",
    ru: "Russian",
    et: "Estonian",
    es: "Spanish",
    ja: "Japanese",
    nl: "Dutch"
}

export default labels;