import React from 'react';
import PageLayout from '../../layouts/page-layout/PageLayout';
import Alert from '../../ui/alert/Alert';
import Hero from '../../ui/hero/Hero';
import useTokenStateErrorContent from '../../hooks/useTokenStateErrorContent';

type Props = {};

const TokenStateErrorView: React.FC<Props> = () => {
    const { headline, description, button } = useTokenStateErrorContent();

    return (
        <PageLayout>
            <Hero
                mainContent={<>
                    <h1>{headline}</h1>
                    {description && <Alert type='error' headline={description} />}
                </>}
                sideContent={button}
            />
        </PageLayout>
    );
};

export default TokenStateErrorView;;