import React, { type PropsWithChildren } from 'react';
import MockAuthContextProvider, { AuthState } from '../auth/MockMsalAuthContextProvider';
import MockChatServiceProvider from '../chat/MockChatServiceContext';
import MockAppInfoServiceProvider from '../app-info/MockAppInfoServiceContext';

const MockContextBootstrap: React.FC<PropsWithChildren<{ authState?: Partial<AuthState>; }>> = ({ children, authState: authStateOverride }) => {
    return (
        <MockAuthContextProvider state={authStateOverride}>
            <MockAppInfoServiceProvider>
                <MockChatServiceProvider>
                    {children}
                </MockChatServiceProvider>
            </MockAppInfoServiceProvider>
        </MockAuthContextProvider>
    );
};

export default MockContextBootstrap;