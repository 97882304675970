export default class EventHandler<EventArgs> {
    private handlers: { (model: EventArgs): void; }[] = [];

    get handlerCount() {
        return this.handlers.length;
    }

    on(handler: { (model: EventArgs): void; }) {
        if (this.handlers.indexOf(handler) === -1) {
            this.handlers.push(handler);
        }
    }
    off(handler: { (model: EventArgs): void; }) {
        const handlerIndex = this.handlers.indexOf(handler);
        if (handlerIndex === -1)
            return;
        this.handlers.splice(handlerIndex, 1);
    }

    offAll() {
        this.handlers.map(x => this.off(x));
    }

    invoke(args: EventArgs) {
        this.handlers.forEach((handler) => {
            try {
                handler(args);
            }
            catch (e) { console.error(e); }
        });
    }
}
export type EventHandlerModel<TEventHandler> = TEventHandler extends EventHandler<infer TModel> ? TModel : never;
