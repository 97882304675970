import Async from "./variation/Async";
import Base from "./variation/Base";
import Svg from "./variation/Svg";
import { IconComponent, IconStyles } from "./Icon.types";
import { Icons } from "./icons/material";

const Icon: IconComponent = () => { };
Icon.Base = Base;
Icon.Async = Async;
Icon.Svg = Svg;

export { Icon };
// Relevant for additions via  plain JS
export const getInlineIcon = ({ iconName, style = IconStyles.outlined }: { iconName: Icons; style?: IconStyles; }) => !iconName ? '' : `<span class="${style}">${iconName}</span>`;