import React from 'react';
import { DfLoader } from '@danfoss/mosaic-react';
import { getClassNames } from '../../helpers/classHelpers';
import style from './Loader.module.scss';

type Props = {
    /** Defaults to `true` */
    isVisible?: boolean;
    isInline?: boolean;
    isGlobal?: boolean;
    isRelative?: boolean;
};

const Loader: React.FC<Props> = ({ isVisible = true, isGlobal, isRelative, isInline = true }) => <DfLoader className={getClassNames([style.loader, isRelative && style.relative, isInline && style.inline])} isVisible={isVisible} isGlobal={isGlobal} />;

export default Loader;