import { useCallback, useRef } from 'react';

const usePersistentAutoSubmitState = () => {
    const autoSubmitForRef = useRef<string>();

    const getAutoSubmit = useCallback((sessionId: string) => sessionId === autoSubmitForRef?.current, []);

    const handleChange = useCallback((sessionId: string) => autoSubmitForRef.current = sessionId, []);

    const handleRemove = useCallback(() => autoSubmitForRef.current = undefined, []);

    return {
        setAutoSubmit: handleChange,
        removeAutoSubmit: handleRemove,
        getAutoSubmit
    };
};

export default usePersistentAutoSubmitState;
