import { defaultPersonaIcon, personaKeyIconMap } from '../constants/consts';

import { User } from '../models/types';
import { SvgIcons } from '../ui/icon/Icon.types';

export const getInitials = (name: string) => {
    let initials;
    const nameSplit = name.split(" ");
    const nameLength = nameSplit.length;
    if (nameLength > 1) {
        initials =
            nameSplit[0].substring(0, 1) +
            nameSplit[nameLength - 1].substring(0, 1);
    } else if (nameLength === 1) {
        initials = nameSplit[0].substring(0, 1);
    } else return "?";

    return initials.toUpperCase();
};

export const getPersonaIcon = (personaKey?: string) => {
    return (personaKeyIconMap[personaKey || ''] || defaultPersonaIcon) as SvgIcons;
};

export const getAvatarHuman = (user?: User) => user?.avatar || '';

export const createImageFromInitials = async (size: number, name: string, color: string = "#fff") => {
    if (name == null) return;

    name = getInitials(name);

    const canvas = document.createElement('canvas');
    const context = canvas.getContext('2d')!;
    canvas.width = canvas.height = size;

    context.fillStyle = "#e2000f";
    context.fillRect(0, 0, size, size);

    context.fillStyle = `${color}50`;
    context.fillRect(0, 0, size, size);

    context.fillStyle = color;
    context.textBaseline = 'middle';
    context.textAlign = 'center';
    const fs = size / 2;
    context.font = `${fs}px 'myriad-pro', "Source Sans Pro", sans-serif, system-ui`;

    // Add "magic number" offsets to center the text
    const offsetX = size * 0.005;
    const offsetY = size * 0.05;

    context.fillText(name, fs + offsetX, fs + offsetY);

    return canvas.toDataURL();
};