import React, { useCallback, type ReactNode, useState, useMemo } from 'react';
import Modal, { type ModalProps } from '../modal/Modal';
import { Icon } from '../icon/Icon';
import { DfTooltip } from '@danfoss/mosaic-react';
import { type TooltipPlacementType } from '@danfoss/mosaic-core';
import { getClassNames } from '../../helpers/classHelpers';
import { BaseUIType } from '../../models/UITypes';
import { Icons } from '../icon/icons/material';
import style from './InputLabel.module.scss';

type Props = BaseUIType & {
    label: string;


    tooltip?: string | ReactNode;
    tooltipPlacement?: TooltipPlacementType;

    modal?: Omit<ModalProps, 'isOpen' | 'onClose' | 'closeOnEscape'>;
    onCloseModal?: () => void;

    iconTitle?: string;
    iconTestId?: string;
    className?: string;
    isDisabled?: boolean;
    isRequired?: boolean;
};

const InputLabel: React.FC<Props> = ({ testId, iconTestId, label, tooltip, tooltipPlacement, modal, iconTitle = '', onCloseModal, className, isDisabled, isRequired }) => {
    const [isOpen, setIsOpen] = useState(false);

    const onModalOpenHandler = useCallback(() => !isDisabled && setIsOpen(true), [isDisabled]);
    const onModalCloseHandler = useCallback(() => {
        setIsOpen(false);
        onCloseModal?.();
    }, [onCloseModal]);

    const props = useMemo(() => ({
        'data-testid': iconTestId,
        'aria-disabled': isDisabled,
        className: style['icon-container']
    }), [iconTestId, isDisabled]);

    const icon = useMemo(() => <Icon.Base isClickable size={18} title={iconTitle} iconName={Icons.info} />, [iconTitle]);

    return (<div className={getClassNames([className, style.label])}>
        <label aria-disabled={isDisabled}>{label} {isRequired && <span>*</span>}</label>

        {tooltip && <DfTooltip data-testid={testId} disabled={isDisabled} placement={tooltipPlacement}>
            <div {...props} slot='tooltip-reference'>{icon}</div>
            <span slot='tooltip-content'>{tooltip}</span>
        </DfTooltip>}

        {modal && <>
            <div {...props} onClick={onModalOpenHandler}>{icon}</div>
            {isOpen && <Modal testId={testId} isOpen={isOpen} {...modal} onClose={onModalCloseHandler} closeOnEscape />}
        </>}
    </div>);
};

export default InputLabel;