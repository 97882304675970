export type FilteredEnum<T extends ReadonlyArray<unknown>> = T extends ReadonlyArray<infer ElementType> ? ElementType : never;



export const isFunction = (v: any): v is Function => typeof v === 'function';

export const isString = (v: any): v is string => typeof v === 'string';

/**Check if type is number, to check if is valid number, please use `isValidNumber` from the numberHelper */
export const isNumber = (v: any): v is number => typeof v === 'number';

export const isArray = <T>(v: T[] | any): v is Array<T> => Array.isArray(v);
export const isLiteralObject = <T extends Object>(v: T | any): v is T => (!!v) && (v.constructor === Object);

export const isRegExp = (v: any): v is RegExp => v instanceof RegExp;

// In a utility library:
export function assertIsNode(e: EventTarget | null): asserts e is Node {
    if (!e || !("nodeType" in e)) {
        throw new Error(`Node expected`);
    }
}