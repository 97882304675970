import EnvConfigHelper from '../helpers/envConfigHelper';
import { HTTPDataService } from './HTTPDataService';
import type { InfoResponse, TermsResponse } from '../models/types';
import type { IAppInfoService } from './IAppInfoService';
import { defaultCulture } from '../constants/consts';

class AppInfoService extends HTTPDataService implements IAppInfoService {
    baseURL = EnvConfigHelper.get('api-base-url', '');

    constructor(public getAccessToken: () => Promise<string | undefined>) {
        super();
    }

    getInfo = async () => {
        return new Promise<InfoResponse>(async (resolve, reject) => {
            try {
                const result = await this.get<InfoResponse>('api/info');

                resolve(result);
            } catch (e) {
                console.error(e);
                reject(e);
            }
        });
    };

    getTerms = async () => {
        try {
            const uri = 'api/info/terms';
            const query = {
                culture: defaultCulture
            };
            const termsPromise = this.get<TermsResponse>(uri, query);
            return await termsPromise;
        } catch (e) {
            console.error(e);
            return Promise.reject(e);
        }
    };
}

export default AppInfoService;