/*
    We generally prefer to use our wrapped React components instead of the
    string-based HTML elements. However, there are some cases where we need to
    get the HTML string of a component.

    When parsing markdown, we want to display potential errors in a df-alert
    and since we can't render React components in markdown, we need to get the
    HTML string.
*/


const dictToHTMLAttributes = (dict: { [key: string]: string | undefined; }) => {
    return Object.entries(dict).map(([key, value]) => {
        if (!value) return '';
        return `${key}="${value}"`;
    }).join(' ');
};


export function renderDfAlert(heading?: string, description?: string, className?: string) {
    const attributes = dictToHTMLAttributes({
        heading,
        description,
        class: className,
        visible: 'true',
        type: 'error',
    });
    const alert = `<df-alert ${attributes}> </df-alert>`;
    return alert;
}