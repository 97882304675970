const { cookie } = require('../constants/nonce');

type InitializeEventConfig = {
    onStatisticalConsentGiven?: () => void;
    onStatisticalConsentRemoved?: () => void;

    onFunctionalConsentGiven?: () => void;
    onFunctionalConsentRemoved?: () => void;

    onMarketingConsentGiven?: () => void;
    onMarketingConsentRemoved?: () => void;
};

export class BrowserCookieInformationService {
    static isInitialized: boolean = false;
    static supportedLanguages = ["ar", "lt", "bg", "lv", "ca", "ms", "cs", "nb", "da", "nl", "de", "pl", "el", "pt", "en", "ro", "es", "ru", "et", "si", "fi", "sk", "fr", "sl", "ga", "sq", "he", "sr", "hi", "sv", "hr", "ta", "id", "th", "is", "tr", "it", "uk", "ja", "vi", "ko", "zh"];
    static didHidePage3 = false;
    static readonly maxHidingAttempts = 20;
    static attempts = 0;

    static async getSupportedLanguages(getCulture: () => Promise<string>) {
        let language = (await getCulture()).split("-")[0];
        if (this.supportedLanguages.indexOf(language) === -1) language = "en";
        return language;
    }

    static disabled = () => window.location.host.toLowerCase().startsWith("localhost");

    static async initialize(getCulture: () => Promise<string>, { onStatisticalConsentGiven, onStatisticalConsentRemoved, onFunctionalConsentGiven, onFunctionalConsentRemoved, onMarketingConsentGiven, onMarketingConsentRemoved }: InitializeEventConfig = {}) {
        if (this.isInitialized) return;
        this.isInitialized = true;
        if (this.disabled()) {
            console.warn("Skipped CookieInformation consent. Service is disabled for localhost.");
            onStatisticalConsentGiven?.();
            onFunctionalConsentGiven?.();
            onMarketingConsentGiven?.();
            return;
        }
        window.addEventListener("CookieInformationConsentGiven", () => {
            const consentCheck = window.CookieInformation?.getConsentGivenFor;

            if (consentCheck?.('cookie_cat_functional')) onFunctionalConsentGiven?.();
            else onFunctionalConsentRemoved?.();

            if (consentCheck?.('cookie_cat_statistic')) onStatisticalConsentGiven?.();
            else onStatisticalConsentRemoved?.();

            if (consentCheck?.('cookie_cat_marketing')) onMarketingConsentGiven?.();
            else onMarketingConsentRemoved?.();
        }, false);
        const cookieInformationScript = document.createElement("script");

        cookieInformationScript.id = "CookieConsent";
        cookieInformationScript.setAttribute("src", "https://policy.app.cookieinformation.com/uc.js");
        cookieInformationScript.setAttribute("data-culture", await this.getSupportedLanguages(getCulture));
        // Disabling integrity check for now, as the script can be updated by the provider, causing the hash to change.
        // cookieInformationScript.setAttribute("integrity", "sha512-FO/n0pIKQQj4F6D6ZZjHOV7uD3UzHX3+2KWD9sVGO8rN34lgJIhN/bVCe4Zn5sqbpLRTMSruKbP0EXMBG1e0zg==");
        cookieInformationScript.setAttribute("nonce", cookie);
        cookieInformationScript.setAttribute("crossorigin", "anonymous");

        cookieInformationScript.onload = this.attemptHidePage3;

        document.head.appendChild(cookieInformationScript);
    }

    static attemptHidePage3 = () => {
        if (this.didHidePage3) return;
        const el = document.getElementById('coiPage-3');

        if (!el) {
            if (this.attempts++ === this.maxHidingAttempts) return;
            setTimeout(this.attemptHidePage3, 50);
        } else if (el && el.getAttribute('style') && !el.style.display) {
            this.didHidePage3 = true;
            el.style.display = 'none';
        }
    };



}
//  –––––––––––– Below code is legacy. Used for attempts to have strict CSP and SRI strategy, that turned out to be not feasible  ––––––––––––  //

// Since we have CSP policy, we need to move the inline event handlers to the script (onclick -> addEventListener)
// https://support.cookieinformation.com/en/articles/8282189-csp-implementation
// https://stackoverflow.com/a/65196581
// cookieInformationScript.onload = this.setupCustomEventListeners;


// type ButtonsConfig = {
//     query: string;
//     customExtraStyles?: CSSProperties;
//     callback: EventListener;
// };

// static buttonConfigs: ButtonsConfig[] = [
//     { query: '#Coi-Renew', callback: () => window.CookieConsent?.renew?.() }, // Cookie icon - opens modal 
//     { query: '#declineButton', callback: () => window.CookieInformation?.declineAllCategories?.() }, // Decline all
//     { query: '#updateButton', callback: () => window.CookieInformation?.submitConsent?.() }, // Save settings
//     { query: '#show_details', callback: () => window.toggleDetails?.('show_details') }, // Show details
//     { query: '#hide_details', callback: () => window.toggleDetails?.('hide_details') }, // Hide details
//     { query: '[onclick*="submitAllCategories"]', callback: () => window.CookieInformation?.submitAllCategories?.() }, // Accept all button
//     { query: '[href*="CookieConsent.renew"]', callback: () => window.CookieConsent?.renew?.(), customExtraStyles: { color: 'rgb(0, 0, 238)', textDecoration: 'underline' } }, // On "Details page" - Changing your consent link
//     {
//         // This is "Read more about cookies", that will change the page to the details page
//         query: '[href*="TogglePage"]',
//         callback: (e: Event) => {
//             const el = e.currentTarget as HTMLAnchorElement;
//             if (!el) return console.warn('Could not find element in callback for button: TogglePage');
//             window.TogglePage?.(el, 'coiPage-3');
//         }
//     },
//     {
//         // This is the "Settings" button from the details page, that will return the user to the cookie sessions page.
//         query: '[onclick*="TogglePage"]',
//         callback: (e: Event) => {
//             const el = e.currentTarget as HTMLButtonElement;
//             if (!el) return console.warn('Could not find element in callback for button: TogglePage');
//             window.TogglePage?.(el, 'coiPage-1');
//         }
//     },
//     {
//         // This is in the details accordion. It will open another accordion with details about "Necessary" cookies.
//         query: '[aria-controls="description-container-cookie_cat_necessary"]',
//         callback: (e: Event) => {
//             const el = e.currentTarget as HTMLButtonElement;
//             if (!el) return console.warn('Could not find element in callback for button: description-container-cookie_cat_necessary');
//             window.toggleCookieDetails(el, 'description-container-cookie_cat_necessary');
//         }
//     },
//     {
//         // This is in the details accordion. It will open another accordion with details about "Statistical" cookies.
//         query: '[aria-controls="description-container-cookie_cat_statistical"]',
//         callback: (e: Event) => {
//             const el = e.currentTarget as HTMLButtonElement;
//             if (!el) return console.warn('Could not find element in callback for button: description-container-cookie_cat_statistical');
//             window.toggleCookieDetails(el, 'description-container-cookie_cat_statistical');
//         }
//     },
//     {
//         // This is in the details accordion. It will open another accordion with details about "Marketing" cookies.
//         query: '[aria-controls="description-container-cookie_cat_marketing"]',
//         callback: (e: Event) => {
//             const el = e.currentTarget as HTMLButtonElement;
//             if (!el) return console.warn('Could not find element in callback for button: description-container-cookie_cat_marketing');
//             window.toggleCookieDetails(el, 'description-container-cookie_cat_marketing');
//         }
//     },
//     {
//         // This is in the details accordion. It will open another accordion with details about "Functional" cookies.
//         query: '[aria-controls="description-container-cookie_cat_functional"]',
//         callback: (e: Event) => {
//             const el = e.currentTarget as HTMLButtonElement;
//             if (!el) return console.warn('Could not find element in callback for button: description-container-cookie_cat_functional');
//             window.toggleCookieDetails(el, 'description-container-cookie_cat_functional');
//         }
//     },
//     {
//         // This is in the details accordion. It will open another accordion with details about "Unclassified" cookies.
//         query: '[aria-controls="description-container-cookie_cat_unclassified"]',
//         callback: (e: Event) => {
//             const el = e.currentTarget as HTMLButtonElement;
//             if (!el) return console.warn('Could not find element in callback for button: description-container-cookie_cat_unclassified');
//             window.toggleCookieDetails(el, 'description-container-cookie_cat_unclassified');
//         }
//     },
// ];

// static hidePage3 = () => {
//     if (this.didHidePage3) return;

//     const el = document.getElementById('coiPage-3');
//     if (!el) return;

//     this.didHidePage3 = true;

//     // remove display style
//     el.style.display = 'block';
//     // force repaint
//     el.offsetHeight; // eslint-disable-line
//     // restore display style
//     el.style.display = 'none';
// };


// static styleChanges = () => {
//     this.hidePage3();
// };

// static setupCustomEventListeners = () => {
//     setTimeout(this.styleChanges, 500); // attempt to quickly handle style changes - only really important for first time visitors
//     setTimeout(() => {
//         this.buttonConfigs.forEach(this.handleButtonConfigs);
//         this.styleChanges();
//     }, 2000);
// };

// static handleButtonConfigs = ({ query, callback, customExtraStyles }: ButtonsConfig) => {
//     const elements = Array.from<HTMLButtonElement | HTMLAnchorElement>(document.querySelectorAll(query));

//     if (!elements.length) return console.warn(`Could not find any elements for button: ${query}`);

//     const handler = this.createElementHandler({ query, callback, customExtraStyles });

//     elements.forEach(handler);
// };

// static createElementHandler = ({ query, callback, customExtraStyles }: ButtonsConfig) => {
//     return function (element: HTMLButtonElement | HTMLAnchorElement) {
//         if (!element) return console.warn('Could not find element', query);

//         if (element.hasAttribute('onclick')) {
//             element.removeAttribute('onclick');
//         }

//         if (element.hasAttribute('href')) {
//             element.removeAttribute('href');
//             element.style.cursor = 'pointer';
//         }

//         // apply extra styles if any
//         if (customExtraStyles) Object.assign(element.style, customExtraStyles);

//         element.addEventListener('click', callback);
//     };
// };
