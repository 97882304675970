import React, { useMemo } from 'react';
import useLabels from '../../hooks/useLabels';
import { Icon } from '../../ui/icon/Icon';
import { defaultPersonaIcon } from '../../constants/consts';
// import { gptAvatarRed } from '../../constants/consts';
import style from './ChatWelcome.module.scss';

type Props = {};

const ChatWelcome: React.FC<Props> = () => {
    const labels = useLabels();
    const l = useMemo(() => {
        return {
            msg: labels.chatWelcome,
        };
    }, [labels.chatWelcome]);

    return (
        <div className={style['chat-welcome']}>
            <Icon.Svg borderSize={2} size={54} title={''} iconName={defaultPersonaIcon} className={style.avatar} fill='red' borderColor='red' />
            <span>{l.msg}</span>
        </div>
    );
};

export default ChatWelcome;