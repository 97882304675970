import useLabels from '../../../../hooks/useLabels';
import { TABLE_MENU_CLASS_NAME } from '../../helpers';
import { generateUniqueId } from '@danfoss/mosaic-core';
import { useEffect } from 'react';
import { fuzzySearchBy } from '../../../../helpers/listsHelpers';
import styles from './useTableSearch.module.scss';

export const TABLE_WRAPPER_CLASS_NAME = 'table-wrapper';
const listenerMap = new WeakMap();
const getElements = (selectors: string, parent?: Element) => (parent || document).querySelectorAll(selectors);

type LabelType = ReturnType<typeof useLabels>;

const processMarkup = (labels: LabelType, parent: Element) => {
    if (listenerMap.has(parent)) return;
    parent.classList.add(styles['table-wrapper']);

    const labelText = labels.tableSearchHeadline;
    const uniqueId = generateUniqueId();
    const wrapperElement = createSearchWrapperElement();
    const { labelWrapperElement } = createLabelElement(uniqueId, labelText);
    const { inputElement, inputWrapperElement, resetInputElement } = createInputElement(uniqueId, labelText);
    const { minisculeMenuIconElement, toggleSearchIcon } = minusculeMenuElement();
    const noResultMessageElement = createNoResultsElement(labels);

    const processTable = (value: string, table: Element) => {
        const rows = Array.from(table.querySelectorAll('tr')).slice(1);  // Exclude the header row
        const fuzzySearched = fuzzySearchBy(rows, value, ['innerText']);

        let matched = 0;
        let wasEven = true;
        rows.forEach(row => {
            const isMatch = fuzzySearched.includes(row);
            row.classList.toggle(styles.hidden, !isMatch);

            if (isMatch) {
                matched++;
                wasEven = !wasEven;
                row.classList.toggle(styles.even, !wasEven);
                row.classList.toggle(styles.odd, wasEven);
            }
        });

        if (!matched) noResultMessageElement.style.display = 'block';
        else noResultMessageElement.style.display = 'none';
    };

    const h = (e: Event) => {
        const value = (e.target as HTMLInputElement)?.value;
        getElements('table', parent).forEach(processTable.bind(this, value));
    };

    inputElement.addEventListener('input', h);
    wrapperElement.appendChild(labelWrapperElement);
    wrapperElement.appendChild(inputWrapperElement);

    minisculeMenuIconElement.addEventListener('click', () => {
        wrapperElement.classList.toggle(styles.expanded);
        if (wrapperElement.classList.contains(styles.expanded)) {
            toggleSearchIcon(true);
            minisculeMenuIconElement.classList.add(styles['miniscule-menu-active']);
            inputElement.focus();
        }
        else {
            resetInputElement(h);
            toggleSearchIcon(false);
            minisculeMenuIconElement.classList.remove(styles['miniscule-menu-active']);
        };
    });

    const table = parent.querySelector(`.${TABLE_MENU_CLASS_NAME}`)!;
    table.insertBefore(minisculeMenuIconElement, table.firstChild);
    table.appendChild(noResultMessageElement);
    parent.insertBefore(wrapperElement, parent.firstChild);
    listenerMap.set(parent, h);
};

const processTables = (callback: (table: Element, index: number) => void) => Object.values(getElements(`.${TABLE_WRAPPER_CLASS_NAME}`) || []).forEach(callback);
const initTableSort = (labels: LabelType) => processTables(processMarkup.bind(this, labels));

export default function useTableSearch({ isActive, dependencies }: { isActive: boolean; dependencies: React.DependencyList; }) {
    const labels = useLabels();
    useEffect(() => {
        if (!isActive) return;
        initTableSort(labels);
        return () => { };
        // eslint-disable-next-line
    }, [labels, isActive, ...(dependencies || [])]);
};


const createNoResultsElement = (labels: LabelType) => {
    const p = document.createElement('span');
    p.innerText = labels.tableSearchNotResultsFound;
    p.className = styles['no-result'];
    return p;
};

const createSearchWrapperElement = () => {
    const div = document.createElement('div');
    div.className = styles['search-wrapper'];

    return div;
};

const createLabelElement = (uniqueId: string, labelText: string) => {
    const labelElement = document.createElement('label');
    labelElement.htmlFor = uniqueId;
    labelElement.textContent = labelText;
    labelElement.className = 'df-field-label';

    const labelWrapperElement = document.createElement('div');
    labelWrapperElement.appendChild(labelElement);

    return { labelElement, labelWrapperElement };
};

const minusculeMenuElement = () => {
    const minisculeMenuIconElement = document.createElement('button');
    minisculeMenuIconElement.ariaLabel = 'Show search input field';
    minisculeMenuIconElement.className = styles['miniscule-menu-icon'];
    const toggleSearchIcon = (search?: boolean) => minisculeMenuIconElement.innerHTML = `<span class="material-icons">${search ? 'search_off' : 'search'}</span>`;
    toggleSearchIcon(false);

    return { minisculeMenuIconElement, toggleSearchIcon };
};

const createInputElement = (uniqueId: string, labelText: string) => {
    const inputElement = document.createElement('input');
    inputElement.placeholder = labelText;
    inputElement.className = 'df-input';
    inputElement.type = 'text';
    inputElement.id = uniqueId;

    const inputWrapperElement = document.createElement('div');
    inputWrapperElement.appendChild(inputElement);
    inputWrapperElement.classList.add(styles['input-container']);

    const resetInputElement = (handler: (e: Event) => void) => {
        inputElement.value = '';
        handler({ target: { value: '' } } as any as Event);
    };

    return { inputElement, inputWrapperElement, resetInputElement };
};