import React, { type PropsWithChildren, createContext, useContext } from 'react';
import { type User } from '../../models/types';
import { notImplemented } from '../../helpers/contextHelpers';

export enum TokenState {
    Valid = 'Valid',
    Invalid = 'Invalid',
    RequiresInteraction = 'RequiresInteraction',
}

export type IAuthService = {
    login: () => void;
    logout: () => void;
    getAccessToken: () => Promise<string | undefined>;
    user?: User;
    tokenState?: TokenState;
    isStartingUp: boolean;
    isLoggingIn: boolean;
    isLoggingOut: boolean;
};

const defaultAuthService: IAuthService = {
    login: notImplemented,
    logout: notImplemented,
    getAccessToken: notImplemented,
    user: undefined,
    tokenState: TokenState.Valid,
    isStartingUp: false,
    isLoggingIn: false,
    isLoggingOut: false
};
export const AuthContext = createContext<IAuthService>(defaultAuthService);

const AuthProvider: React.FC<PropsWithChildren<{ service: IAuthService; }>> = ({ children, service }) => {
    return <AuthContext.Provider value={service}>
        {children}
    </AuthContext.Provider>;
};

export const useAuth = () => useContext(AuthContext);

export default AuthProvider;