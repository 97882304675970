import { ReactNode } from 'react';
// import { GreyImagePlaceholder } from 'mosaic-plus/assets/placeholder';

export const hexColorForPlaceholder = '#e4e8eb';
export const placeholderClass = 'image-placeholder';

type Type = {
    url?: string;
    alt?: string;
    children?: ReactNode;

    /*  UIImage sets img inside picture to 100% width and height
        The picture has the dimension so no need to set it here */
    // placeholderDimensions?: { width?: string | number; height?: string | number; };
};

export const validateImage = ({ url, alt, children }: Type) => {
    if (!url) return <img className="image-placeholder" alt={alt} style={{ backgroundColor: hexColorForPlaceholder, objectFit: 'cover' }} src={''} />;
    return children;
};
