import React, { CSSProperties, useMemo } from 'react';
import { getClassNames } from '../../helpers/classHelpers';
import { Color } from '../../constants/color';
import style from './AvatarIcon.module.scss';

type Props = {
    imageUrl: string;
    className?: string;
    /** Defaults to Danfoss' red color */
    color?: Color;
    /** Defaults to 1(px) */
    borderWidth?: number;
    borderColor?: Color;
};

const AvatarIcon: React.FC<Props> = ({ imageUrl, className, color = 'red', borderColor, borderWidth = 1 }) => {
    const cssVars = useMemo<CSSProperties>(() => {
        return {
            '--avatar-border-width': borderWidth,
        } as CSSProperties;
    }, [borderWidth]);
    return (
        <img
            src={imageUrl}
            style={cssVars}
            className={getClassNames([style['avatar-icon'], 'df-icon', className, style[color], borderColor && style[`border-${borderColor}`]])} alt={''}  /*'Avatar for the user' */
        />
    );
};

export default AvatarIcon;