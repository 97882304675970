import PageLayout from '../../layouts/page-layout/PageLayout';
import Sidebar, { type EnrichedListItem } from '../../elements/sidebar/Sidebar';
import NewChatWindow from '../../elements/new-chat-window/NewChatWindow';
import ChatWindow from '../../elements/chat-window/ChatWindow';
import useStateRef from '../../hooks/useStateRef';
import SidebarHeader from '../../elements/sidebar-header/SidebarHeader';
import Button, { ButtonProps, ButtonThemes } from '../../ui/button/Button';
import SidebarChildren from '../../elements/sidebar-children/SidebarChildren';
import useDisclaimer from '../../hooks/useDisclaimer';
import useLabels from '../../hooks/useLabels';
import useLoadAllChats from '../../elements/hooks/useLoadAllChats';
import { Icon } from '../../ui/icon/Icon';
import { IconTheme } from '../../ui/icon/Icon.types';
import { FC, memo, useCallback, useMemo } from 'react';
import { findDateGroup } from '../../helpers/dateHelpers';
import { AppRoute, getChatItemRoute } from '../../router/Routing';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { SessionResponse } from '../../models/ChatTypes';
import { useChat } from '../../contexts/chat/ChatContext';
import { getPersonaIcon } from '../../helpers/avatarHelpers';
import { getClassNames } from '../../helpers/classHelpers';
import { useMediaSize } from '../../hooks/useMediaSize';
import { MediaSize, sidePanelElementId } from '../../constants/consts';
import { SidebarHistoryBody, SidebarHistoryFooter, useSidebarDeletionModal } from '../../elements/sidebar/sidebar-history/SidebarHistory';
import { LayoutProvider, useLayout } from '../../contexts/layout/LayoutContext';
import { OBJECT_ID_KEY, useActionsListener } from '../../ui/markdown-wrapper/hooks/useActions/useActions';
import { TestIds } from '../../mocks/ids';
import { Icons } from '../../ui/icon/icons/material';
import style from './ChatView.module.scss';

type Props = {
    newPrompt?: boolean;
    newPromptOrigin?: string;
};

export const PERSISTENT_INPUT_KEY = 'input-history';

export type ChatProps = {
    newPromptOrigin?: string;
    currentChat?: SessionResponse;
    chatId: string;
    onOpenSidebar?: () => void;
    onReloadChats?: (force?: boolean) => Promise<void>;
};

const ChatView: FC<Props> = memo((props) => {
    return (
        <LayoutProvider>
            <Content {...props} />
        </LayoutProvider>
    );
});

export default ChatView;

const Content: FC<Props> = memo(({ newPrompt, newPromptOrigin }) => {
    const navigate = useNavigate();
    const { chatId } = useParams();
    const { getAllChats, deleteChat, deleteAllChats: deleteChats } = useChat();

    const { isLeftSidebarVisible, isRightSidebarVisible, rightSidebarContentStack, rightSidebarContentStackLength, showLeftSidebar, hideLeftSidebar, hideRightSidebar, removeRightSidebarContent } = useLayout();

    const [chats, setChats, chatsRef] = useStateRef<SessionResponse[]>();
    useLoadAllChats({ setChats });

    useActionsListener();

    const reloadChats = useCallback(async (force?: boolean) => {
        // only reload if the chatId is not the same as the first chat in the list, to update order of chats based on recent activity
        if (!force && chatId === chatsRef.current?.[0]?.session_id) return Promise.resolve<void>(undefined);
        const updatedChats = await getAllChats();
        setChats(updatedChats);

    }, [chatId, getAllChats, setChats, chatsRef]);

    const handleDeleteChat = useCallback(async (id: string) => {
        const isCurrent = chatId === id;

        try {
            await deleteChat(id);
        }
        catch (e) {
            console.error('Failed to delete chat.', e);
        }
        finally {
            await reloadChats(true);
            if (isCurrent) navigate(AppRoute.chat);
        }
    }, [chatId, deleteChat, navigate, reloadChats]);

    const handleDeleteAllChats = useCallback(async () => {
        if (!chats?.length) return Promise.reject<void[]>() as any;
        try {
            await deleteChats();
        } catch (e) {
            console.error('Failed to delete all chats.', e);
        } finally {
            const updatedChats = await getAllChats();
            setChats(updatedChats);
            hideLeftSidebar();
            navigate(AppRoute.chat);
        }
    }, [chats?.length, hideLeftSidebar, deleteChats, getAllChats, navigate, setChats]);

    const history = useMemo<EnrichedListItem[]>(() => {
        return chats?.map((chat) => {
            return {
                id: chat.session_id,
                title: chat.subject || 'Missing subject',
                date: chat.created,
                icon: <Icon.Svg title='' size={20} className={style.avatar} borderSize={0} fill='black' iconName={getPersonaIcon(chat.persona)} />,
                dateGroup: findDateGroup(chat.updated),
                url: getChatItemRoute(chat.session_id),
            };
        }) ?? [];
    }, [chats]);

    const currentChat = useMemo(() => chats?.find(chat => chat.session_id === chatId), [chatId, chats]);

    const { deleteModalChatId, isDeleteAllModalOpen, setDeleteModalChatId, setIsDeleteAllModalOpen } = useSidebarDeletionModal();

    const props = useMemo(() => ({
        listItems: history,
        deleteModalChatId,
        isDeleteAllModalOpen,
        setDeleteModalChatId,
        setIsDeleteAllModalOpen,
        onDeleteChat: handleDeleteChat,
        onDeleteAllChats: handleDeleteAllChats,
        onCloseSidebar: hideLeftSidebar,
    }), [history, deleteModalChatId, isDeleteAllModalOpen, setDeleteModalChatId, setIsDeleteAllModalOpen, handleDeleteChat, handleDeleteAllChats, hideLeftSidebar]);

    const isMobile = useMediaSize((ms) => ms <= MediaSize.sm);
    const isTablet = useMediaSize((ms) => ms <= MediaSize.lg);

    const onCreateNewChatHandler = useCallback(() => {
        isMobile && hideRightSidebar();
        hideLeftSidebar();
    }, [isMobile, hideLeftSidebar, hideRightSidebar]);


    return <PageLayout testId={TestIds.chatViewContent}>
        <div className={getClassNames([style['chat-view'], isLeftSidebarVisible && style['opened-left'], isRightSidebarVisible && style['opened-right']])}>
            <Sidebar
                isOpen={isLeftSidebarVisible}
                className={style['left-sidebar']}
                onCloseSidebar={hideLeftSidebar}
                headerChildren={<SidebarHeader>
                    <NewChatButton
                        onClick={onCreateNewChatHandler}
                    />
                </SidebarHeader>}
                scrollChildren={<SidebarHistoryBody {...props} />}
            />

            <div className={style.main}>
                <ActiveChatWindow
                    chatId={chatId!}
                    currentChat={currentChat}
                    onReloadChats={reloadChats}
                    onOpenSidebar={isTablet && isRightSidebarVisible ? hideRightSidebar : showLeftSidebar}
                    newPrompt={newPrompt}
                    newPromptOrigin={newPromptOrigin}
                />
            </div>

            {rightSidebarContentStack.map((content, i) => {
                const currentContentIndex = i + 1;
                const { metadata: rightSidePanelMetadata, children: rightSidePanelChildren, header: rightSidePanelHeader } = content || {};
                return (
                    <Sidebar
                        key={i}
                        id={sidePanelElementId}
                        direction='right'
                        isOpen={isRightSidebarVisible}
                        className={getClassNames([rightSidebarContentStackLength === currentContentIndex && style['right-sidebar-visible'], style['right-sidebar'], style[(rightSidePanelMetadata?.[OBJECT_ID_KEY]?.includes('.pdf') && 'pdf') || '']])}
                        onCloseSidebar={removeRightSidebarContent}
                        headerChildren={
                            <SidebarHeader
                                iconName={rightSidebarContentStackLength < 2 ? Icons.clear : Icons.arrowBack}
                                onClose={removeRightSidebarContent}
                            >
                                {rightSidePanelHeader}
                            </SidebarHeader>
                        }
                        scrollChildren={<SidebarChildren className={style.children}>{rightSidePanelChildren}</SidebarChildren>}
                    />
                );
            })}
        </div>

        <Disclaimer />
        <SidebarHistoryFooter {...props} />
    </PageLayout>;
});

const ActiveChatWindow = memo((props: ChatProps & Props) => {
    if (props.newPrompt) return <NewChatWindow {...props} />;
    return <ChatWindow {...props} />;
});

const Disclaimer = memo(() => {
    const { renderDisclaimer } = useDisclaimer();
    return renderDisclaimer({ className: style.disclaimer });
});

export const NewChatButton = memo(({ onClick }: ButtonProps) => {
    const labels = useLabels();
    const { pathname } = useLocation();
    const navigate = useNavigate();
    const l = useMemo(() => {
        return {
            nc: labels.newChat,
        };
    }, [labels.newChat]);

    const isMobile = useMediaSize((ms) => ms <= MediaSize.sm);

    const handleOnClick = (e: React.MouseEvent<HTMLButtonElement>) => {
        onClick?.(e);

        if (pathname !== AppRoute.chat) navigate(AppRoute.chat);
    };

    return <Button
        className={style.cta}
        label={l.nc}
        isSmall={isMobile}
        theme={ButtonThemes.secondary}
        onClick={handleOnClick}
        icon={<Icon.Base title='' theme={IconTheme.inherit} iconName={Icons.add} />} // Title not desired since it's used in button
    />;
});