import { isString } from './typeHelpers';

// const numExp = /(\d+)/;
/**Performs natural comparison on two values.\
 * If both values are of type `number` or 'boolean', numeric comparison is preformed.\
 * Otherwise, if one of the values are not a string, `0` is returned.\
 * In case both values are of type `string`, the natural comparison is performed.\
 * Ex: Unlike regular string comparison where "A2" is greater than "A10", with natural comparison "A2" is less than "A10".\
 * __Limitation: If the values contain more than one numeric part (Ex: A50X60), only the first numeric part is considered.__
 */
export const alphaNumCompare = (a: any, b: any): 1 | -1 | 0 => {
    if (!isNaN(a) && !isNaN(b)) {
        return a === b ? 0 : (a < b) ? -1 : 1;
    }

    if (!a && !b) return 0;
    if (!a && b) return -1;
    if (a && !b) return 1;

    if (!isString(a) || !isString(b)) return 0;

    return a.localeCompare(b, undefined, { numeric: true, sensitivity: 'base' }) as 1 | -1 | 0;
};
