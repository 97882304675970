import useLabels from '../../../../hooks/useLabels';
import useDcsEmbed from './actions/embed/useDcsEmbed';
import { Icon } from '../../../icon/Icon';
import { useCallback, useEffect, useMemo } from 'react';
import { useInvokeSidePanel, useLayout } from '../../../../contexts/layout/LayoutContext';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { dictionaryToQueryString } from '../../../../helpers/routeHelpers';
import { DynamicRouteProperties } from '../../../../router/Routing';
import { makeHashPath, processHashPaths } from '../../../../helpers/hashRouting';
import { Icons } from '../../../icon/icons/material';
import styles from './useActions.module.scss';

const culture = 'en';

const TARGET_CLASS_NAME = 'action-tooltip'; // TODO: Change this to the actual class name
export const OBJECT_ID_KEY = 'objectId';
export const OBJECT_TYPE_KEY = 'objectType';

const listenerMap = new WeakMap();

export type DataSetType = {
    [OBJECT_ID_KEY]: string;
    [OBJECT_TYPE_KEY]: string;
};

// These Enum values are used in ChatView.module.scss as class names
export enum ObjectTypes {
    pdf = "pdf",
    trace = "trace",
    data = "data",
    documents = "documents",

    // Not used currently
    txvEmbed = "txv-embed",
    exvEmbed = "exv-embed",

    // View depth limit exceeded
    error = "error",
}

export enum ActionsErrorExceptions {
    viewDepthLimitExceeded = "-view-depth-limit-exceeded"
}

export const createActionsErrorException = (exception: ActionsErrorExceptions) => {
    return `${ObjectTypes.error}${exception}`;
};

const getActionContainers = () => document.querySelectorAll(`.${TARGET_CLASS_NAME}`);
const getHashQuery = (dict: Record<string, string>) => dictionaryToQueryString({ dict, delimiter: "#" });

export const usePathRouting = () => {
    const { hash } = useLocation();
    const navigate = useNavigate();
    const { chatId } = useParams() as DynamicRouteProperties;

    const invokeNavigationToSidePanel = (newHash: string) => {
        if (!chatId) return;
        const returnHash = processHashPaths(hash, newHash);
        navigate(returnHash);
    };

    return { invokePathNavigateToSidePanel: invokeNavigationToSidePanel };
};

// export const isEmbeddedType = (type?: string) => {
//     return [ObjectTypes.exvEmbed, ObjectTypes.txvEmbed].includes(type as ObjectTypes || "");
// };

export function useAddSideActionCta({ isActive, dependencies }: { isActive: boolean; dependencies: React.DependencyList; }) {
    const labels = useLabels();
    const { invokePathNavigateToSidePanel } = usePathRouting();

    const l = useMemo(() => {
        return {
            [ObjectTypes.documents]: labels.viewDetails,
        };
    }, [labels]);

    const initActionsCta = useCallback((labels: Record<ObjectTypes, string>) => {
        const actionContainers = getActionContainers();

        const h = (objectId: string, objectType: string, e: MouseEvent,) => {
            e.preventDefault();
            invokePathNavigateToSidePanel(makeHashPath(objectType, objectId));
        };

        actionContainers.forEach(async (element) => {
            if (listenerMap.has(element)) return;
            element.classList.add(styles['actions-container']);
            const htmlElement = element as HTMLElement;
            const { objectId, objectType } = (htmlElement.dataset as DataSetType) ?? {};

            if (objectId && objectType) {
                listenerMap.set(element, true);
                const link = document.createElement('a');
                link.href = getHashQuery({ objectId, objectType });
                link.addEventListener('click', h.bind(null, objectId, objectType));
                link.innerText = objectType ? labels?.[objectType as ObjectTypes] : '-';
                element.appendChild(link);
            }
        });
    }, [invokePathNavigateToSidePanel]);

    useEffect(() => {
        if (!isActive) return;
        initActionsCta(l as Record<ObjectTypes, string>);
        return () => { };

        // eslint-disable-next-line
    }, [l, isActive, ...(dependencies || []), initActionsCta]);
};

export const useListenForSideAction = () => {
    const { showRightSidebar, addRightSidebarContent } = useLayout();
    const { exvEmbed, txvEmbed } = useDcsEmbed();
    const labels = useLabels();

    const actionMap = useMemo(() => {
        return {
            [ObjectTypes.error]: {
                callback: async (dataset: DataSetType) => {
                    if (!dataset.objectId) return console.error('Missing objectId in dataset');

                    const { error } = await import('./actions/error/Error');
                    error({ labels, exception: dataset.objectId as ActionsErrorExceptions, addRightSidebarContent, showRightSidebar });
                }
            },
            [ObjectTypes.txvEmbed]: {
                callback: async (dataset: DataSetType) => {
                    txvEmbed({ culture, labels, metadata: dataset, addRightSidebarContent, showRightSidebar });
                }
            },
            [ObjectTypes.exvEmbed]: {
                callback: async (dataset: DataSetType) => {
                    exvEmbed({ culture, labels, metadata: dataset, addRightSidebarContent, showRightSidebar });
                }
            },
            [ObjectTypes.data]: {
                callback: async (dataset: DataSetType, chatId?: string) => {
                    const { Data } = await import('./actions/data/Data');

                    addRightSidebarContent({
                        header: <b></b>,
                        children: <Data objectId={dataset.objectId} objectType={dataset.objectType as ObjectTypes} chatId={chatId} />,
                        metadata: dataset
                    });

                    showRightSidebar();
                }
            },
            [ObjectTypes.documents]: {
                callback: async (dataset: DataSetType) => {
                    const { Documents } = await import('./actions/documents/Documents');

                    addRightSidebarContent({
                        header: <b>Documents</b>,
                        children: <Documents />,
                        metadata: dataset
                    });
                    showRightSidebar();
                }
            },
            [ObjectTypes.pdf]: {
                callback: async (dataset: DataSetType) => {
                    const { Pdf } = await import('./actions/pdf/Pdf');
                    let fileUrl = dataset.objectId
                    if (fileUrl) fileUrl = decodeURIComponent(fileUrl)
                    addRightSidebarContent({
                        header: !fileUrl ? undefined : <Icon.Base title={labels.openInNewTabTitle} iconName={Icons.openInNewWindow} onClick={() => window.open(fileUrl, "_blank")} />,
                        children: <Pdf url={dataset.objectId} />,
                        metadata: dataset
                    });
                    showRightSidebar();
                }
            },
            [ObjectTypes.trace]: {
                callback: async (dataset: DataSetType) => {
                    const { Trace } = await import('./actions/trace/Trace');
                    addRightSidebarContent({
                        header: <b>{labels.chainViewerHeadline}</b>,
                        children: <Trace traceId={dataset.objectId} />,
                        metadata: dataset
                    });
                    showRightSidebar();
                }
            }
        };
    }, [addRightSidebarContent, exvEmbed, labels, showRightSidebar, txvEmbed]);

    const handleOpenActionModal = useCallback(async (dataset: DataSetType, chatId?: string) => {
        if (!dataset) return console.error('Missing dataset');
        const { objectId, objectType } = dataset;
        if (!objectId || !objectType) return console.error('Missing objectId or objectType in dataset');

        const { callback } = actionMap?.[objectType as ObjectTypes] || {};
        if (!callback) return console.error('No callback found for objectType:', objectType);
        callback(dataset, chatId);
    }, [actionMap]);

    return { handleOpenActionModal };
};

export const createAssetActionLink = (url: string, type = ObjectTypes.pdf) => `${type.replace('.', '').trim()}-${url}`;

export const useActionsListener = () => {
    const { handleOpenActionModal } = useListenForSideAction();

    // Invoke the action side panel when the hash changes
    useInvokeSidePanel((id, type, chatId) => {
        handleOpenActionModal({ objectId: id || '', objectType: type || ''}, chatId || '' );
    });
};