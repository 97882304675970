import Button from '../../../ui/button/Button';
import AsyncMarkdownWrapper from '../../../ui/markdown-wrapper/AsyncMarkdownWrapper';
import { CURRENT_RELEASES_VERSION, checkReleaseVersion, headlineValue } from '../config';
import { AnchorHeadline, normalizeAnchorId } from './AnchorHeadline';
import { AppRoute } from '../../../router/Routing';
import { useCallback } from 'react';


export const ReleaseNotesOverview = () => {
    const rawVersion = checkReleaseVersion(CURRENT_RELEASES_VERSION?.version!, CURRENT_RELEASES_VERSION?.draft);
    const version = headlineValue(CURRENT_RELEASES_VERSION?.version!, CURRENT_RELEASES_VERSION?.draft);
    const targetId = normalizeAnchorId(version);
    const href = `${AppRoute.info}#is=${targetId}`;

    const onClick = useCallback(() => document.getElementById(targetId)?.scrollIntoView({ behavior: 'smooth' }), [targetId]);

    return (
        <div>
            <AnchorHeadline headline={'Release Notes'} />
            <div>
                <b>Current release version: </b>
                <a href={href} onClick={onClick}>{rawVersion}</a>
            </div>
            <br />
            <br />
            <h4>Highlights:</h4>
            <h5>User Interface/User experience:</h5>
            <UiUxHighlight version={CURRENT_RELEASES_VERSION?.version!} />

            {/* <h5>Artificial Intelligence/Backend</h5>
            <AiHighlight version={CURRENT_RELEASES_VERSION?.version!} /> */}

            <div>
                <h5>Found and issue, or perhaps want to propose an improvement?</h5>
                <p>Thank you for using Danfoss AI Chatbot, we are looking forward to your feedback.</p>
                <br />
                <Button isDisabled label={'Report/Suggest'} isSmall />
            </div>
        </div>
    );
};


const UiUxHighlight = ({ version }: { version: string; }) => {
    const url = `${process.env.PUBLIC_URL}/release-notes/v${version}/HIGHLIGHTS.md`;
    return <AsyncMarkdownWrapper url={url} />;
};

// const AiHighlight = ({ version }: { version: string; }) => {
//     //TODO: Add proper route to service
//     const url = `${process.env.PUBLIC_URL}/ai/v${version}/HIGHLIGHTS.md`;
//     return <AsyncMarkdownWrapper url={url} />;
// };
