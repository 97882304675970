import { useCallback, useEffect } from 'react';

// Hook to work around the mobile browser viewport height issue
// Relevant for
//   - @mixin max-height-viewport
//   - index.scss

export default function useSetViewportHeight() {
    const updateVhCssVar = useCallback(() => {
        let vh = window.innerHeight * 0.01;
        document.documentElement.style.setProperty('--vh', `${vh}px`);
    }, []);

    useEffect(() => {
        updateVhCssVar();

        window.addEventListener('resize', updateVhCssVar);

        return () => {
            window.removeEventListener('resize', updateVhCssVar);
        };
    }, [updateVhCssVar]);
}