import axios from 'axios';
import TaskPromise from './TaskPromise';
import type { AuthTokenResponse, IHTTPDataService } from './IHTTPDataService';

export abstract class HTTPDataService implements IHTTPDataService {
    abstract baseURL: string;

    abstract getAccessToken(): Promise<string | undefined>;

    toAbsoluteApiUrl(endpoint: string, params?: Record<string, string>) {
        let url = endpoint;
        if (
            url.indexOf("http") !== 0 &&
            url.indexOf("//") !== 0 &&
            url.indexOf("/") !== 0
        ) {
            url = this.baseURL + "/" + url;
        }

        if (!params) return url;
        if (url.indexOf('?') === -1) url += "?";
        url += Object.keys(params).map(key => key + '=' + params[key]).join('&');

        return url;
    }

    promiseTokenCache: Promise<AuthTokenResponse | undefined> | undefined;
    cacheToken: AuthTokenResponse | undefined;
    private async getAuthToken() {
        const token = await this.getAccessToken();
        if (!token) return undefined;
        return { token };
    }

    getAcceptLanguageValue = () => {
        const languages = [this.getPreferredLanguage(), ...navigator.languages];
        let q = 1;
        return languages
            .filter(x => !!x)
            .slice(0, 10)
            .map(l => `${l};q=0.${10 - q++}`)
            .join(",");
    };
    getPreferredLanguage = () => localStorage.getItem("language");
    setPreferredLanguage = (language: string) => localStorage.setItem("language", language);

    private request<ResponseType>(method: 'GET' | 'POST' | 'PATCH' | 'DELETE', endpoint: string, params?: Record<string, string | number>, data?: any): TaskPromise<ResponseType> {

        const cancelTokenSource = axios.CancelToken.source();
        const promise = new TaskPromise<ResponseType>(async (resolve, reject) => {
            const url = this.toAbsoluteApiUrl(endpoint);

            let attemptCount = 0;
            const maxAttemptCount = 1;
            const call = async () => {
                // The number of times the `call` function has been triggered.
                attemptCount++;

                const handleError = (error: any, reattempt: boolean) => {
                    const response = error.response || error;

                    if (axios.isCancel(error)) {
                        reject('cancellationError');
                        return;
                    }

                    const statusText = response.statusText || response?.data?.Message || response?.data?.message || '';

                    // retry if i get 401 and i have a token 
                    if (!reattempt || !token || response.status !== 401) {
                        const error: HttpError = {
                            status: response.status || 0,
                            statusText,
                            data: response.data,
                            config: response.config,
                        };
                        reject(error);
                    }
                    else call();
                };

                let token: string | undefined = undefined;

                try {
                    token = (await this.getAuthToken())?.token; // force token refresh if this is not the first attempt.
                } catch (err: any) {
                    handleError(err, false); // do not reattempt in this case. The token call has failed.
                    return;
                }

                const headers: Record<string, string> = {
                    Authorization: `Bearer ${token}`,
                    'Accept-Language': this.getAcceptLanguageValue(),
                };

                axios({
                    url,
                    method,
                    data,
                    params,
                    withCredentials: false,
                    cancelToken: cancelTokenSource.token,
                    headers,
                })
                    .then((response) => resolve(response.data))
                    .catch((err) => handleError(err, attemptCount < maxAttemptCount)); // reattempt only if maxAttemptCount is not reached.
            };
            await call();
        });

        promise.disposed(() => {
            cancelTokenSource.cancel();
        });
        return promise;
    }

    get = <T>(path: string, queryParams?: Record<string, string | number>) => {
        return this.request<T>('GET', path, queryParams);
    };

    post = <T>(path: string, queryParams?: Record<string, string | number>, data?: Record<string, any>) => {
        return this.request<T>('POST', path, queryParams, data);
    };

    postStream = <T>(path: string, queryParams?: Record<string, string | number>, data?: Record<string, any>) => {
        return this.request<T>('POST', path, queryParams, data);
    };

    patch = <T>(path: string, queryParams?: Record<string, string | number>, data?: Record<string, any>) => {
        return this.request<T>('PATCH', path, queryParams, data);
    };

    delete = <T>(path: string, queryParams?: Record<string, string | number>) => {
        return this.request<T>('DELETE', path, queryParams);
    };
}


export type HttpError = {
    status: number,
    statusText: string;
    data?: any,
    config?: any;
};