import useLabels from './useLabels';
import { useCallback, useMemo, } from 'react';
import { rawLlmToUI } from '../helpers/transformHelpers';
import { useAppInfo } from '../contexts/app-info/AppInfoContext';
import { sortItemsBy } from '../helpers/listsHelpers';

export default function useInfo() {
    const labels = useLabels();
    const { llms, personas, access } = useAppInfo();

    const llmOptions = useMemo(() => {
        if (!llms) return [];
        return llms
            .map(rawLlmToUI)
            .sort((a, b) => a.sort - b.sort);
    }, [llms]);

    const personaOptions = useMemo(() => {
        if (!personas?.length) return [];
        return [...sortItemsBy(personas, "sort"), {
            key: "",
            title: labels.advancedTitle,
            description: labels.advancedDescription,
        }];
    }, [labels.advancedDescription, labels.advancedTitle, personas]);

    const allowedPersonaOptions = useMemo(() => {
        return personaOptions.filter(p => !p.key || !!access?.personas?.[p.key]);
    }, [personaOptions, access]);

    const getPersonaFromKey = useCallback((key: string) => personas?.find(p => p.key === key), [personas]);

    const getLlmFromKey = useCallback((key: string) => llms?.find(llm => llm.key === key), [llms]);

    const getLlmLabelFromKey = useCallback((key: string) => getLlmFromKey(key)?.title || key, [getLlmFromKey]);

    const getPersonaLabelFromKey = useCallback((key: string) => getPersonaFromKey(key)?.title ?? key, [getPersonaFromKey]);

    return {
        llmOptions,
        personaOptions,
        allowedPersonaOptions,
        getPersonaFromKey,
        getLlmFromKey,
        getLlmLabelFromKey,
        getPersonaLabelFromKey
    };
}