import React, { useLayoutEffect, useRef } from 'react';

const mosaicInputBaseHeight = 40;

function autoGrowTextareaHeight(this: HTMLTextAreaElement) {
    const el = this;
    if (!el) return;
    el.style.height = mosaicInputBaseHeight + 'px';

    // If only empty white, don't grow the hight
    if(!el.value.trim()) return;

    // Mosaic has uneven top and bottom padding, so we override that
    // Because of this, we add the 2px to the scrollHeight
    
    const extraPadding = 2;
    el.style.height = Math.max((el.scrollHeight + extraPadding), mosaicInputBaseHeight) + 'px';
};

export default function useTextareaMutations(element: React.RefObject<HTMLTextAreaElement>, deps?: React.DependencyList) {
    const lastEl = useRef<HTMLTextAreaElement>();
    useLayoutEffect(() => {

        const el = element.current;
        if (!el) return;

        const addListeners = lastEl.current !== el;
        lastEl.current = el;

        if (addListeners) {
            const events = ['keydown', 'keyup', 'blur', 'focusout', 'focus', 'mouseup', 'change', 'input'] as (keyof HTMLElementEventMap)[];

            events.forEach(event => {
                lastEl.current?.removeEventListener(event, autoGrowTextareaHeight);

                el.removeEventListener(event, autoGrowTextareaHeight);
                el.addEventListener(event, autoGrowTextareaHeight);
            });
        }
        autoGrowTextareaHeight.call(el);
        setTimeout(() => autoGrowTextareaHeight.call(el), 50); // Also after a small delay in case the content to capture re-renders
        // eslint-disable-next-line 
    }, [element, ...(deps || [])]);
}