import { useEffect } from 'react';
import { alphaNumCompare } from "../../../../helpers/comparisonHelpers";
import styles from './useTableSorting.module.scss';

export const EXCLUDED_TARGET_TABLE_CLASS_NAME = 'exclude-manual-table-sorting';

const TARGET_ELEMENT = 'table';
const listenerMap = new WeakMap();
const getTables = () => document.getElementsByTagName(TARGET_ELEMENT);
const getTableRowTextContent = (index: number) => (row: HTMLTableRowElement) => row?.children?.[index]?.textContent?.trim().toLowerCase() ?? '';

const attachTableSortListener = (table: HTMLTableElement) => {
    const sortTableByHeader = (rows: HTMLTableRowElement[], headerIndex: number, isAscending: boolean) => {
        if (!table || table.tagName.toLowerCase() !== 'table') {
            console.error("Invalid table element provided.");
            return;
        }

        const getRowValue = getTableRowTextContent(headerIndex);
        const compareRows = (rowA: HTMLTableRowElement, rowB: HTMLTableRowElement) => {
            const valueA = getRowValue(rowA);
            const valueB = getRowValue(rowB);
            const result = alphaNumCompare(valueA, valueB);
            if (result) return isAscending ? -result : result;
            return 0;
        };

        rows.sort(compareRows);

        const tbody = table.querySelector('tbody');

        if (tbody !== null) {
            tbody.innerHTML = '';
            // Append the sorted rows back to the table body
            rows.forEach(row => tbody.appendChild(row));
        }
        table.classList.add(styles['sorted']);
        attachTableSortListener(table);
    };

    const rows = Array.from(table.querySelectorAll('tr')).slice(1);  // Exclude the header row
    const headers = table.querySelectorAll('th');  // Assuming header elements are within <th> tags

    headers.forEach((header, headerIndex) => {
        const targetClassName = styles['sortable'];
        const descClassName = styles['descending'];
        const ascClassName = styles['ascending'];
        const span = document.createElement('span');
        const div = document.createElement('div');

        div.classList.add(targetClassName);

        if (!listenerMap.has(header)) {

            const tableSortListener = () => {
                let isAscending = true;

                if (div.classList.contains(descClassName)) {
                    div.classList.remove(descClassName);
                    div.classList.add(ascClassName);
                    isAscending = true;
                }
                else if (div.classList.contains(targetClassName) || div.classList.contains(ascClassName)) {
                    div.classList.remove(ascClassName);
                    div.classList.add(descClassName);
                    isAscending = false;
                }

                sortTableByHeader(rows, headerIndex, isAscending);

                // Remove the sorting classes from other headers
                headers.forEach((header, index) => {
                    if (index !== headerIndex) {
                        header.querySelector(`div .${targetClassName}`)?.classList.remove(ascClassName, descClassName);
                    }
                });
            };

            div.innerHTML = header.innerHTML;
            div.appendChild(span);

            header.innerHTML = '';
            header.appendChild(div);
            header.classList.add(styles['headline']);

            span.addEventListener('click', tableSortListener);
            listenerMap.set(header, tableSortListener);
        }
    });
};

const processTables = (callback: (table: HTMLTableElement, index: number) => void) => Object.values(getTables() || []).filter(x => {
    return !x.classList.contains(EXCLUDED_TARGET_TABLE_CLASS_NAME);
}).forEach(callback);
const initTableSort = () => processTables(attachTableSortListener);

export default function useTableSorting({ isActive, dependencies }: { isActive: boolean; dependencies: React.DependencyList; }) {
    useEffect(() => {
        if (!isActive) return;
        initTableSort();
        return () => { };

        // eslint-disable-next-line
    }, [isActive, ...(dependencies || [])]);
};