import Accordion from '../../../ui/accordion/Accordion';
import useLabels from '../../../hooks/useLabels';
import { ReactNode, useEffect, useRef, useState } from 'react';
import { getClassNames } from '../../../helpers/classHelpers';
import { AnchorHeadline, normalizeAnchorId } from './AnchorHeadline';
import { TARGET_ANCHOR_KEY, headlineValue } from '../config';
import { useLocation } from 'react-router-dom';
import { queryStringToDictionary } from '../../../helpers/routeHelpers';
import style from '../Information.module.scss';

export const ReleaseTimeline = ({ assistantKey, version, draft, date, content }: { assistantKey?: string; draft?: boolean; version: string; date: string; content: (isOpen: boolean) => ReactNode; }) => {
    const labels = useLabels();
    const location = useLocation();
    const [isOpen, setIsOpen] = useState(false);

    const headline = headlineValue(version, draft);

    const loaded = useRef(false);
    useEffect(() => {
        if (loaded.current || !location || !headline) return;
        loaded.current = true;
        const targetHash = queryStringToDictionary(location.hash)?.[TARGET_ANCHOR_KEY];
        const currentHash = normalizeAnchorId(headline, assistantKey);
        setIsOpen(targetHash === currentHash);
        return () => { };
    }, [location, headline, assistantKey]);

    return <li className={getClassNames(['df-timeline-steps', style.timeline])}>
        <div className="df-timeline-container">
            <div className="df-timeline">
                <div className="df-timeline-circle" />
                <div className="df-timeline-line" />
            </div>
        </div>
        <div className={style['timeline-content']}>
            <AnchorHeadline headline={headline} type={'h5'} prefix={assistantKey} />
            <p className={style.date}>{date}</p>

            <Accordion
                headline={labels.viewReleaseNotes}
                content={content(isOpen)}
                externalState={{
                    isOpen,
                    onToggle: setIsOpen
                }}
            />
        </div>
    </li>;
};
