import Button, { ButtonIconPosition, ButtonProps } from './Button';
import React, { useCallback, useState } from 'react';

export type ButtonAsyncProps = Omit<ButtonProps, 'isLoading'>;

const ButtonAsync = (props: ButtonAsyncProps) => {
    const [isLoading, setIsLoading] = useState(false);

    const handleClick = useCallback(async (e: React.MouseEvent<HTMLButtonElement>) => {
        e.preventDefault();
        e.stopPropagation();

        const { onClick, onAsyncError } = props || {};
        onAsyncError?.(undefined);
        setIsLoading(true);

        try {
            const response = await onClick?.(e);
            setIsLoading(false);
            return response;
        }
        catch (e) {
            onAsyncError?.(e);
            setIsLoading(false);
        }
    }, [props]);

    return (
        <Button
            iconPosition={ButtonIconPosition.beginning}
            {...props}
            isLoading={isLoading}
            onClick={handleClick}
        />
    );
};

export default ButtonAsync;
