import { DependencyList, EffectCallback, useEffect } from "react";
import { isFunction } from "../helpers/typeHelpers";
import useRefLive from "./useRefLive";

export type CancellationToken = { cancelled: boolean; };
export default function useEffectAsync(effectFactory: (token: CancellationToken) => EffectCallback, deps?: DependencyList) {
    const effectFactoryRef = useRefLive(effectFactory);
    useEffect(() => {
        const token: CancellationToken = { cancelled: false };
        const effect = effectFactoryRef.current(token);
        const destructor: ReturnType<EffectCallback> = effect();
        return () => {
            token.cancelled = true;
            if (isFunction(destructor))
                destructor();
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, (deps ?? []).concat(effectFactoryRef));
}