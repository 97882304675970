import Custom from './Custom';
import { ImageProps } from '../Image.types';
import { getClassNames } from '../../../helpers/classHelpers';
import styles from '../Image.module.scss';

export default function ImageZoom(props: ImageProps) {
    return <Custom
        enableZoom
        className={getClassNames([styles.zoom, 'zoom-image'])}
        images={[props]}
    />;
};