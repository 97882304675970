import { mockChatMessageFeedback, mockChatMessageInsights, mockCreateChatResponse, mockDynamicData, mockGetChatResponse, mockGetChatsResponse, mockPromptResponse, mockSubmitProductivityGainResponse, mockUpdateChatMetadataResponse, sidePanelDataMock } from '../data/mockData';
import { guid } from '../helpers/randomHelpers';
import { ChatMessageTracesResponse, ChatMetadata, GetChatResponse, SessionResponse } from '../models/ChatTypes';
import type { ChatMessageFeedback, UpdateChatMetadataResponse } from '../models/types';
import type { IChatService } from './IChatService';

const delay = <R>(response: R) => new Promise<R>((resolve) => setTimeout(() => resolve(response), 500));

const encoder = new TextEncoder();

const enqueueChunk = (controller: ReadableStreamController<any>, chunk: string, delay: number) => {
    setTimeout(() => controller.enqueue(encoder.encode(chunk)), delay);
};

const enqueueMock = (controller: ReadableStreamController<any>, text: string) => {
    let currentDelay = 0;
    let chunkDelay = 5;

    text.split('').forEach(chunk => {
        currentDelay += chunkDelay;
        enqueueChunk(controller, chunk, currentDelay);
    });

    setTimeout(() => controller.close(), currentDelay + 1);
};

const createStream = (text: string) => new ReadableStream({ start: (controller) => enqueueMock(controller, text) });

class MockChatService implements IChatService {
    baseURL = '';

    constructor(public getAccessToken: () => Promise<string | undefined>) { }

    getChat = async (sessionId: string) => delay<GetChatResponse>(mockGetChatResponse?.find(x => x.session.session_id === sessionId) || mockGetChatResponse[0]);
    getAllChats = async () => delay<SessionResponse[]>(mockGetChatsResponse);


    createChat = async (initialMessage: string, llm: string, temperature: number, persona?: string) => delay<SessionResponse>(mockCreateChatResponse);

    updateChatMetadata = async (sessionId: string, metadata: Partial<ChatMetadata>) => delay<UpdateChatMetadataResponse>(mockUpdateChatMetadataResponse);

    deleteChat = (sessionId: string) => Promise.resolve();
    deleteAllChats = () => Promise.resolve();

    submitProductivityGain = async (sessionId: string, value: number) => delay(mockSubmitProductivityGainResponse);

    prompt = async (message: string, sessionId: string) => {
        const text = mockPromptResponse;
        const response = new Response(createStream(text), {
            status: 200,
            statusText: 'OK',
            headers: {
                'Content-Type': 'application/json',
            },
        });

        await delay(true);

        return {
            response,
            aiId: guid(),
            humanId: guid(),
            abort: () => { },
        };
    };

    tableData = (sessionId: string, source: string, page?: number, params?: Record<string, string[]>) => Promise.resolve(mockDynamicData);
    dataSidePanel = (sessionId: string, objectId: string) => delay(sidePanelDataMock);
    messageLike = (sessionId: string, messageId: string) => Promise.resolve();
    messageDislike = (sessionId: string, messageId: string) => Promise.resolve();
    messageResetVote = (sessionId: string, messageId: string) => Promise.resolve();
    messageComment = (sessionId: string, messageId: string, value: string) => Promise.resolve();
    messageInsights = (traceId: string) => Promise.resolve<ChatMessageTracesResponse>(mockChatMessageInsights);
    getMessageFeedback = (sessionId: string, messageId: string) => Promise.resolve<ChatMessageFeedback>(mockChatMessageFeedback);
    getChatMessagesFeedback = (sessionId: string) => Promise.resolve<ChatMessageFeedback[]>([mockChatMessageFeedback, mockChatMessageFeedback, mockChatMessageFeedback]);
}

export default MockChatService;