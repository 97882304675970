import EnvConfigHelper from '../helpers/envConfigHelper';
import { limitSubjectLength } from '../helpers/chatHelpers';
import { HTTPDataService, HttpError } from './HTTPDataService';
import type { ChatMessageTracesResponse, ChatMessageStream, ChatMetadata, GetChatResponse, SessionResponse } from '../models/ChatTypes';
import type { ChatMessageFeedback, ProductivityGainResponse, PromptPayload, UpdateChatMetadataResponse, Vote } from '../models/types';
import type { IChatService } from './IChatService';
import { ComponentsData, DataRequest, TableRowsResponse } from '../models/SidePanelTypes';


const getResponseContent = async (response: Response) => {
    const text = await response.text();
    const result = { text, data: undefined as any };
    const contentType = response.headers.get('content-type');
    if (contentType?.includes('application/json'))
        try {
            result.data = JSON.parse(text);
        }
        catch (e) {
            console.error('Unable to parse json response', e);
        }
    return result;
};

class ChatService extends HTTPDataService implements IChatService {
    baseURL = EnvConfigHelper.get('api-base-url', '');

    constructor(public getAccessToken: () => Promise<string | undefined>) {
        super();
    }

    getChat = async (sessionId: string) => this.get<GetChatResponse>(`api/me/session/${sessionId}`);
    getAllChats = async () => {
        const result = await this.get<SessionResponse[]>('api/me/session/');
        return result;
    };

    createChat = async (initialMessage: string, llm: string, temperature: number, persona?: string) => {
        const trimmedMessage = limitSubjectLength(initialMessage);
        const result = await this.post<SessionResponse>('api/me/session/', {}, {
            llm,
            temperature,
            subject: trimmedMessage,
            persona
        });
        return result;
    };

    updateChatMetadata = async (sessionId: string, metadata: Partial<ChatMetadata>) => {
        const result = await this.patch<UpdateChatMetadataResponse>(`api/me/session/${sessionId}`, {}, metadata);
        return result;
    };

    deleteChat = (sessionId: string) => this.delete<void>(`api/me/session/${sessionId}`);
    deleteAllChats = () => this.delete<void>(`api/me/privacy/clear-all`);

    submitProductivityGain = async (sessionId: string, productivityGain: number) => {
        const uri = `api/me/session/${sessionId}/productivity-gain`;
        const query = { session_id: sessionId };
        const payload = { productivity_gain: productivityGain };
        return this.patch<ProductivityGainResponse>(uri, query, payload);
    };

    prompt = (message: string, sessionId: string) => new Promise<ChatMessageStream>(async (resolve, reject) => {
        const payload: PromptPayload = { message: message };

        const uri = this.toAbsoluteApiUrl(`api/me/chat/${sessionId}`);
        const aborter = new AbortController();
        const token = this.getAccessToken();

        try { await token; }
        catch (e) { return reject(e); }

        const promise = fetch(uri, {
            method: 'POST',
            signal: aborter.signal,
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${await token}`
            },
            body: JSON.stringify(payload),
        });

        let result: ChatMessageStream | undefined;
        let error: HttpError | undefined;
        try {
            const response = await promise;

            if (!response.ok) error = {
                status: response.status,
                statusText: response.statusText,
                ...await getResponseContent(response)
            };
            else {
                const headers = response.headers;
                const aiId = headers.get('x-ai-message-id');
                const humanId = headers.get('x-human-message-id');

                if (!aiId || !humanId) error = { status: 400, statusText: 'Message id headers missing' };
                else {
                    result = {
                        response,
                        aiId,
                        humanId,
                        abort: () => aborter.abort(),
                    };
                }
            }
        }
        catch (e) {
            // https://developer.mozilla.org/en-US/docs/Web/API/fetch#exceptions
            if (e instanceof DOMException) error = { status: 0, statusText: 'abort' };
            else error = { status: 0, statusText: 'network_error' };
        }

        if (result) resolve(result);
        else reject(error ?? { status: -1, statusText: '' });
    });

    private _messageVote = async (sessionId: string, message_id: string, vote: Vote) => {
        const uri = `api/me/feedback/${sessionId}/vote`;
        const payload = { vote, message_id };
        const result = await this.patch<void>(uri, undefined, payload);
        return result;
    };

    messageLike = async (sessionId: string, messageId: string) => {
        const result = await this._messageVote(sessionId, messageId, 1);
        return result;
    };
    messageDislike = async (sessionId: string, messageId: string) => {
        const result = await this._messageVote(sessionId, messageId, -1);
        return result;
    };
    messageResetVote = async (sessionId: string, messageId: string) => {
        const result = await this._messageVote(sessionId, messageId, 0);
        return result;
    };

    messageComment = async (sessionId: string, message_id: string, comment: string) => {
        const uri = `api/me/feedback/${sessionId}/comment`;
        const payload = { comment, message_id };
        const result = await this.patch<void>(uri, undefined, payload);
        return result;
    };

    messageInsights = async (traceId: string) => {
        const uri = `api/me/trace/${traceId}`;
        const result = await this.get<ChatMessageTracesResponse>(uri);
        return result;
    };

    tableData = async (sessionId: string, source: string, page?: number, params?: Record<string, string[]>): Promise<TableRowsResponse> => {
        const uri = `api/me/session/${sessionId}/side-panel-data`;
        const query: DataRequest = { session_id: sessionId, data_key: source };
        const result = await this.get<TableRowsResponse>(uri, query);
        return result ?? { rows: [], count: 0};
    };

    dataSidePanel = async (sessionId: string, objectId: string) => {
        const uri = `api/me/session/${sessionId}/side-panel-data`;
        const query: DataRequest = { session_id: sessionId, data_key: decodeURIComponent(objectId) };

        const response = await this.get<{ data: ComponentsData; }>(uri, query);
        return response.data;
    };

    private _getMessagesFeedback = async (sessionId: string, message_id?: string) => {
        const uri = `api/me/feedback/${sessionId}`;
        const params: { message_id?: string; } = {};
        if (message_id) params.message_id = message_id;
        const result = await this.get<ChatMessageFeedback[]>(uri, params);
        return result;
    };

    getChatMessagesFeedback = async (sessionId: string) => {
        const result = await this._getMessagesFeedback(sessionId);
        return result;
    };
    getMessageFeedback = async (sessionId: string, messageId: string) => {
        const [result] = await this._getMessagesFeedback(sessionId, messageId);
        return result;
    };
}

export default ChatService;