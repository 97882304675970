import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import LoadingIcon from '../../ui/loading-icon/LoadingIcon';
import useLabels from '../../hooks/useLabels';
import { Icon } from '../../ui/icon/Icon';
import { getClassNames } from '../../helpers/classHelpers';
import { limitSubjectLength } from '../../helpers/chatHelpers';
import { UpdateChatMetadataResponse } from '../../models/types';
import { isEventEnter, isEventEscape } from '../../helpers/eventHelpers';
import { useParams } from 'react-router-dom';
import { TestIds } from '../../mocks/ids';
import { Icons } from '../../ui/icon/icons/material';
import style from './ChatWindowHeader.module.scss';

export type ChatWindowHeaderProps = {
    initialTitle?: string;
    onCreateNewChat?: () => void;
    showBurger?: boolean;
    onBurgerClick?: () => void;
    onClockClick?: () => void;
    onRenameChat?: (newName: string) => Promise<UpdateChatMetadataResponse | undefined>;
    className?: string;
    disableInteractions?: boolean;
};

const ChatWindowHeader: React.FC<ChatWindowHeaderProps> = ({ initialTitle, showBurger = true, onBurgerClick, onClockClick, onCreateNewChat, onRenameChat, className, disableInteractions }) => {
    const inputRef = useRef<HTMLInputElement>(null);
    const { chatId } = useParams() || {};

    const [isRenaming, setIsRenaming] = useState(false);
    const [isSavingName, setIsSavingName] = useState(false);
    const [title, setTitle] = useState(initialTitle);

    const labels = useLabels();
    const l = useMemo(() => {
        return {
            reportGainIconTitle: labels.reportGainIconTitle,
            createNewChatIconTitle: labels.createNewChatIconTitle,
            editChatNameIconTitle: labels.editChatNameIconTitle,
            menuIconTitle: labels.menuIconTitle,
        };
    }, [labels]);

    useEffect(() => {
        setTitle(initialTitle);
    }, [initialTitle]);

    const closeRenaming = useCallback(() => setIsRenaming(false), []);

    const onCancelRenameHandler = useCallback(() => {
        closeRenaming();
        setTitle(initialTitle?.trim());
    }, [closeRenaming, initialTitle]);

    const onRenameChatHandler = useCallback(async () => {
        if (!title?.trim() || initialTitle?.trim() === title?.trim()) {
            onCancelRenameHandler();
            return;
        }

        if (!onRenameChat) return;
        const trimmedChatName = limitSubjectLength(title);
        setIsSavingName(true);
        try {
            await onRenameChat(trimmedChatName);
            setTitle(trimmedChatName);
            closeRenaming();
        } catch (e) {
            console.error('Failed to rename chat.', e);
        } finally {
            setIsSavingName(false);
        }
    }, [closeRenaming, initialTitle, onCancelRenameHandler, onRenameChat, title]);

    const initiateRenaming = useCallback(() => {
        setIsRenaming(true);

        // We need to request animation frame to
        // focus the input after the modal is opened if
        // the user clicks multiple times on the edit icon
        requestAnimationFrame(() => {
            const el = inputRef.current;
            if (!el) return;
            el.focus();
            el.select();
        });
    }, []);

    const onInputBlurHandler = useCallback(() => {
        setIsRenaming(false);
        onRenameChatHandler();
    }, [onRenameChatHandler]);

    const onInputKeydownHandler = useCallback((e: React.KeyboardEvent<HTMLInputElement>) => {
        const isEsc = isEventEscape(e);
        const isEnter = isEventEnter(e);
        if (isEsc) onCancelRenameHandler();
        else if (isEnter) onRenameChatHandler();
    }, [onCancelRenameHandler, onRenameChatHandler]);

    const onInputClickHandler = useCallback((e: React.MouseEvent<HTMLInputElement>) => {
        e.stopPropagation();
        if (e.detail === 2) initiateRenaming();
    }, [initiateRenaming]);

    return (<>
        <header className={getClassNames([className, style.header])}>

            <div className={style.left}>
                {showBurger && onBurgerClick && <Icon.Base title={l.menuIconTitle} className={getClassNames([style.icon, style.menu, style.mobile])} iconName={Icons.menu} onClick={onBurgerClick} />}
            </div>

            <div className={style.center}>
                <input
                    type="text"
                    className={style.center}
                    ref={inputRef}
                    disabled={!isRenaming}
                    value={title}
                    onChange={(e) => setTitle(e.target.value)}
                    onBlur={onInputBlurHandler}
                    onKeyDown={onInputKeydownHandler}
                />
                {/* To catch (double) click event on disabled input */}
                {chatId && !isRenaming && <div data-testid={TestIds.inputOverlay} className={getClassNames([style.center, style['input-overlay']])} onClick={onInputClickHandler}></div>}
            </div>

            <div className={style.right}>
                {chatId && <>
                    {onClockClick && <Icon.Base title={l.reportGainIconTitle} className={getClassNames([style.icon, style['input-gain']])} iconName={Icons.schedule} onClick={onClockClick} />}
                    {onCreateNewChat && <Icon.Base title={l.createNewChatIconTitle} iconName={Icons.add} className={getClassNames([style.icon, style['new-chat'], style.mobile])} onClick={onCreateNewChat} />}
                    {(isSavingName) && <span className={getClassNames([style.icon, style.loader, style.desktop])}><LoadingIcon size={24} /></span>}
                    {!isSavingName && <Icon.Base title={l.editChatNameIconTitle} iconName={Icons.edit} className={getClassNames([style.icon, style.rename, style.desktop])} onClick={initiateRenaming} />}
                </>}
            </div>
            {disableInteractions && <div className={style.overlay}></div>}
        </header>
    </>);
};

export default ChatWindowHeader;