import { Dispatch, SetStateAction, useEffect } from 'react';
import { SessionResponse } from '../../models/ChatTypes';
import { useChat } from '../../contexts/chat/ChatContext';

type Props = {
    setChats: Dispatch<SetStateAction<SessionResponse[] | undefined>>;
};

const useLoadAllChats = ({ setChats }: Props) => {
    const { getAllChats } = useChat();

    useEffect(() => {

        let s = setChats;

        (async () => {
            try {
                const chats = await getAllChats();
                s(chats);
            } catch (e) { }
        })();

        return () => {
            s = () => { };
        };
    }, [getAllChats, setChats]);
};

export default useLoadAllChats;