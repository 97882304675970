import { Icon } from "../../icon/Icon";
import { useState, useRef, useEffect } from "react";
import { getClassNames } from "../../../helpers/classHelpers";
import { BaseType } from "../Image.types";
import { placeholderClass, validateImage } from "../imageHelper";
import { Icons } from "../../icon/icons/material";
import styles from "../Image.module.scss";

const alias = 'ui-image';

function isPlaceholder(el: HTMLImageElement) {
    return el.classList.contains(placeholderClass);
}

export default function Base(props: BaseType) {
    const { className, src, alt, height, width, objectFit, isLoading, disableLazyLoad, showLoadingWhenFetching, onClick, onExpand } = props;
    const [isFetchingImage, setIsFetchingImage] = useState(false);
    const [error, setError] = useState(false);
    const showLoadingShimmer = showLoadingWhenFetching && (isLoading || isFetchingImage);
    const classNames = getClassNames([className, styles[alias], objectFit && styles[objectFit], showLoadingShimmer && styles.loading, error && styles._error]);
    const imgRef = useRef<HTMLImageElement>(null);

    useEffect(() => {
        const el = imgRef.current;
        if (!el || isPlaceholder(el) || !src || !showLoadingWhenFetching) return;
        setError(false);
        setIsFetchingImage(true);

        function loaded() {
            setIsFetchingImage(false);
            cleanup();
        }
        function onError(e: unknown) {
            loaded();
            setError(!!e);
            console.error(e);
        }

        if (el.complete && src && el.naturalWidth > 0) {
            return loaded();
        }

        el.addEventListener('load', loaded);
        el.addEventListener('error', onError);
        function cleanup() {
            if (!el) return;
            el.removeEventListener('load', loaded);
            el.removeEventListener('error', onError);
        }

        return cleanup;
    }, [showLoadingWhenFetching, src]);

    return (
        <picture className={getClassNames([styles.base, classNames])} style={{ width, height }} onClick={(e) => { e.stopPropagation(); onClick?.(src || ''); }}>
            {validateImage({
                url: src,
                alt,
                children: <img ref={imgRef} loading={disableLazyLoad ? 'eager' : 'lazy'} style={{ objectFit: showLoadingShimmer ? 'cover' : objectFit || 'contain' }} alt={alt} src={src} />,
            })}
            {onExpand && <Icon.Base className={styles.expand} title={""} iconName={Icons.zoomIn} onClick={() => onExpand?.(src || '')} />}
        </picture>
    );
}
