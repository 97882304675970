import React, { FC } from 'react';
import { getClassNames } from '../../helpers/classHelpers';

type Props = {
    isLight?: boolean;
    isHeavy?: boolean;
    className?: string;
};

const Divider: FC<Props> = ({ isLight, isHeavy, className }) => {
    return (
        <hr className={getClassNames([className, 'df-divider', isLight && 'divider-light', isHeavy && 'divider-heavy'])} />
    );
};

export default Divider;