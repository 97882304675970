import React, { useMemo, type PropsWithChildren, } from 'react';
import Header from '../../ui/header/Header';
import useLabels from '../../hooks/useLabels';
import useUserInstructionsModal from '../../hooks/useUserInstructionsModal';
import { getClassNames } from '../../helpers/classHelpers';
import { useNavigate } from 'react-router-dom';
import { AppRoute } from '../../router/Routing';
import { BaseUIType } from '../../models/UITypes';
import style from './PageLayout.module.scss';

type Props = BaseUIType & {
    mainClassName?: string;
};

const PageLayout: React.FC<PropsWithChildren<Props>> = ({ testId, mainClassName, children }) => {
    // Disabled disclaimer since terms api is making the site crash - to be enabled again
    const labels = useLabels();
    const navigate = useNavigate();
    const { openModal, renderModal } = useUserInstructionsModal();

    const userDropdownItems = useMemo(() => {
        return [
            { label: labels.informationPageCta, onClick: () => navigate(AppRoute.info), },
            { label: labels.userInstructions, onClick: openModal, }
        ];
    }, [labels, openModal, navigate]);
    return (<>
        <div className={getClassNames([style['page-layout'], 'df-site'])} data-testid={testId}>
            <Header userDropdownItems={userDropdownItems} />

            <main className={getClassNames([mainClassName, style['main-section'], 'df-main-content'])}>
                {children}
            </main>
        </div>
        {renderModal}
    </>);
};

export default PageLayout;