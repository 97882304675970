import React from 'react';
import useScrollLock from '../../hooks/useScrollLock';
import { ListItem } from '../../ui/list/List';
import { getClassNames } from '../../helpers/classHelpers';
import { DateGroup } from '../../helpers/dateHelpers';
import style from './Sidebar.module.scss';

type Props = {
    id?: string
    className?: string;
    direction?: 'left' | 'right';
    isOpen?: boolean;
    scrollChildren?: React.ReactNode;
    headerChildren?: React.ReactNode;
    footerChildren?: React.ReactNode;

    onCloseSidebar?: () => void;
};

export type EnrichedListItem = ListItem & {
    date: string;
    dateGroup: DateGroup;
};

const Sidebar: React.FC<Props> = ({ id, className, direction = 'left', isOpen, scrollChildren, headerChildren, footerChildren, onCloseSidebar }) => {
    useScrollLock(isOpen);

    return (<>
        <aside id={id} className={getClassNames([className, style[direction], style.sidebar, isOpen && '_sidebar-open'])}>
            {headerChildren}

            <div className={style.scroll}>
                {scrollChildren}
            </div>
        </aside>
        <div onClick={onCloseSidebar} className={style.backdrop}></div>
        {footerChildren}
    </>);
};

export default Sidebar;