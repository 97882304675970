import { useMemo, useState, useCallback, useEffect } from 'react';
import useLabels from '../../hooks/useLabels';
import ButtonAsync from '../../ui/button/ButtonAsync';
import Input from '../../ui/input/Input';
import Modal from '../../ui/modal/Modal';
import Button, { ButtonThemes } from '../../ui/button/Button';
import { isValidNumber } from '../../helpers/numberHelpers';
import style from './ProductivityGainModal.module.scss';

type ProductivityGainModalProps = {
    initialProductivityGain?: number | null;
    chatId?: string;
    isOpen: boolean;
    onClose: () => void;
    onSubmit: (value: number) => Promise<void>;
};
const ProductivityGainModal: React.FC<ProductivityGainModalProps> = ({ initialProductivityGain, chatId, isOpen, onClose, onSubmit }) => {

    const labels = useLabels();
    const l = useMemo(() => {
        return {
            productivityHl: labels.productivityHeadline,
            productivityDesc: labels.productivityDescription,
            productivityPlaceholder: labels.productivityPlaceholder,
            cancel: labels.cancel,
            submit: labels.submit,
        };
    }, [labels]);

    const [productivityGain, setProductivityGain] = useState('');
    useEffect(() => {
        if (isValidNumber(initialProductivityGain)) {
            const initialProductivityGainString = initialProductivityGain.toString();
            setProductivityGain(initialProductivityGainString);
        } else setProductivityGain('');
    }, [initialProductivityGain, chatId]);

    const isProductivityGainValid = useMemo(() => !!productivityGain && parseInt(productivityGain) > 0, [productivityGain]);
    const onSubmitProductivityGain = useCallback(async () => {
        if (!isProductivityGainValid) return;
        await onSubmit(parseInt(productivityGain));
    }, [isProductivityGainValid, onSubmit, productivityGain]);

    return <Modal
        isOpen={isOpen}
        headline={l.productivityHl}
        className={style['productivity-modal']}
        body={<>
            <p>{l.productivityDesc}</p>
            <Input type='number' placeholder={l.productivityPlaceholder} value={productivityGain} onChange={setProductivityGain} />
        </>}
        closeOnEscape
        closeOnBackdropClick
        footer={<>
            <Button label={l.cancel} onClick={onClose} theme={ButtonThemes.textPrimary} />
            <ButtonAsync label={l.submit} onClick={onSubmitProductivityGain} isDisabled={!isProductivityGainValid} />
        </>}
        size='small'
        onClose={onClose}
    />;
};

export default ProductivityGainModal;