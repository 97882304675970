import React, { useEffect, useState } from 'react';
import parse from 'html-react-parser';
import Alert from '../../ui/alert/Alert';
import useTerms from '../../hooks/useTerms';
import Loader from '../../ui/loader/Loader';

export type ChatDisclaimerProps = {
    className?: string;
    onDisclaimerIsRead?: (isRead: true) => void;
};

const ChatDisclaimer: React.FC<ChatDisclaimerProps> = ({ className, onDisclaimerIsRead, }) => {
    const [isDisclaimerRead, setIsDisclaimerRead] = useState<boolean>(false);
    const { terms, isLoading, error } = useTerms();

    const containerRef = React.useRef<HTMLDivElement>(null);
    useEffect(() => {
        let cleanup = () => { };

        if (error) return markDisclaimerAsRead();
        if (isLoading || !terms) return;
        if (isDisclaimerRead) return;

        let interval: ReturnType<typeof setInterval>;
        const maxTries = 10;
        let tries = 0;

        interval = setInterval(() => {
            if (isDisclaimerRead || tries >= maxTries) {
                cleanup();
                clearInterval(interval);
                markDisclaimerAsRead();
                return;
            }
            tries++;

            const el = containerRef.current;
            if (!el) return;

            clearInterval(interval);
            init();
        }, 100);
        cleanup = () => clearInterval(interval);

        function init() {
            const el = containerRef.current;
            if (!el || isDisclaimerRead) return;

            const nearestScrollableParent = el.closest<HTMLDivElement>('.df-modal-body');

            if (!nearestScrollableParent) {
                markDisclaimerAsRead();
                return;
            }

            nearestScrollableParent.style.position = 'relative';

            const handleEvent = () => {
                const buffer = 10;
                const parentScrollBottom = nearestScrollableParent.scrollTop + nearestScrollableParent.clientHeight + buffer;
                const disclaimerBottom = el.offsetTop + el.clientHeight;
                if (parentScrollBottom >= disclaimerBottom) markDisclaimerAsRead();
            };

            handleEvent();

            window.addEventListener('resize', handleEvent);
            window.addEventListener('scroll', handleEvent);
            nearestScrollableParent.addEventListener('scroll', handleEvent);

            cleanup = () => {
                window.removeEventListener('resize', handleEvent);
                window.removeEventListener('scroll', handleEvent);
                nearestScrollableParent.removeEventListener('scroll', handleEvent);
            };
        }

        function markDisclaimerAsRead() {
            setIsDisclaimerRead(true);
            onDisclaimerIsRead?.(true);
            cleanup();
        }

        return cleanup;
    }, [error, isDisclaimerRead, isLoading, onDisclaimerIsRead, terms]);

    return (
        <div ref={containerRef} className={className}>
            <Loader isVisible={isLoading} />

            {terms && <Alert type='warning'>
                <div>
                    {parse(terms.text)}
                </div>
            </Alert>}

            {error && <Alert headline={error.message} type='error' />}
        </div>
    );
};

export default ChatDisclaimer;