import { RefObject, useCallback, useEffect } from 'react';



const useInputFocus = (element: RefObject<HTMLTextAreaElement | HTMLInputElement>, deps?: React.DependencyList) => {
    const focusInputElement = useCallback(() => {
        const el = element?.current;
        if (!el) return;
        el.focus();
        el.selectionStart = el.value.length;
        el.scrollTop = el.scrollHeight;
    }, [element]);

    useEffect(() => {
        focusInputElement();
        // eslint-disable-next-line 
    }, [element, focusInputElement, ...(deps || [])]);
    return { focusInputElement };
};

export default useInputFocus;