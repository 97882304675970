import { ImageComponent } from "./Image.types";
import Base from "./variation/Base";
import Zoom from "./variation/Zoom";
import Slider from "./variation/Slider";
import Custom from "./variation/Custom";
import Modal from "./variation/Modal";

const Image: ImageComponent = () => { };
Image.Base = Base;
Image.Zoom = Zoom;
Image.Slider = Slider;
Image.Modal = Modal;
Image.Custom = Custom;

export { Image };