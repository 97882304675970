export enum TestIds {
    // ChatInputOption
    advancedOptionsModalIcon = 'advanced-options-modal',
    advancedOptionsModal = 'advanced-options-modal',
    dfSelectOption = 'df-select-option',
    dfSelect = 'df-select',

    // ChatInput
    chatInput = 'chat-input',
    chatInputContainer = 'chat-input-container',
    sendButton = 'send-button',

    // CharWindow | NewChatWindow
    chatWindow = 'chat-window',

    // ChatWindowHeader
    inputOverlay = 'input-overlay',

    // LoadingIcon | ChatWindowHeader | 
    loadingIcon = 'loading-icon',

    // Accordion 
    accordionBase = 'accordion',

    // ChatView
    chatViewContent = 'chat-view-content',

    //
    pdfViewer = 'pdf-viewer',
    pdfViewerLoader = 'pdf-viewer-loader',
    pdfError = 'pdf-error',

    // useActions
    dataSidePanel = 'data-side-panel',

}