import useStorage from '../../hooks/useStorage';
import { useCallback } from 'react';
import { ChatInputModel } from '../../elements/chat-input';
import { LlmUIOption, PersonaOption } from '../../models/types';

const PERSISTENT_NEW_PROMPTS_SCOPE = 'session-prompt';

const usePersistentInputState = () => {
    const { get, set, remove } = useStorage(PERSISTENT_NEW_PROMPTS_SCOPE, 'local', 'json');

    const handleChangeInput = useCallback((sessionId: string, model: ChatInputModel) => model && set(sessionId, model), [set]);

    const handleGetInput = useCallback((sessionId: string, defaultModel?: ChatInputModel) => ({ ...(defaultModel ?? {}), ...(get(sessionId) as ChatInputModel) }), [get]);

    const handleRemoveInput = useCallback((sessionId: string) => remove(sessionId), [remove]);

    const handleMergeInput = useCallback((sessionId: string, model: Partial<ChatInputModel>) => handleChangeInput(sessionId, {...(handleGetInput(sessionId) ?? {}), ...model}), [handleGetInput, handleChangeInput]);


    return {
        getInput: handleGetInput,
        setInput: handleChangeInput,
        mergeInput: handleMergeInput,
        removeInput: handleRemoveInput
    };
};

export default usePersistentInputState;


export const validateInputModelSelection = (model: ChatInputModel, llmOptions: LlmUIOption[], personaOptions: PersonaOption[]) => {
    const isValidLlmSelection = !model.llm || llmOptions.some(x => x.value === model.llm)
    const isValidPersonaSelection = !model.persona || personaOptions.some(x => x.key === model.persona)
    const isValidSelection= isValidLlmSelection && isValidPersonaSelection
    return {
        isValidLlmSelection, isValidPersonaSelection, isValidSelection
    }
}