import React, { useEffect, useMemo, useState, } from 'react';
import Modal from '../modal/Modal';
import { ButtonThemes, type ButtonProps } from '../button/Button';
import style from './Prompt.module.scss';
import ButtonAsync from '../button/ButtonAsync';
import { useMediaSize } from '../../hooks/useMediaSize';
import { MediaSize } from '../../constants/consts';
import { getClassNames } from '../../helpers/classHelpers';

type Props = {
    isOpen: boolean;
    headline: string;
    message?: string;
    primaryButton: Omit<ButtonProps, 'theme'>;
    secondaryButton?: Omit<ButtonProps, 'theme' | 'onClick'>;
    closeOnBackdropClick?: boolean;
    closeOnEscape?: boolean;
    onClose?: () => void;

    className?: string;
};

const Prompt: React.FC<Props> = ({ isOpen, headline, message, primaryButton, secondaryButton, closeOnBackdropClick, closeOnEscape, onClose, className, }) => {
    const [isMounted, setIsMounted] = useState(false);
    useEffect(() => setIsMounted(true), []);

    const isMobile = useMediaSize(ms => ms <= MediaSize.xss);

    const renderFooter = useMemo(() => {
        return <div className={style.actions}>
            {onClose && <ButtonAsync
                fullWidth={isMobile}
                {...secondaryButton}
                theme={ButtonThemes.textPrimary}
                onClick={onClose}
            />}
            <ButtonAsync fullWidth={isMobile} {...primaryButton} />
        </div>;
    }, [onClose, primaryButton, secondaryButton, isMobile]);

    if (!isMounted) return null;
    return (
        <Modal
            size='small'
            className={getClassNames([className, style.prompt])}
            isOpen={isOpen}
            headline={headline}
            body={message}
            footer={renderFooter}
            hideCloseIcon
            closeOnBackdropClick={closeOnBackdropClick}
            closeOnEscape={closeOnEscape}
            onClose={onClose}
        />
    );
};

export default Prompt;