import useEffectAsync from '../../hooks/useEffectAsync';
import useLabels from '../../hooks/useLabels';
import { NewChatButton } from '../../views/chat-view/ChatView';
import { AppRoute } from '../../router/Routing';
import { useMemo, useRef, useState } from 'react';
import { useChat } from '../../contexts/chat/ChatContext';
import { useNavigate } from 'react-router-dom';
import { GetChatResponse } from '../../models/ChatTypes';
import { ErrorHandler } from '../../contexts/error-handler/ErrorHandler';
import { useErrorHandlerContext } from '../../contexts/error-handler/ErrorContext';

type Props = {
    active?: boolean;
    chatId?: string;
};

const useLoadChatMessages = ({ active = true, chatId }: Props) => {
    const labels = useLabels();
    const navigate = useNavigate();
    const { getChat } = useChat();

    // Prevent unnecessary load in case url changes but chatId stays the same
    const charIdRef = useRef<string>();

    const [chat, setChat] = useState<GetChatResponse>();
    const [isLoadingChatHistory, setIsLoadingChatHistory] = useState(true);
    const { errorId, registerError, removeError, getError } = useErrorHandlerContext();
    const error = getError(errorId);

    useEffectAsync(token => () => {
        if (!chatId || !active || chatId === charIdRef.current) return;

        (async () => {
            removeError(errorId);

            try {
                setIsLoadingChatHistory(true);
                const chat = await getChat(chatId);

                if (token.cancelled) return;
                charIdRef.current = chatId;
                setChat(chat);
            } catch (err: any) {
                if (token.cancelled) return;

                registerError({
                    [errorId]: {
                        type: 'modal',
                        headline: labels.historyErrorHeadline,
                        notification: {
                            headline: labels.historyErrorOccurredWhileLoading,
                            description: labels.accessBlockedAlertHeadline,
                            details: err
                        },
                        onClose: () => navigate(AppRoute.chat)
                    }
                });
            } finally {
                setIsLoadingChatHistory(false);
            }
        })();
    }, [labels, chatId, active, errorId, removeError, getChat, navigate]);

    const renderChatHistoryError = useMemo(() => <ErrorHandler.Modal id={errorId} footer={<NewChatButton />} />, [errorId]);

    return { chat, isLoadingChatHistory, chatHistoryError: error, renderChatHistoryError };
};

export default useLoadChatMessages;