
import { PureComponent } from 'react';
import { MessageStreamProcessor, ChatMessageErrorCode, wrapAsMarkdownError, IMessageStreamHandler, StructuredContentErrorHandler, StructuredContentHandlers } from '../../helpers/messageStreamHelper';
import { MessageDisplayProps, MessageUpdater, ChatMessageStreamingResponse } from '../chat-window/models';
import Message from './Message';


const textDecoder = new TextDecoder(); // Using default "utf-8"

type StreamingMessageProps = MessageDisplayProps & { updateMessageById: MessageUpdater; scrollToBottom?: () => void; };
export class StreamingMessage extends PureComponent<StreamingMessageProps, ChatMessageStreamingResponse> implements IMessageStreamHandler {
    id: string;
    response: Response;
    abortResponse: () => void;

    processor: MessageStreamProcessor;
    constructor(props: StreamingMessageProps) {
        super(props);
        const { message } = props;

        const stream = message.stream!;
        this.response = stream.response;
        this.abortResponse = message.stream!.abortResponse;

        this.id = message.id;
        this.state = message;

        this.processor = new MessageStreamProcessor(this);
    }

    onContent = (content: string) => this.setState(s => ({ ...s, content: s.content + content }));

    structuredContentHandlers: StructuredContentHandlers = {
        SystemMessage: (model) => this.setState({ generationStatus: model.state, trace_id: model.traceId }),
        ResponseContext: (context) => this.setState({ context }),
        EnquiryAnalysis: (enquiry) => this.setState({ enquiry })
    };

    structuredContentPraseErrorHandlers: StructuredContentErrorHandler = (error, parseResult) => console.error('Structured content parse error', { error, parseResult });

    streamDebounce?: NodeJS.Timeout;
    isStreaming?: boolean;
    stream = async () => {
        this.isStreaming = true;

        const response = this.response;
        const body = response.body!;
        if (response.bodyUsed || body.locked)
            return;

        const reader = body!.getReader();
        let isDone = false;

        while (!isDone) {
            try {
                const { done, value } = await reader.read();
                isDone = done;

                const chunk = value ? textDecoder.decode(value) : "";
                this.processor.processChunk(chunk);
            }
            catch (e) {
                isDone = true;
                let errorCode = ChatMessageErrorCode.Unknown;
                let errorMessage = "";
                // 1) Handle abort scenario. 
                // When we abort, we do that on the fetch response itself. 
                // Based on fetch documentation, when response is aborted, a DomException is thrown
                // https://developer.mozilla.org/en-US/docs/Web/API/fetch#exceptions
                if (e instanceof DOMException && e.code === e.ABORT_ERR) {
                    errorCode = ChatMessageErrorCode.Cancelled;
                }
                else if (e instanceof Error)
                    errorMessage = e.message;

                const error = wrapAsMarkdownError(errorMessage, errorCode);
                this.onContent(error);
            }
            finally {

            }
            this.props.scrollToBottom?.();
        }
        // Finally, push the state of the streaming message back to the state of message as seen by the parent.
        this.setState({ isStreaming: false }, () => {
            this.props.updateMessageById(this.id, this.state);
        });
    };

    componentDidMount = () => {
        // We start the streaming after a small delay. This is basically only needed to work around strict mode
        this.streamDebounce = setTimeout(this.stream, 200);
    };
    componentWillUnmount = () => {

        clearTimeout(this.streamDebounce);

        if (this.isStreaming)
            this.abortResponse();
    };

    render = () => {
        const originalMessage = this.props.message;
        const updatedMessage = this.state;
        return <Message {...this.props} message={{ ...originalMessage, ...updatedMessage }} />;
    };
}
