import React from 'react';
import Button from '../../ui/button/Button';
import style from './ChatDisclaimerModalFooter.module.scss';

type Props = {
    extra: React.ReactNode;
    button: {
        label: string;
        onClick: () => void;
        isDisabled: boolean;
    };
};

const ChatDisclaimerModalFooter: React.FC<Props> = ({ extra, button }) => {
    return (
        <div className={style.container}>
            <div>{extra}</div>
            <Button className={style.cta} isDisabled={button.isDisabled} onClick={button.onClick}>{button.label}</Button>
        </div>
    );
};

export default ChatDisclaimerModalFooter;