import React, { type ReactNode, Fragment, useCallback, useMemo, useState } from 'react';
import useLabels from '../../hooks/useLabels';
import AvatarIcon from '../avatar-icon/AvatarIcon';
import { Icon } from '../icon/Icon';
import { useAuth } from '../../contexts/auth/AuthContext';
import { DfContextMenu, DfMenuItem } from '@danfoss/mosaic-react';
import { getClassNames } from '../../helpers/classHelpers';
import { Link } from 'react-router-dom';
import { AppRoute } from '../../router/Routing';
import { wrapInOptionalLink } from '../../helpers/linkHelpers';
import { IconTheme } from '../icon/Icon.types';
import { Icons } from '../icon/icons/material';
import style from './Header.module.scss';

export type HeaderProps = {
    navItems?: NavItem[];
    userDropdownItems?: UserDropdownItem[];
    className?: string;
};

type UserDropdownItem = Omit<NavItem, 'className' | 'icon'>;
type NavItem = {
    label: string;
    url?: string;
    target?: string;
    icon?: ReactNode;
    /** Default: `true` */
    closeOnClick?: boolean;
    onClick?: (e: React.MouseEvent<HTMLAnchorElement>) => void;
    className?: string;
};

const Header: React.FC<HeaderProps> = ({ navItems, userDropdownItems, className }) => {
    const { user, logout } = useAuth();
    const labels = useLabels();

    const l = useMemo(() => {
        return {
            changeLang: labels.changeLanguage,
            lo: labels.logout,
        };
    }, [labels.changeLanguage, labels.logout]);

    const [isContextMenuOpen, setIsContextMenuOpen] = useState(false);

    const renderNavItem = useCallback(({ label, icon, onClick, url, className, target }: NavItem, i?: number) => {
        const inner = <>
            {icon}
            <span className={getClassNames([!!icon && 'df-hidden-xs', 'df-link-text'])}>{label}</span>
        </>;

        return <li slot='link' className={getClassNames([className])} key={i}>
            {wrapInOptionalLink(inner, url, { className: 'df-link-tertiary', onClick, target, })}
        </li>;
    }, []);

    const renderUserDropdownItem = useCallback(({ label, onClick, url, target, closeOnClick = true }: UserDropdownItem, i?: number) => {
        const onMenuItemClick = closeOnClick ? () => setIsContextMenuOpen(false) : undefined;
        const inner = <DfMenuItem onClick={onMenuItemClick} label={label} />;
        return <Fragment key={i}>{wrapInOptionalLink(inner, url, { className: 'df-link-tertiary', onClick, target, })}</Fragment>;
    }, []);

    const onUserClickHandler = useCallback(() => {
        setIsContextMenuOpen(true);
    }, []);

    const userIcon = useMemo(() => {
        if (!user) return null;
        const imageUrl = user.avatar;
        if (imageUrl) return <AvatarIcon borderWidth={1} borderColor='white' color='white' imageUrl={imageUrl} className='df-addon-start' />;
        return <Icon.Svg title='' size={16} borderSize={1} borderColor='white' fill='white' iconName={'User'} className='df-addon-start' />;
    }, [user]);

    return (
        <header className={getClassNames(['df-header', style.header, className])}>
            <div className='df-container df-header-container'>
                <div className='df-logo-container'>
                    <Link to={AppRoute.chat} className='df-logo' aria-label='Home page' >
                        <picture>
                            <source
                                srcSet='https://cdn.mosaic.danfoss.com/npm/mosaic-assets@latest/dist/logos/danfoss-mobile.svg'
                                media='(max-width: 599.5px)'
                            />
                            <img
                                src='https://cdn.mosaic.danfoss.com/npm/mosaic-assets@latest/dist/logos/danfoss.svg'
                                alt='Danfoss logo'
                            />
                        </picture>
                    </Link>
                </div>

                <nav className='header-nav-container' aria-label='header links'>
                    <ul className={style.list}>
                        {navItems?.map(renderNavItem)}

                        {user && <div className={'df-context-menu-container'}>
                            {renderNavItem({
                                label: user.name || user.username,
                                onClick: onUserClickHandler,
                                icon: user.avatar && userIcon,
                                className: getClassNames([style.user, 'df-context-menu-button']),
                            })}
                            <DfContextMenu
                                onOutsideClick={() => setIsContextMenuOpen(false)}
                                isRightAsXPosition
                                posY={38}
                                posX={-38}
                                isOpen={isContextMenuOpen}
                            >
                                {userDropdownItems?.map(renderUserDropdownItem)}
                                <DfMenuItem onClick={logout} className='df-color-error' label={l.lo} />
                            </DfContextMenu>
                        </div>}

                        {user && <Icon.Base
                            title={labels.navigateToInformationViewIconTitle}
                            iconName={Icons.menu}
                            theme={IconTheme.light}
                            onClick={onUserClickHandler}
                        />}
                    </ul>
                </nav>
            </div>
        </header>
    );
};

export default Header;