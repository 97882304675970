const queryStringToDictionary = (search: string) => {
    if (search) {
        search = search.trim();
        search = search.startsWith('?') ? search.substring(1) : search;
        search = search.startsWith('#') ? search.substring(1) : search;
    }

    let query: { [key: string]: string; } = {};
    try {
        search = (search || "").split('&').filter(x => !!x).join('&');
        query = !search ? {} : JSON.parse('{"' + decodeURI(search || "").replace(/"/g, '\\"').replace(/&/g, '","').replace(/=/g, '":"') + '"}');
    }
    catch (e) {
        console.error("Unable to parse query string into key value pairs", e);
    }
    return query;
};

type DictionaryToQueryStringArgs = { dict: Record<string, any>, encodeValues?: boolean, excludeEmpty?: boolean, includeQueryDelimiter?: boolean, delimiter?: string; };
const dictionaryToQueryString = ({ dict, encodeValues = false, excludeEmpty = false, includeQueryDelimiter = true, delimiter = "?" }: DictionaryToQueryStringArgs) => {
    let query = "";
    if (!dict) return query;
    const keys = Object.keys(dict);

    if (includeQueryDelimiter && keys.length > 0) query += delimiter;

    let params = keys.map(key => {
        let value = dict[key];

        if (excludeEmpty && !value) return undefined;
        if (encodeValues) value = encodeURIComponent(value ?? "");

        return key + '=' + value;
    });

    if (excludeEmpty) params = params.filter(x => !!x);

    query += params.join('&');

    return query;
};


// export all functions: 
export {
    queryStringToDictionary,
    dictionaryToQueryString,
};