import { useLayoutEffect, useRef } from 'react';
import { useLocation } from 'react-router-dom';
import { queryStringToDictionary } from '../../../helpers/routeHelpers';
import { TARGET_ANCHOR_KEY } from '../config';
import style from '../Information.module.scss';


export const useInitAnchorScrollTo = () => {
    const location = useLocation();

    const init = useRef(false);
    useLayoutEffect(() => {
        // if (init.current) return;

        setTimeout(() => {
            const hashString = queryStringToDictionary(location.hash);
            const id = hashString[TARGET_ANCHOR_KEY];
            if (!id) return;

            const element = document.getElementById(id);
            element?.classList.add(style['highlighted']);
            init.current = true;

            if (!element) return;
            element.scrollIntoView({
                behavior: 'smooth',
                block: 'start',
                inline: 'nearest'
            });
        }, 100);

        return () => {
            const element = document.querySelector(`.${style['highlighted']}`);
            element?.classList.remove(style['highlighted']);
        };
    }, [location]);
};
