import { useEffect } from 'react';
import { BrowserCookieInformationService } from '../services/BrowserCookieInformationService';

export default function useAnalytics() {
    // const onFunctionalConsentGiven = useCallback(() => {
    //     DynatraceService.initialize();
    // }, []);
    // const onFunctionalConsentRemoved = useCallback(() => {
    //     console.warn('Consent not given for cookies.');
    // }, []);

    useEffect(() => {
        BrowserCookieInformationService.initialize(async () => 'en');
    }, []);
}