import { useCallback, useMemo } from 'react';

export default function useStorage(scope?: string, storeType: 'local' | 'session' = 'local', valueType: 'json' | 'string' = 'json') {
    const storage = useMemo(() => storeType === 'local' ? localStorage : sessionStorage, [storeType]);
    const getKeyWithScope = useCallback((key: string) => [scope, key].filter(Boolean).join(':'), [scope]);

    const get = useCallback((k: string) => {
        const key = getKeyWithScope(k);
        const item = storage.getItem(key);

        if (valueType === 'json') 
            return item ? JSON.parse(item) : null;
        else 
            return item as string

    }, [getKeyWithScope, storage, valueType]);

    const set = useCallback((k: string, value: any) => {
        const key = getKeyWithScope(k);

        const storeValue = valueType === 'json' ? value ? JSON.stringify(value) : null : value;

        storage.setItem(key, storeValue as string);
    }, [getKeyWithScope, storage, valueType]);
    const remove = useCallback((k: string) => {
        const key = getKeyWithScope(k);
        storage.removeItem(key);
    }, [getKeyWithScope, storage]);

    return {
        get,
        set,
        remove,
    };
}