import React, { useMemo } from 'react';
import PageLayout from '../../layouts/page-layout/PageLayout';
import Button from '../../ui/button/Button';
import useLabels from '../../hooks/useLabels';
import Hero from '../../ui/hero/Hero';
import { useAuth } from '../../contexts/auth/AuthContext';

type Props = {};

const UnauthenticatedRootView: React.FC<Props> = () => {
    const labels = useLabels();
    const { login } = useAuth();

    const l = useMemo(() => {
        return {
            hl: labels.welcome,
            desc: labels.welcomeDescription,
            login: labels.login,
        };
    }, [labels.login, labels.welcome, labels.welcomeDescription]);

    return (
        <PageLayout>
            <Hero
                mainContent={<>
                    <h1>{l.hl}</h1>
                    <p>{l.desc}</p>
                </>}
                sideContent={<Button onClick={login}>{l.login}</Button>}
            />
        </PageLayout>
    );
};

export default UnauthenticatedRootView;