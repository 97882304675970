import { type CustomSelectOptionType } from '@danfoss/mosaic-core';
import { maxSubjectLength } from '../constants/consts';
import { isString } from './typeHelpers';

export const limitSubjectLength = (subject: string) => {
    let trimmedSubject = subject.trim();
    if (trimmedSubject.length > maxSubjectLength) trimmedSubject = trimmedSubject.substring(0, maxSubjectLength) + '...';
    return trimmedSubject;
};

export const getOptionValue = (option?: CustomSelectOptionType): string | number | undefined => {
    return isString(option) ? option : option?.value;
};
export const getOptionLabel = (option?: CustomSelectOptionType): string | number | undefined => {
    return isString(option) ? option : option?.label;
};
