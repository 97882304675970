import { useMemo } from 'react';
import labels, { inputValidationLabels, chatMessageErrorLabels, countries, languages } from '../data/labels';

export default function useLabels() {
    return labels;
}

export function useCountries() {
    return countries;
}

export function useLanguages() {
    return countries;
}

export const useLov = () => {
    const lov = useMemo(() => ({
        country: countries,
        language: languages
    }), [])
    return lov
}
export function useInputValidationLabels() {
    return inputValidationLabels;
}
export function useChatMessageErrorLabels() {
    return chatMessageErrorLabels;
}