import React, { type PropsWithChildren, } from 'react';
import MsalAuthContextProvider from '../auth/MsalAuthContextProvider';
import ChatServiceProvider from '../chat/ChatServiceContext';
import useAnalytics from '../../hooks/useAnalytics';
import AppInfoServiceContext from '../app-info/AppInfoServiceContext';

const AppContextBootstrap: React.FC<PropsWithChildren> = ({ children }) => {

    useAnalytics();

    return (
        <MsalAuthContextProvider>
            <AppInfoServiceContext>
                <ChatServiceProvider>
                    {children}
                </ChatServiceProvider>
            </AppInfoServiceContext>
        </MsalAuthContextProvider>
    );
};

export default AppContextBootstrap;