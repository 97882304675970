import React, { type PropsWithChildren, useCallback, useEffect, useMemo, useState } from 'react';
import { useMsal, useMsalAuthentication } from '@azure/msal-react';
import { InteractionRequiredAuthError, InteractionStatus, InteractionType } from '@azure/msal-browser';
import AuthContext, { type IAuthService, TokenState } from './AuthContext';
import { getApiAccessToken, getDefaultAccessToken, getPhoto, loginRequest, loginUser, logoutUser } from '../../services/MsalAuthService';



const MsalInnerAuthContextProvider: React.FC<PropsWithChildren> = ({ children }) => {
    const { instance, accounts, inProgress } = useMsal();
    useMsalAuthentication(InteractionType.Silent, loginRequest);

    const account = useMemo(() => {
        if (accounts.length === 0) return undefined;
        return accounts[0];
    }, [accounts]);

    const login = useCallback(() => loginUser(instance), [instance]);
    const logout = useCallback(() => logoutUser(instance, account), [instance, account]);

    const [tokenState, setTokenState] = useState<TokenState | undefined>(TokenState.Valid);
    const getAccessToken = useCallback(() => {
        const p = getApiAccessToken();

        p
            .then(() => setTokenState(TokenState.Valid))
            .catch((e: any) => {
                // Error types: https://learn.microsoft.com/en-us/entra/identity-platform/msal-error-handling-js#error-types
                if (e instanceof InteractionRequiredAuthError) setTokenState(TokenState.RequiresInteraction);
                else setTokenState(TokenState.Invalid);
            });

        return p;
    }, []);

    const isStartingUp = useMemo(() => inProgress === InteractionStatus.Startup, [inProgress]);
    const isLoggingOut = useMemo(() => inProgress === InteractionStatus.Logout, [inProgress]);
    const isLoggingIn = useMemo(() => {
        return [
            InteractionStatus.SsoSilent,
            InteractionStatus.Login,
            InteractionStatus.HandleRedirect,
        ].includes(inProgress);
    }, [inProgress]);

    const [avatar, setAvatar] = useState<string | undefined>(undefined);
    useEffect(() => {
        (async () => {
            const photo = await getPhoto(account, getDefaultAccessToken);
            setAvatar(photo);
        })();
    }, [account]);

    const user = useMemo(() => {
        if (!account) return undefined;
        return {
            ...account,
            avatar,
        };
    }, [account, avatar]);

    const service = useMemo<IAuthService>(() => {
        return {
            login,
            logout,
            getAccessToken,
            user,
            tokenState,
            isStartingUp,
            isLoggingIn,
            isLoggingOut,
        };
    }, [login, logout, user, tokenState, getAccessToken, isStartingUp, isLoggingIn, isLoggingOut]);

    return <AuthContext service={service}>
        {children}
    </AuthContext>;
};

export default MsalInnerAuthContextProvider;