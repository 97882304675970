import { isString } from "./typeHelpers";

export function copyToClipboard(text?: string): Promise<void> {
    if (!text || !isString(text)) return Promise.reject(new Error("Invalid input"));

    return new Promise(async (resolve, r) => {
        const reject = () => r(new Error("Your browser does not support copying to clipboard"));

        try {
            if ('clipboard' in navigator) {
                await navigator.clipboard.writeText(text);
                resolve();
            }
            else {
                const temp = document.createElement("textarea");

                temp.style.pointerEvents = "none";
                temp.style.opacity = "0";

                document.body.appendChild(temp);

                temp.value = text;
                temp.select();
                const didCopy = document.execCommand("copy");

                document.body.removeChild(temp);

                didCopy ? resolve() : reject();
            }
        } catch (error) {
            reject();
        }
    });
}

export const equals = (t1?: string, t2?: string, ignoreCase = true) => {

    if (t1 === t2) return true;

    if (!t1 || !t2) return false;

    if (!ignoreCase) return false;

    return t1.toLowerCase() === t2.toLowerCase();
};


export const trimChar = (string: string, charsToRemove: string[] | string, start = true, end = true) => {
    if (!string) return string;
    while (start && charsToRemove.includes(string.charAt(0))) {
        string = string.substring(1);
    }

    while (end && charsToRemove.includes(string.charAt(string.length - 1))) {
        string = string.substring(0, string.length - 1);
    }

    return string;
};