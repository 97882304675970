import React, { type PropsWithChildren } from 'react';
import { MsalProvider } from '@azure/msal-react';
import MsalInnerAuthContextProvider from './MsalInnerAuthProvider';
import { msalInstance } from '../../services/MsalAuthService';


const MsalAuthContextProvider: React.FC<PropsWithChildren> = ({ children }) => {
    return <MsalProvider instance={msalInstance} >
        <MsalInnerAuthContextProvider>
            {children}
        </MsalInnerAuthContextProvider>
    </MsalProvider >;
};

export default MsalAuthContextProvider;