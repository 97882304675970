import { useCallback } from 'react';
import { TokenState, useAuth } from '../contexts/auth/AuthContext';
import Button from '../ui/button/Button';
import useLabels from './useLabels';

export default function useAuthenticationStateErrorContent() {
    const labels = useLabels();
    const { login, logout, tokenState } = useAuth();

    const renderButton = useCallback((label: string, onClick: () => void) => {
        return <Button label={label} onClick={onClick} />;
    }, []);

    switch (tokenState) {
        case TokenState.RequiresInteraction: return {
            headline: labels.tokenExpiredHeadline,
            description: labels.tokenExpiredDescription,
            button: renderButton(labels.login, login),
        };
        case TokenState.Invalid:
        default: return {
            headline: labels.invalidTokenHeadline,
            description: labels.invalidTokenDescription,
            button: renderButton(labels.logout, logout),
        };
    }
}