import Base from './Base';
import Button, { ButtonThemes } from '../../button/Button';
import { Icon } from '../../icon/Icon';
import { Swiper, SwiperRef, SwiperSlide } from 'swiper/react';
import { Zoom, Thumbs, Navigation, Mousewheel } from 'swiper/modules';
import { CustomType } from '../Image.types';
import { IconTheme } from '../../icon/Icon.types';
import { getClassNames } from '../../../helpers/classHelpers';
import { useCallback, useRef, useState } from 'react';
import { Icons } from '../../icon/icons/material';
import styles from '../Image.module.scss';

import 'swiper/css';
import 'swiper/css/zoom';
import 'swiper/css/mousewheel';
import "swiper/css/pagination";
import "swiper/css/navigation";

const MAX_ZOOM = 5;
const MIN_ZOOM = 1;

export default function ImageCustom({ className, enableThumbs, enableMousewheel, enableZoom, images, onClick, onExpand }: CustomType) {
    const ref = useRef<SwiperRef>(null);
    const [zoom, setZoom] = useState(MIN_ZOOM);

    // React on zoom scale change
    const updateSwiperZoom = useCallback((zoom: number) => {
        if (!ref.current) return;
        ref.current.swiper.zoom.in(zoom);
    }, []);

    const handleManualZoom = useCallback((s: number) => setZoom(s), []);

    const zoomIn = useCallback(() => setZoom(zoom => {
        const $zoom = zoom < MAX_ZOOM ? zoom + 1 : zoom;
        updateSwiperZoom($zoom);
        return $zoom;
    }), [updateSwiperZoom]);

    const zoomOut = useCallback(() => setZoom(zoom => {
        const $zoom = zoom > MIN_ZOOM ? zoom - 1 : zoom;
        updateSwiperZoom($zoom);
        return $zoom;
    }), [updateSwiperZoom]);

    const zoomOutAll = useCallback(() => {
        setZoom(MIN_ZOOM);
        updateSwiperZoom(MIN_ZOOM);
    }, [updateSwiperZoom]);

    const modules = [];
    const zoomMods = [Zoom];
    const thumbsMods = [Thumbs, Navigation, Mousewheel];
    if (enableZoom) modules.push(...zoomMods);
    if (enableThumbs) modules.push(...thumbsMods);

    const renderSwiper = <div className={getClassNames([styles.slider, className])}>
        <Swiper
            ref={ref}
            onZoomChange={(_: any, s: number) => handleManualZoom(s)}
            tabIndex={-1}
            mousewheel={{
                enabled: enableMousewheel
            }}
            zoom={{
                maxRatio: MAX_ZOOM,
                minRatio: MIN_ZOOM,
            }}
            modules={modules}
            className={getClassNames(['swiper-thumbs', '_center-flex'])}
            navigation={{ prevEl: '.swiper-thumb-prev', nextEl: '.swiper-thumb-next', }}
        >
            {images.map((img, i) => <SwiperSlide key={i} >
                <div className="swiper-zoom-container">
                    <Base
                        className={getClassNames([img.className, styles['custom-image']])}
                        {...img}
                        showLoadingWhenFetching
                        onClick={onClick}
                        onExpand={onExpand}
                    />
                </div>
            </SwiperSlide>)}
        </Swiper>

        {enableThumbs && images.length > 1 && <>
            <Button label={<Icon.Base theme={IconTheme.inherit} iconName={Icons.chevronRight} title={''} /> as any} className="swiper-thumb-prev swiper-thumb-navigation" theme={ButtonThemes.textSecondary} />
            <Button label={<Icon.Base theme={IconTheme.inherit} iconName={Icons.chevronRight} title={''} /> as any} className="swiper-thumb-next swiper-thumb-navigation" theme={ButtonThemes.textSecondary} />
        </>}

        {enableZoom && <div className={styles.actions}>
            <Icon.Base isDisabled={zoom === MAX_ZOOM} onClick={zoomIn} theme={IconTheme.inherit} iconName={Icons.zoomIn} title='' />
            <Icon.Base isDisabled={zoom === MIN_ZOOM} onClick={zoomOut} theme={IconTheme.inherit} iconName={Icons.zoomOut} title='' />
            <Icon.Base isDisabled={zoom === MIN_ZOOM} onClick={zoomOutAll} theme={IconTheme.inherit} iconName={Icons.zoomOutAll} title='' />
        </div>}
    </div>;

    return renderSwiper;
};