import { mockGetInfoResponse, mockGetTermsResponse } from '../data/mockData';
import EnvConfigHelper from '../helpers/envConfigHelper';
import type { IAppInfoService } from './IAppInfoService';

const delay = <R>(response: R) => new Promise<R>((resolve) => setTimeout(() => resolve(response), 500));

class MockAppInfoService implements IAppInfoService {
    baseURL = EnvConfigHelper.get('api-base-url', '');

    constructor(public getAccessToken: () => Promise<string | undefined>) { }

    getInfo = async () => {
        this.getAccessToken();
        return delay(mockGetInfoResponse);
    };

    getTerms = async () => {
        return delay(mockGetTermsResponse);
    };
}

export default MockAppInfoService;