import { isValidNumber } from "./numberHelpers";
import { SubType } from "./types";
import { alphaNumCompare } from "./comparisonHelpers";
import { isArray, isString } from "./typeHelpers";

export const ensureArrayCopy = <ItemType>(target: ItemType[]) => (Array.isArray(target) ? target : []).slice(0);

/**Returns the provided array after sorting its item based on the selected prop*/
export const sortBy = <ItemType, PropertyName extends keyof ItemType>(data: ItemType[], sortByProp: PropertyName | (PropertyName | { prop: PropertyName, desc?: boolean; })[], desc = false) => {
    if (!data || !sortByProp || !isArray(data)) return data;

    const props = ((isString(sortByProp) ? [sortByProp] : sortByProp) as { prop: PropertyName, desc?: boolean; }[]).map(c => {
        if (isString(c)) c = { prop: c } as any;
        if (c.desc === undefined) c.desc = desc;
        return c;
    });

    return data.sort((a: ItemType, b: ItemType): number => {

        for (let i = 0; i < props.length; i++) {
            const c = props[i];

            const p = c.prop;
            const d = c.desc;

            const r = alphaNumCompare(a[p], b[p]);

            if (r) return d ? -r : r;
        }

        return 0;
    });
};


/**Returns a new array with items sorted based on the selected prop */
export const sortItemsBy = <ItemType, PropertyName extends keyof ItemType>(data: ItemType[], sortByProp: PropertyName | (PropertyName | { prop: PropertyName, desc?: boolean; })[], desc = false) => {
    if (!data || !sortByProp || !isArray(data)) return data;
    return sortBy([...data], sortByProp, desc);
};

/**Returns the provided array after sorting its item based on the value returned by the predicate*/
export const sortWith = <ItemType>(data: ItemType[], predicate: (item: ItemType) => any, descending = false) => {
    if (!data || !predicate || !isArray(data)) return data;
    return data.sort((a: ItemType, b: ItemType): number => {

        const r = alphaNumCompare(predicate(a), predicate(b));
        if (!r) return 0;
        return descending ? -r : r;
    });
};
/**Returns a new array with items sorted based on the the value returned by the predicate*/
export const sortItemsWith = <ItemType>(data: ItemType[], predicate: (item: ItemType) => any, descending = false) => {
    if (!data || !predicate || !isArray(data)) return data;
    return sortWith([...data], predicate, descending);
};


/**Return an array that contains distinct values that exist in all provided arrays */
export const intersect = <T>(...lists: T[][]) => {
    const sortedLists = (lists.filter(x => !!x) as T[][]).sort((a, b) => a.length < b.length ? 1 : a.length > b.length ? -1 : 0);
    if (sortedLists.length === 0) return [];
    if (sortedLists.filter(x => x.length === 0).length > 0) return [];

    let result: T[] = [...sortedLists[0]];
    for (let li = 1; li < sortedLists.length; li++) {
        result = result.filter(item => sortedLists[li].indexOf(item) > -1);
        if (result.length === 0) break;
    }
    return distinct(result);
};

/** Distinct values in array of basic types. Ex: string[], number[] */
export const distinct = <ItemType>(target: ItemType[]): ItemType[] => {
    if (!target) return [];

    const res: ItemType[] = [];
    target.forEach(i => addDistinct(i, res));
    return res;
};

/** Distinct values in array of complex type based on a property of a basic type. */
export const distinctBy = <ItemType, PropertyName extends keyof ItemType>(target: ItemType[], property: PropertyName): ItemType[] => {
    if (!target) return [];
    const propValues = target.map(item => item[property]);
    const distinctPropValues = distinct(propValues);
    return distinctPropValues.map(val => target.find(item => item[property] === val)) as ItemType[];
};


/** Add item to array if it does not already exist  */
export const addDistinct = <ItemType>(value: ItemType, target: ItemType[]) => {
    if (!value) return;
    if (target.indexOf(value) > -1) return;
    target.push(value);
};

// export const distinctAndSortBy = <ItemType, PropertyName extends keyof ItemType>(target: ItemType[], distinctProperty: PropertyName, sortProperty: PropertyName): ItemType[] => {
//     return sortItemsBy(distinctBy(target, distinctProperty), sortProperty);
// };


// ----------- BELOW: old (installer app/reftools way of handling this)
// export const groupBy = <ItemType, PropertyName extends keyof SubType<ItemType, string | number | boolean | undefined>>(target: ItemType[], property: PropertyName) => {
//     return groupByMany(target, [property]).map(g => ({ group: g.group[property], items: g.items }));
// };

// export const groupByMany = <ItemType, Properties extends (keyof SubType<ItemType, string | number | boolean | undefined>)[], GroupType = Pick<ItemType, Properties[number]>>(target: ItemType[], properties: Properties) => {
//     const result: { group: GroupType, items: ItemType[]; }[] = [];

//     if (!target || !properties) return result;

//     target.forEach(x => {
//         if (!x) return;
//         const group: GroupType = {} as any;
//         properties.forEach(property => (group as any)[property] = x[property]);

//         let thisGroup = result.find(other => properties.every(property => (group as any)[property] === (other.group as any)[property]));

//         if (thisGroup) {
//             thisGroup.items.push(x);
//             return;
//         }

//         thisGroup = { group, items: [x] };
//         result.push(thisGroup);

//     });
//     return result;
// };
// ----------- ABOVE: old (installer app/reftools way of handling this). Below is new monorepo way of handling this

export const groupBy = <ItemType, PropertyName extends keyof SubType<ItemType, string | number | boolean | undefined>>(target: ItemType[], property: PropertyName) => {
    return groupByMany(target, [property]).map((g => ({ group: g.group[property], items: g.items })));
};

export const groupByMany = <ItemType, Properties extends (keyof SubType<ItemType, string | number | boolean | undefined>)[], GroupType = Pick<ItemType, Properties[number]>>(target: ItemType[], properties: Properties) => {
    const predicate = (item: ItemType) => {
        const group: GroupType = {} as any;
        properties.forEach(property => (group as any)[property] = item[property]);
        return group;
    };
    return groupUsing(target, predicate as any) as {
        group: GroupType;
        items: ItemType[];
    }[];
};

export const groupUsing = <ItemType, GroupType extends { [key: string]: any; }>(target: ItemType[], predicate?: (item: ItemType) => GroupType) => {
    const result: { group: GroupType, items: ItemType[]; }[] = [];

    if (!target || !predicate) return result;

    target.forEach(x => {
        if (!x) return;

        const group = predicate(x);
        const groupKeys = Object.keys(group);
        let thisGroup = result.find(other => groupKeys.every(groupKey => (group as any)[groupKey] === (other.group as any)[groupKey]));

        if (thisGroup) {
            thisGroup.items.push(x);
            return;
        }

        thisGroup = { group, items: [x] };
        result.push(thisGroup);

    });
    return result;
};


type SearchProps<T> = (keyof SubType<T, string | undefined>)[] | "*";
type SearchConfig<T> = {
    properties?: SearchProps<T>,
    values?: ((item: T) => string | undefined)[];
};
type SearchValueSelectors<T> = SearchProps<T> | SearchConfig<T>;

/**Returns items that contains any properties of type string that match the keyword.
 * @param keyword Limited to 1000 chars
 */
export const searchBy = <T>(target: T[], keyword: string, selectors: SearchValueSelectors<T>) => {
    return searchByString(target, keyword, false, selectors);
};

/**Returns items that contains any properties of type string that match the keyword (fuzzy match).
* @param keyword Limited to 1000 chars
 */
export const fuzzySearchBy = <T>(target: T[], keyword: string, selectors: SearchValueSelectors<T>) => {
    return searchByString(target, keyword, true, selectors);
};

/**Returns items that contains any properties of type string that match the keyword (fuzzy match).
* @param keyword Limited to 1000 chars
 */
export const searchByString = <T>(target: T[], keyword: string, fuzzyMatch: boolean, selectors: SearchValueSelectors<T>) => {
    target = ensureArrayCopy(target);
    if (!selectors) return target;

    keyword = keyword?.trim().substring(0, 1000);

    if (!keyword) return target;

    if (isArray(selectors)) selectors = { properties: selectors } as SearchConfig<T>;

    let { properties = "*" } = selectors as SearchConfig<T>;
    const { values = [] } = selectors as SearchConfig<T>;
    if (!properties.length && values.length) return target;

    const searchAllProps = properties === "*";
    if (!isArray(properties)) properties = [];


    if (fuzzyMatch) {
        const wildcardDelimiter = '.*';
        keyword = wildcardDelimiter + keyword.split('').join(wildcardDelimiter) + wildcardDelimiter;
    }

    const exp = new RegExp(keyword, "i");
    const isMatch = (v: any) => v && isString(v) && v.search(exp) > -1;

    const predicate = (item: T) => {

        if (!item) return false;

        const props: string[] = searchAllProps ? Object.keys(item) as any : properties;

        for (let i = 0; i < props.length; i++) {
            const key = props[i];
            const value = item[key as any as keyof T];

            if (isMatch(value)) return true;
        }

        for (let i = 0; i < values.length; i++) {
            const valueSelector = values[i];
            const value = valueSelector(item);

            if (isMatch(value)) return true;
        }


        return false;
    };

    return target.filter(predicate);
};


/**Join array of strings with a delimiter. */
export const delimit = <T extends string | boolean | number | undefined | number>(values: T[], delimiter = ",", excludeEmpty = true, lastDelimiter?: string) => {
    if (!values?.length) return "";
    if (excludeEmpty) {
        values = values.filter(x => !!x || x === 0);
    }
    if (lastDelimiter && values.length > 1) {
        const firstValues = values.slice(0, -1);
        return `${firstValues.join(delimiter)} ${lastDelimiter.trim()} ${values.slice(-1)}`;
    }
    return values.join(delimiter);
};

/**Results the min and max value of a property in the list of objects.
 * @param maxProperty Defaults to `property`
 */
export const getMinMax = <ItemType, PropertyName extends keyof SubType<ItemType, number | undefined>>(target: ItemType[], property: PropertyName, maxProperty?: PropertyName) => {
    const result = {
        min: NaN,
        max: NaN,
        minItem: undefined as ItemType | undefined,
        maxItem: undefined as ItemType | undefined,
        minItemIndex: undefined as number | undefined,
        maxItemIndex: undefined as number | undefined
    };
    if (!target?.length) return result;
    for (let index = 0; index < target.length; index++) {

        const elem = target[index];
        if (!elem) continue;

        const minVal = elem[property] as any as number;
        const maxVal = elem[maxProperty ?? property] as any as number;

        if (!isValidNumber(minVal)) continue;
        if (isNaN(result.min) || result.min > minVal) {
            result.min = minVal;
            result.minItem = elem;
            result.minItemIndex = index;
        }

        if (!isValidNumber(maxVal)) continue;
        if (isNaN(result.max) || result.max < maxVal) {
            result.max = maxVal;
            result.maxItem = elem;
            result.maxItemIndex = index;
        }
    }
    return result;
};

/**Executes an action for the provided value(s)  */
export const forEachValue = <V>(v: V | V[], action: (v: V) => void) => {
    if (Array.isArray(v)) v.forEach(action);
    else action(v);
};

/**Returns `true` if the provided value (or one of the provided values if array) is present in the selected property of the provided item  */
export const includesValue = <T, V>(value: V, item: T, key: keyof T | string) => {
    const itemValue = (item as any)[key as any] as any[];
    let filterValue = value as any as any[];
    if (itemValue === filterValue) return true;

    if (!Array.isArray(filterValue)) filterValue = [filterValue];

    if (!Array.isArray(itemValue)) return filterValue.includes(itemValue);
    else return itemValue.some(x => filterValue.includes(x));
};


/**Filter a list of item based on multiple properties and with multiple value options.
 * Returns a list of items that matched at least one value for every single property in the filter.
*/
export const filterBy = <T>(
    items: T[],
    inputs: { key: string, values: any[]; }[],
    filters: { key: string, predicate: (value: any, item: T, key: keyof T | string) => boolean | undefined; }[]
) => {
    const result: T[] = [];
    let item: T;
    for (let i1 = 0; (item = items[i1]); i1++) {
        let input: { key: string, values: any[]; };
        let match = true;
        for (let i2 = 0; (input = inputs[i2]); i2++) {
            const values = input.values;
            if (values && !values.length) continue;
            const key = input.key;
            const filter = filters.find(x => x.key === key);
            const customPredicate = filter?.predicate;

            if (customPredicate) {
                let value: any;
                for (let i3 = 0; (value = values[i3]); i3++) {
                    match = customPredicate(value, item, key) || false;
                    if (match) break;
                }
            }
            else {
                match = includesValue(input.values, item, key) || false;
            }

            if (!match) break;
        }
        if (!match) continue;
        result.push(item);
    }
    return result;
};


/**
 * Returns the first item that match a certain condition.
 * @param items the list to lookup
 * @param predicate If `undefined`, index `0` is returned
 */
export const first = <T>(items: T[], predicate?: (item: T) => boolean) => {
    if (!predicate) return items[0];
    return items.find(predicate);
};

/**
 * Returns the last item that match a certain condition.
 * @param items the list to lookup
 * @param predicate If `undefined`, last item in the list is returned.
 */
export const last = <T>(items: T[], predicate?: (item: T) => boolean) => {
    if (!predicate) return items[items.length - 1];

    let item: T | undefined = undefined;
    for (let i = items.length - 1; i >= 0 && !item; i--) {
        const currentItem = items[i];
        if (predicate(currentItem)) item = currentItem;
    }
    return item;
};


/**ٌReturns an item an array at a certain index. If index is out of bounds, then it is wrapped around the length of the array */
export const getWrap = <T>(array: T[], index: number) => {
    const length = array.length;

    if (index < 0 || index + 1 >= length) {
        index = index % length;
        if (index < 0) {
            index = length + index;
        }
        return array[index];
    }
    else return array[index];
};
/**Rotate items inside an array. Positive values rotate left, negative values rotate right  */
export const rotateArray = <T>(array: T[], count: number) => {
    count = count % array.length;
    return array.slice(count, array.length).concat(array.slice(0, count));
};

/**Returns an new array with items rotated. Positive values rotate left, negative values rotate right  */
export const rotateArrayItems = <T>(array: T[], count: number) => {
    return rotateArray([...array], count);
};


export const mapMany = <A, T>(list: A[], mapper: (item: A, index: number) => T[]) => {
    const result = [] as T[];
    list.forEach((item, index) => result.push(...(mapper(item, index) ?? [])));
    return result;
};

// Chunk list into - smaller chunks (useful for pagination)
export const chunkBy = <T>(data: T[], chunkSize = 10): T[][] => {
    const chunks: T[][] = [];
    let chunk: T[] = [];

    for (let i = 0; i < data.length; i++) {
        if (i !== 0 && (chunk.length % chunkSize === 0)) chunks.push(chunk);
        if (i % chunkSize === 0) chunk = [];
        if (i + 1 === data.length) chunks.push(chunk);
        chunk.push(data[i]);
    }

    return chunks;
};

export const pickRandom = <T>(items: T[]) => {
    return items[Math.floor(Math.random() * items.length)];
};