import useLabels from '../../../../hooks/useLabels';
import { Icon, getInlineIcon } from '../../../icon/Icon';
import { useEffect, useMemo, useState } from 'react';
import { IMAGE_WRAPPER_CLASS_NAME } from '../../helpers';
import { IconTheme } from '../../../icon/Icon.types';
import { Image } from '../../../image/Image';
import { portalId } from '../../../../constants/consts';
import { createPortal } from 'react-dom';
import { Icons } from '../../../icon/icons/material';
import styles from './useImageExpanding.module.scss';

const TARGET_ELEMENT_CLASS_NAME = IMAGE_WRAPPER_CLASS_NAME;
const listenerMap = new WeakMap();
const getImages = () => document.querySelectorAll(`.${TARGET_ELEMENT_CLASS_NAME}`);

const attachImageListener = (setImageUrl: (url: string) => void, labels: ReturnType<typeof useLabels>, imageContainer: Element) => {
    if (listenerMap.has(imageContainer)) return;
    listenerMap.set(imageContainer, true);

    const e = imageContainer as HTMLDivElement;
    const img: HTMLImageElement = imageContainer.querySelector('img')!;
    e.classList.add(styles['container']);

    const cta = document.createElement('button');
    cta.classList.add(styles['image-expanding']);
    cta.textContent = labels.imageEnlargeCta;
    cta.innerHTML = getInlineIcon({ iconName: Icons.zoomIn });
    e.appendChild(cta);

    const h = () => {
        const imgUrl = img.src.split('?')?.[0];
        const width = window?.innerWidth ?? 3000;
        // Attach bigger image size (Default `?width=600&height=300&mode=max`) is defined in helpers.ts
        const url = imgUrl + `?w=${width}&mode=min`;
        setImageUrl(url);
    };

    cta.addEventListener('click', h);
};

const processImages = (callback: (imageContainer: Element, index: number) => void) => Object.values(getImages() || []).forEach(callback);
const initImageExpanding = (setImageUrl: (url: string) => void, labels: ReturnType<typeof useLabels>) => processImages(attachImageListener.bind(this, setImageUrl, labels));


export default function useTableSorting({ isActive, dependencies }: { isActive: boolean; dependencies: React.DependencyList; }) {
    const labels = useLabels();
    const [imageUrl, setImageUrl] = useState<string>();
    const outlet: HTMLElement | null = (typeof window !== "undefined") ? document.getElementById(portalId) : null;

    // Attach listeners to all images
    useEffect(() => {
        if (!isActive) return;
        initImageExpanding(setImageUrl, labels);
        return () => { };
        // eslint-disable-next-line
    }, [labels, isActive, ...(dependencies || [])]);

    // Listen for escape key to close the modal
    useEffect(() => {
        if (!imageUrl) return;
        const h = (e: KeyboardEvent) => (e.key === 'Escape' || e.key === 'Enter') && setImageUrl(undefined);
        document.addEventListener('keydown', h);
        return () => document.removeEventListener('keydown', h);
    }, [imageUrl]);

    const zoomModal = useMemo(() => {
        return <div className={styles['image-modal']}>
            <Icon.Base className={styles['close']} theme={IconTheme.light} iconName={Icons.clear} onClick={() => setImageUrl(undefined)} title={''} />
            <Image.Zoom className={styles.image} src={imageUrl} alt={labels.imageEnlargeAlt} />
        </div>;
    }, [imageUrl, labels]);

    const renderModal = useMemo(() => {
        if (!outlet || !imageUrl) return null;
        return createPortal(zoomModal, outlet);
    }, [outlet, zoomModal, imageUrl]);

    return { renderModal };
};