import { useState } from "react";
import useRefLive from "./useRefLive";

function useStateRef<T>(): [T | undefined, React.Dispatch<React.SetStateAction<T | undefined>>, React.MutableRefObject<T | undefined>];
function useStateRef<T>(initialState: T | (() => T)): [T, React.Dispatch<React.SetStateAction<T>>, React.MutableRefObject<T>];

/**Identical to useState but includes a live ref to the current state.  */
function useStateRef<T>(initialState?: T | (() => T)): [T, React.Dispatch<React.SetStateAction<T>>, React.MutableRefObject<T>] {
    const [state, setState] = useState(initialState);
    const stateRef = useRefLive(state);

    return [state, setState, stateRef] as any;
}

export default useStateRef;