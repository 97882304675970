import React, { type ReactNode, useCallback, useState } from 'react';
import { DfAccordion } from '@danfoss/mosaic-react';
import { AccordionVariantEnum } from '@danfoss/mosaic-core';
import { BaseUIType } from '../../models/UITypes';

export type AccordionProps = BaseUIType & {
    headline: string;
    content: ReactNode | string | number;

    variant?: AccordionVariantEnum;
    isDisabled?: boolean;
    className?: string;

    isInitiallyOpen?: boolean;
    unmountHiddenContent?: boolean;

    // Sync with external state 
    externalState?: {
        isOpen: boolean;
        onToggle: (isOpen: boolean) => void;
    };
};

const Accordion: React.FC<AccordionProps> = ({ unmountHiddenContent = false, testId, headline, content, isInitiallyOpen, variant, isDisabled, className, externalState }) => {
    const [isOpen, setIsOpen] = useState(!!isInitiallyOpen);

    const onToggleHandler = useCallback(() => {
        if (isDisabled) return;
        setIsOpen(s => {
            const value = !s;
            externalState?.onToggle?.(value);
            return value;
        });
    }, [isDisabled, externalState]);

    return (
        <DfAccordion data-testid={testId} isExpanded={externalState?.isOpen ?? isOpen} onHeaderClick={onToggleHandler} className={className} isDisabled={isDisabled} variant={variant}>
            <div slot='header'>
                {headline}
            </div>
            <div slot='content'>
                {(unmountHiddenContent && !isOpen) ? null : content}
            </div>
        </DfAccordion>
    );
};

export default Accordion;