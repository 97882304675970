import { useEffect, useState } from "react";

const useDebounce = <T,>(value: T, delay: number, initialValue?: T): T | undefined => {

    const [debouncedValue, setDebouncedValue] = useState(initialValue);

    useEffect(() => {
        const handler = setTimeout(() => setDebouncedValue(value), delay);
        return () => {
            clearTimeout(handler);
        };
    }, [delay, setDebouncedValue, value]);

    return debouncedValue;
};

export default useDebounce;
