import { AnchorHeadline } from './AnchorHeadline';
import style from '../Information.module.scss';


export const About = () => {
    return (
        <div className={style.about}>
            <AnchorHeadline headline={'About Danfoss AI Chatbot'} />

            <p>
                The Danfoss AI Chatbot is a tool for employees to quickly access information and assistance. It combines publicly available knowledge with internal Danfoss information to answer your questions.
            </p>
            <br />
            <p>
                While the chatbot strives for accuracy, it's important to note:
            </p>
            <ul>
                <li><b>Some information may not be up-to-date or complete.</b> Always verify critical information</li>
                <li><b>The chatbot does not represent official Danfoss opinions.</b></li>
                <li><b>Respect copyright.</b> Any third-party links or information provided may have usage restrictions.</li>
            </ul>
            <br />
            <p>
                Use the chatbot responsibly, in line with Danfoss IT policies. Misuse may result in access termination.
            </p>
        </div>
    );
};
